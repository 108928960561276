import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import PartnerDetails from './components/partnerDetails/partnerDetails';
import './components/styles/styles.scss';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './providers/appInsightProvider';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { runningInIframe } from './utils/iframeUtils';
import { OutboundMessagesHandler } from './protocol/outboundMessagesHandler';
import { InboundMessagesHandler } from './protocol/inboundMessagesHandler';
import { ISetContextMessage, IViewContext } from './protocol/partnersViewInboundMessages';
import { getContext, getContextInit, setContextInfo, setHostSize, setScrollOffset } from './redux/hostContextSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Logger from './services/loggerService';
import PartnersGallery from './components/partnerGallery/partnersGallery';
import ErrorBoundary from './components/common/errorBoundary';
import { appInsights } from './providers/appInsightProvider';
import { isRtl } from './utils/rtlUtils';
import { monitorConfiguration } from './services/configurationService';
import { monitorDependency, MONITORS_DEPENDENCIES } from './services/monitorService';
import SearchSuggestionHandler from './components/partnerSearch/SearchSuggestionHandler';
import { RootState } from './redux';
import { FEATURE_FLAGS } from './shared/constants';
import { FeatureFlags, setFeatureFlags } from './redux/configSlice';

const LOGGER_PREFIX = 'App';

const AppContainer = () => {
  const lang = i18next.language ? i18next.language : 'en';
  const location = useLocation();

  const renderStandaloneRoutes = () => {
    return (
      <Switch>
        <Route exact={true} path="/">
          <Redirect to={'/' + lang + '/partners/'} />
        </Route>
        <Route path="/partners">
          <Redirect to={'/' + lang + location.pathname} />
        </Route>
        <Route path="/:locale/partners">
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Route>
      </Switch>
    );
  };

  const renderIframeRoutes = () => {
    return (
      <Switch>
        <Route path="/:locale">
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Route>
      </Switch>
    );
  };

  return <Router>{runningInIframe() ? renderIframeRoutes() : renderStandaloneRoutes()}</Router>;
};

const App = () => {
  //Init fluentui icons one time before first render.
  initializeIcons();
  const dispatch = useDispatch();
  const { locale } = useParams<{ locale: string }>();
  const [lng, setLng] = useState(i18next.language);
  const { path } = useRouteMatch();
  const { t: translate } = useTranslation();
  const outboundMessagesHandler = new OutboundMessagesHandler();
  let inboundMessagesHandler = null;
  const isIframe: boolean = runningInIframe();
  const urlLocation = useLocation();
  const history = useHistory();

  //code to run on component mount
  useEffect(() => {
    // If we are running in iframe, no need for scrollbar. It going to come from outside (iframe host).
    if (isIframe) {
      document.body.style.overflow = 'hidden';
    }
    monitorConfiguration();
    let resizeObserver = null;
    if (isIframe) {
      inboundMessagesHandler = new InboundMessagesHandler({ onScrollChange, onSetContextMessage, onSizeChange });
      inboundMessagesHandler.startHandleInboundMessages();
      resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(document.body);
      outboundMessagesHandler.postReadyMessage();
      monitorDependency({ name: MONITORS_DEPENDENCIES.IFRAME_SEND_READY_MESSAGE, success: true });
    } else {
      window.addEventListener('scroll', onWindowScrollChange);
    }

    return function cleanup() {
      if (isIframe) {
        inboundMessagesHandler.stopHandleInboundMessages();
      } else {
        window.removeEventListener('scroll', onWindowScrollChange);
      }
      if (resizeObserver) {
        resizeObserver.unobserve(document.body);
      }
    };
  }, []);

  useEffect(() => {
    const lng = locale && locale.length > 1 ? locale.slice(0, 2) : null;
    if (lng) {
      setLng(lng);
    }
  }, [locale]);

  useEffect(() => {
    if (lng) {
      i18next.changeLanguage(lng);
    }
  }, [lng]);

  useEffect(() => {
    history.listen(trackPageView);
    const searchParams = new URLSearchParams(urlLocation.search);
    handleDesignationsFeatureFlag(searchParams.get(FEATURE_FLAGS.DesignationsEnabled));
  }, []);

  const handleDesignationsFeatureFlag = (designationEnabled: string) => {
    if (designationEnabled === 'true') {
      dispatch(setFeatureFlags({ featureFlags: new FeatureFlags(true) }));
    } else if (designationEnabled === 'false') {
      dispatch(setFeatureFlags({ featureFlags: new FeatureFlags(false) }));
    }
  };

  const trackPageView = () => {
    appInsights.trackPageView({
      name: window.location.pathname,
      uri: window.location.toString()
    });
  };

  const onWindowScrollChange = () => {
    onScrollChange(window.pageXOffset, window.pageYOffset);
  };

  const onScrollChange = (xOffset: number, yOffset: number, sender?: InboundMessagesHandler) => {
    dispatch(setScrollOffset({ scrollOffset: { xOffset: xOffset, yOffset: yOffset } }));
  };

  const onSizeChange = (width: number, height: number, frameLeft: number, frameTop: number, sender?: InboundMessagesHandler) => {
    dispatch(setHostSize({ size: { width: width, height: height, frameLeft: frameLeft, frameTop: frameTop } }));
  };

  const onSetContextMessage = (context: ISetContextMessage, sender: InboundMessagesHandler) => {
    // Monitor message only for the first time.
    if (sender && sender.getContextMessageCount() == 1) {
      monitorDependency({ name: MONITORS_DEPENDENCIES.IFRAME_RECIEVED_CONTEXT_MESSAGE, success: true });
    }
    Logger.Info(LOGGER_PREFIX, 'On set context');
    dispatch(setContextInfo({ context: context.context }));
    const historyLocationPathName = history.location.pathname + history.location.search;
    // We are replacing to URL and not pushing it because we don't want local router stack to have back or next.
    // All url navigation happen from the outside.
    if (context.context.partnersUrl && context.context.partnersUrl !== historyLocationPathName) {
      history.replace(context.context.partnersUrl);
    }
    if (context.context.partnersUrl) {
      //Using fummy host. We only use the url to help us parse the query params.
      const url = new URL(context.context.partnersUrl, 'https://a/b/c/d');
      if (url.search) {
        const params = new URLSearchParams(url.search);
        handleDesignationsFeatureFlag(params.get(FEATURE_FLAGS.DesignationsEnabled));
      }
    }
  };

  const onResize = () => {
    outboundMessagesHandler.postResizeMessage();
  };

  const appClassName = runningInIframe() ? 'app-iframe' : 'app';

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div className={`${appClassName} ${isRtl() ? 'rtl' : 'ltr'}`}>
        {runningInIframe() ? (
          <div>
            <Route exact={true} path="/:locale/partners" component={PartnersGallery} />
            <Route exact={true} path="/:locale/partners/suggestion" component={SearchSuggestionHandler} />
            <Route exact={true} path="/:locale/partners/:id/:tab?/:contact?" component={PartnerDetails} />
          </div>
        ) : (
          <Switch>
            <Route exact={true} path={path + '/:id/:tab/:contact?'}>
              <PartnerDetails />
            </Route>
            <Route exact={true} path={path + '/suggestion'}>
              <SearchSuggestionHandler />
            </Route>
            <Route exact={true} path={path + '/:id'}>
              <PartnerDetails />
            </Route>
            <Route exact={true} path={path}>
              <PartnersGallery />
            </Route>
          </Switch>
        )}
      </div>
    </AppInsightsContext.Provider>
  );
};

export default AppContainer;
