export class Consents {
  consentToToShareInfoWithOthers = false;
  consentToContact = true;
}

export class CustomerProfile {
  name: string = undefined;
  address: Address = new Address();
  size: string = undefined;
  team: Team[] = [];
}

export class ContactPreference {
  locale: string = undefined;
  disableNotifications = false;
}

export class Team {
  contactPreference = new ContactPreference();
  firstName: string = undefined;
  lastName: string = undefined;
  phoneNumber: string = undefined;
  email: string = undefined;
}

export class Address {
  addressLine1: string = undefined;
  addressLine2: string = undefined;
  city: string = undefined;
  state: string = undefined;
  country: string = undefined;
  postalCode: string = undefined;
}

export class IdsObj {
  id: string = undefined;
  type: string = undefined;
}

export class Requirements {
  industries: IdsObj[] = [];
  products: IdsObj[] = [];
  services: IdsObj[] = [];
  solutions: IdsObj[] = [];
}

export class InviteContext {
  notes: string = undefined;
  invitedBy: string = null;
}

export class SendReferralDetails {
  notes: string = undefined;
  dealValue: string = null;
  currency = 'USD';
  closingDateTime = '';
  requirements = new Requirements();
}

export class SendReferralRequest {
  organizationId: string = undefined;
  businessProfileId: string = undefined;
  engagementId: string = undefined;
  organizationName: string = undefined;
  status = '';
  substatus = '';
  qualification = 'Direct';
  externalReferenceId = '';
  type = 'independent';
  customerProfile: CustomerProfile = new CustomerProfile();
  consent: Consents = new Consents();
  team: Team[] = [];
  details: SendReferralDetails = new SendReferralDetails();
  inviteContext: InviteContext = new InviteContext();
}
