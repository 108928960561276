import React, { useRef, useState } from 'react';
import { DirectionalHint, HoverCard, HoverCardType } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { getLocalizationKey } from '../../shared/domainDataObj';
import { getIconForProduct } from '../../utils/iconUtils';
import IconLink from './iconLink';
import { useEffect } from 'react';
import { throttle } from '../../utils/throttle';

const CARD_DISMISS_DELAY_MSEC = 500;
const RESIZE_THROTTLE_MSEC = 100;

interface IProductBox {
  products: any[];
  maxProducts?: number;
  responsive?: boolean;
  resizeContainer?: boolean;
}

export default function ProductsBox(props: IProductBox) {
  const { t: translate } = useTranslation();
  const moreProductsLabelRef = useRef(null);
  const [overflowingProductCount, setOverflowingProductCount] = useState(0);
  const hostElement = useRef(null);
  const iconsContainer = useRef(null);

  const renderMoreCardProducts = () => {
    if (props.products && isProductsOverflowing()) {
      const moreProducts = props.products.slice(props.products.length - overflowingProductCount, props.products.length);
      const moreCardProducts = moreProducts.map((productItem, index) => {
        {
          return (
            <IconLink
              iconClassName={getIconForProduct(productItem)}
              href={undefined}
              title={translate(getLocalizationKey(productItem))}
              key={'product link ' + index}
              className="more-product-item"
              boxClassName="more-product-icon-link"
              role="listitem"
            >
              <span>{translate(getLocalizationKey(productItem))}</span>
            </IconLink>
          );
        }
      });
      return (
        <div className="more-products-card" tabIndex={0} role="region" aria-live="assertive">
          <div role="list">{moreCardProducts}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  const isProductsOverflowing = () => {
    return overflowingProductCount > 0;
  };

  const renderMoreProductsHoverLabel = (productsCount: number) => {
    return (
      isProductsOverflowing() && (
        <div
          className="more-product-item-label-wrapper"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <HoverCard
            cardDismissDelay={CARD_DISMISS_DELAY_MSEC}
            instantOpenOnClick={true}
            type={HoverCardType.plain}
            plainCardProps={{
              onRenderPlainCard: renderMoreCardProducts,
              directionalHint: DirectionalHint.topCenter
            }}
          >
            <a ref={moreProductsLabelRef} onFocus={onMoreProductsLabelFocus} tabIndex={0} className="more-product-item-label">
              {`+${productsCount}`}
            </a>
          </HoverCard>
        </div>
      )
    );
  };

  const isProductElementOverflowing = productElement => {
    const moreLinkWidth = moreProductsLabelRef && moreProductsLabelRef.current ? moreProductsLabelRef.current.offsetWidth : 0;
    return productElement.offsetWidth + productElement.offsetLeft >= hostElement.current.offsetWidth - moreLinkWidth;
  };

  const updateIconVisibility = () => {
    if (hostElement && hostElement.current) {
      let widthSum = 0;
      let foundEnd = false;
      const childRefs = iconsContainer.current.children;

      for (let i = 0; i < childRefs.length; i++) {
        const currentElement = childRefs[i];
        currentElement.style.visibility = 'hidden';
        currentElement.style.display = 'flex';
        if (
          i > 0 &&
          (foundEnd || (props.maxProducts && i >= props.maxProducts) || (props.responsive && isProductElementOverflowing(currentElement)))
        ) {
          currentElement.style.display = 'none';
          if (!foundEnd) {
            foundEnd = true;
            setOverflowingProductCount(props.products.length - i);
          }
        } else {
          currentElement.style.display = 'flex';
          currentElement.style.visibility = 'visible';

          widthSum += currentElement.offsetWidth;
        }
      }

      if (!foundEnd) {
        setOverflowingProductCount(0);
      }

      if (props.resizeContainer) {
        iconsContainer.current.style.width = widthSum + 'px';
      }
    }
  };

  const onMoreProductsLabelFocus = (e: React.FocusEvent<HTMLElement>) => {
    // Manually trigger opening of the hover card for keyboard navigation support
    if (e && e.target && e.target.click) {
      e.target.click();
    }
  };

  useEffect(() => {
    const handler = throttle(updateIconVisibility, RESIZE_THROTTLE_MSEC);
    handler();
    if (props.products.length && props.responsive) {
      window.addEventListener('resize', handler);
    }
    return () => {
      window.removeEventListener('resize', handler);
    };
  });

  if (props.products) {
    const cardProducts = props.products.map((productItem, index) => {
      {
        return (
          <div key={'product link wrapper' + index} className="product-icon-link-container" style={{ visibility: 'hidden' }}>
            <IconLink
              iconClassName={getIconForProduct(productItem)}
              href={undefined}
              title=""
              className="product-item ellipsis"
              key={'product link ' + index}
              role="listitem"
            >
              <span>{translate(getLocalizationKey(productItem))}</span>
            </IconLink>
          </div>
        );
      }
    });

    return (
      <div className="card-products-box" ref={hostElement} tabIndex={0}>
        <div className="card-products-box-icons-container" role="list" ref={iconsContainer}>
          {cardProducts}
        </div>
        {renderMoreProductsHoverLabel(overflowingProductCount)}
      </div>
    );
  }

  return null;
}
