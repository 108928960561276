/*
  Data structure of messages recieved by partner host.
  At the moment the only host is AppSource.
 */
export enum PartnersHostMessageType {
  Ready, // Partners view notify partners host that it is ready
  Login, // Partners view request partners host to perform user login
  ChangeUrlDetails, // Partners view request partners host to change its url to PDP
  ChangeUrlGallery, // Partners view request partners host to change its url to Gallery
  StoreFilterLocation, // Partners view request partners host to store the location of the filter
  StoreContactDetails, // Partners view request partners host to store contact details
  ContentHeightChange // Partners view notify partners host about height change
}

export interface IFilterLocation {
  countryCode: string;
  locationLatitude: number;
  locationLongtitude: number;
  locationName: string;
  radiusMiles: number;
  onlyThisCountryResults: boolean;
}

export interface IContactDetails {
  firstName: string;
  lastName: string;
  phone: string;
  city: string;
  country: string;
  email: string;
  organizationName: string;
  organizationSize: string;
  industreyFocus: string;
  zipCode: string;
  state: string;
  helpNeeded: string;
}

export interface IPartnersHostMessage {
  messageType: PartnersHostMessageType;
}

export interface IContentHeightChangeMessage extends IPartnersHostMessage {
  height: number;
}

export interface IChangeUrlGalleryMessage extends IPartnersHostMessage {
  galleryFilter: string;
  contact: string;
  replaceUrl: boolean;
}

export interface IStoreFilterLocationMessage extends IPartnersHostMessage {
  filterLocation: IFilterLocation;
  diversityAndInclusionEnabled: boolean;
}

export interface IStoreContactDetailsMessage extends IPartnersHostMessage {
  contactDetails: IContactDetails;
}

export interface IChangeUrlDetailsMessage extends IPartnersHostMessage {
  partnerId: string;
  tab: string;
  contact: boolean;
  replaceUrl: boolean;
}
