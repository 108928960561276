import { GetEnvConfiguration } from '../services/configurationService';
import * as Logger from './../services/loggerService';
const LOGGER_PREFIX = 'BingUtils';
const env = GetEnvConfiguration();
const BING_MAP_API = 'https://sdk.virtualearth.net/api/maps/mapcontrol?';
const BING_MAP_CALLBACK = 'onLoadMapApi';
const BING_SCRIPT_TYPE = 'text/javascript';
const LANG = 'en';
const MKT = 'en-US';
let bingMapLoading = false;
let bingMapLoaded = false;
const loadBingMapsCallbacks = new Array<() => void>();

export const loadBingMapApi = (callback: () => void) => {
  if (!callback) return;
  //Already loaded - call the callback.
  if (bingMapLoaded) {
    if (callback) callback();
    return;
    //Already loading - add the callback.
  } else if (bingMapLoading) {
    loadBingMapsCallbacks.push(callback);
  } else {
    bingMapLoading = true;
    loadBingMapsCallbacks.push(callback);
    (window as any).onLoadMapApi = () => onLoadMapApi(); // Set callback to window to make it global.
    const bingScript = document.createElement('script');
    bingScript.type = BING_SCRIPT_TYPE;
    bingScript.src = `${BING_MAP_API}&callback=${BING_MAP_CALLBACK}&setLang=${LANG}&setMkt=${MKT}`;
    bingScript.async = true;
    document.body.appendChild(bingScript);
  }
};
const onLoadMapApi = () => {
  bingMapLoaded = true;
  bingMapLoading = false;
  //Call all callbacks
  loadBingMapsCallbacks.forEach(callback => {
    try {
      if (callback) callback();
    } catch (ex) {
      Logger.Err(LOGGER_PREFIX, 'Error in bing map loading callback - ' + ex);
    }
  });
  //Clear all callbacks
  loadBingMapsCallbacks.splice(0, loadBingMapsCallbacks.length);
};
export const loadAutoCompleteModule = (callback: () => void, errorCallback: (message: string) => void) => {
  (window as any).Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {
    callback: callback,
    errorCallback: errorCallback,
    credentials: env.BING_MAP_KEY
  });
};
export const loadSearchModule = (callback: () => void, errorCallback: (message: string) => void) => {
  (window as any).Microsoft.Maps.loadModule('Microsoft.Maps.Search', {
    callback: callback,
    errorCallback: errorCallback,
    credentials: env.BING_MAP_KEY
  });
};
