import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  CONTACT_ME,
  COUNTRY_REGION,
  FIRST_NAME,
  HOW_CAN_WE_HELP,
  INDUSTREY_FOCUS,
  LAST_NAME,
  ORGANIZATION_SIZE,
  PHONE,
  SELECT_INDUSTREY_FOCUS,
  SELECT_ORGANIZATION_SIZE,
  WE_PULLED_YOUR_PROFILE_INFO_MESSAGE,
  WORK_EMAIL,
  PARTNER_LOCATION,
  CITY,
  ZIP_CODE,
  STATE,
  ORGANIZATION_NAME,
  ENTER_ORGANIZATION_NAME,
  DESCRIBE_YOUR_BUSINESS_NEED,
  INVALID_VALUE,
  PARTNER_LOGO_ALT,
  INVALID_VALUE_NO_URLS_ALLOWED,
  CONTACT_PARTNER_DISCLAIMER_MESSAGE,
  CLOSE_CONTACT_PARTNER_DIALOG
} from '../../constants/localization';
import {
  Modal,
  TextField,
  Dropdown,
  IDropdownOption,
  Separator,
  mergeStyleSets,
  ComboBox,
  Dialog,
  PrimaryButton,
  DialogFooter,
  Spinner,
  SpinnerSize,
  Link,
  IconButton,
  MessageBar
} from '@fluentui/react';
import { DominDataType, getDomainDataUniqueKey, IDomainData } from '../../shared/domainDataObj';
import { IPartnerDetails, IPartnerLocation } from '../../shared/models/partnerDetailsModel';
import {
  CANADA_COUNTRY_CODE,
  CONTRY_CODE_WITH_STATES,
  getAllCanadaStatesSorted,
  getAllCountriesSorted,
  getAllUSAStatesSorted,
  USA_COUNTRY_CODE
} from '../../utils/countryStatesUtils';
import { IPartnersUserInfo } from '../../protocol/partnersViewInboundMessages';
import PartnerLocationDropDown from './../common/partnerLocationDropDown';
import { contactPartnerRequest } from '../../sagas/apis';
import { IdsObj, SendReferralRequest, Team } from '../../shared/models/Request/sendReferral';
import { runningInIframe } from '../../utils/iframeUtils';
import * as Logger from './../../services/loggerService';
import { containsURL, isSensitiveEmail, isValidEmail } from '../../utils/InputValidationUtils';
import { IContactDetails, IStoreContactDetailsMessage, PartnersHostMessageType } from '../../protocol/partnersHostInboundMessages';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import {
  ANALYTICS_PARTNER_CENTER_ACTIONS,
  ANALYTICS_USER_ACTIONS,
  PARTNER_CENTER_ACTIONS_PROPS_NAMES,
  trackPartnerCenterAction,
  trackUserAction
} from '../../services/analyticsService';
import { RootState } from '../../redux';
import { getHostSize, HostSizeInfo } from '../../redux/hostContextSlice';

const LOGGER_PREFIX = 'ContactPartnerDialog';
const NO_CONTENT = 'No content';

export const CONTACT_DISCLAIMER_TERMS_HREF = 'https://aka.ms/azuremarketplaceterms';
export const CONTACT_DISCLAIMER_PRIVACY_HREF = 'https://aka.ms/AppSourcePrivacyStatement';
export const DEFAULT_HEIGHT = 800;
export const DEFAULT_FRAME_TOP = 100;

export interface IContactDialog {
  partnerId: string;
  partnerListingId: string;
  partnerName: string;
  partnerLocation: IPartnerLocation;
  partnerLogoURL: string;
  isDialogOpen: boolean;
  allPartnerDetails: IPartnerDetails[];
  allLocations: IPartnerLocation[];
  user: IPartnersUserInfo;
  lastContactDetails: IContactDetails;
  directionRtl: boolean;
  loadingMoreLocations: boolean;
  onDismissDialog();
  onContactMessageSent();
}

export default function ContactPartnerDialog(props: IContactDialog) {
  const MAX_DROPDOWN_HEIGHT = 400;
  const DROPDOWN_WIDTH = 606;
  const MAX_NOTES_LENGTH = 8000;
  const { t: translate } = useTranslation();
  const hostSize: HostSizeInfo = useSelector((store: RootState) => getHostSize(store.hostContext));
  // Editor values
  const [selectedLocation, setSelectedLocation] = useState(props.partnerLocation.id);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [organizationSize, setOrganizationSize] = useState('');
  const [industreyFocus, setIndustreyFocus] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [helpNeeded, setHelpNeeded] = useState('');
  //Invalid values
  const [firstNameInvalidVal, setFirstNameInvalidVal] = useState(false);
  const [lastNameInvalidVal, setLastNameInvalidVal] = useState(false);
  const [phoneInvalidVal, setPhoneInvalidVal] = useState(false);
  const [cityInvalidVal, setCityInvalidVal] = useState(false);
  const [emailInvalidVal, setEmailInvalidVal] = useState(false);
  const [organizationNameInvalidVal, setOrganizationNameInvalidVal] = useState(false);
  const [zipCodeInvalidVal, setZipCodeInvalidVal] = useState(false);
  const [helpNeededInvalidVal, setHelpNeededInvalidVal] = useState(false);

  const isIframe: boolean = runningInIframe();
  const [contactInProgress, setContactInProgress] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    // Init fields from last contact details.
    if (props.lastContactDetails) {
      setFirstName(props.lastContactDetails.firstName);
      setLastName(props.lastContactDetails.lastName);
      setPhone(props.lastContactDetails.phone);
      setCity(props.lastContactDetails.city);
      setCountry(props.lastContactDetails.country);
      setState(props.lastContactDetails.state);
      setEmail(props.lastContactDetails.email);
      setZipCode(props.lastContactDetails.zipCode);
      setHelpNeeded(props.lastContactDetails.helpNeeded);
      setIndustreyFocus(props.lastContactDetails.industreyFocus);
      setOrganizationSize(props.lastContactDetails.organizationSize);
      setOrganizationName(props.lastContactDetails.organizationName);
    }
    // Init fields from user informaton.
    else if (props.user) {
      setFirstName(props.user.firstName);
      setLastName(props.user.lastName);
      setPhone(props.user.phone);
      setCountry(props.user.country ? props.user.country.toUpperCase() : '');
      setEmail(props.user.email);
      setOrganizationName(props.user.company);
    }
  }, []);

  useEffect(() => {
    setFirstNameInvalidVal(containsURL(firstName));
  }, [firstName]);

  useEffect(() => {
    setLastNameInvalidVal(containsURL(lastName));
  }, [lastName]);

  useEffect(() => {
    setPhoneInvalidVal(containsURL(phone));
  }, [phone]);

  useEffect(() => {
    setCityInvalidVal(containsURL(city));
  }, [city]);

  useEffect(() => {
    setEmailInvalidVal(email && !isValidEmail(email));
  }, [email]);

  useEffect(() => {
    setOrganizationNameInvalidVal(containsURL(organizationName));
  }, [organizationName]);

  useEffect(() => {
    setZipCodeInvalidVal(containsURL(zipCode));
  }, [zipCode]);

  useEffect(() => {
    setHelpNeededInvalidVal(containsURL(helpNeeded));
  }, [helpNeeded]);

  const renderImage = () => {
    return props.partnerLogoURL ? (
      <div className="partner-logo">
        <img src={props.partnerLogoURL} alt={translate(PARTNER_LOGO_ALT, { partnerName: props.partnerName })} />
      </div>
    ) : null;
  };

  const getSelectedLocation = (): IPartnerLocation => {
    const locations = props.allLocations.filter(locationObj => locationObj.id === selectedLocation);
    if (locations && locations[0]) return locations[0];
    else return null;
  };

  const getSelectedPartnerProfile = (): IPartnerDetails => {
    const foundPartners = props.allPartnerDetails.filter(partner => partner.location.id === selectedLocation);
    if (foundPartners && foundPartners[0]) return foundPartners[0];
    else return null;
  };

  const renderHeader = () => {
    return (
      <div>
        <div className="header-panel">
          <div className="header-title" id="ContactMeDialogTitle">
            {translate(DESCRIBE_YOUR_BUSINESS_NEED)}
          </div>
          <IconButton
            className={props.directionRtl ? 'contact-message-sent-cancel-icon-rtl' : 'contact-message-sent-cancel-icon'}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel={translate(CLOSE_CONTACT_PARTNER_DIALOG)}
            onClick={props.onDismissDialog}
          />
        </div>

        <div className="header-box">
          {renderImage()}
          <div className="header-content-box">
            <div className="partner-name">{props.partnerName}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderPartnerLocation = () => {
    return (
      <div className="location-drop-down">
        <PartnerLocationDropDown
          key="contact_partner_locations_dropdown"
          width={DROPDOWN_WIDTH}
          label={translate(PARTNER_LOCATION)}
          maxDropDownHeight={MAX_DROPDOWN_HEIGHT}
          location={getSelectedLocation()}
          allLocations={props.allLocations}
          underLineBorder={false}
          loadingProgress={props.loadingMoreLocations}
          onLocationChange={(location: IPartnerLocation) => {
            if (location) {
              trackUserAction({
                name: ANALYTICS_USER_ACTIONS.CONTACT_CHANGE_PARTNER_LOCATION,
                properties: { partnerId: props.partnerId, partnerListingId: props.partnerListingId, locationId: location?.id }
              });
              setSelectedLocation(location.id);
            }
          }}
        />
      </div>
    );
  };

  const renderNameFields = () => {
    return (
      <div className="content-fields-row-no-margin">
        <TextField
          className="content-field"
          required={true}
          label={translate(FIRST_NAME)}
          deferredValidationTime={2000}
          value={firstName}
          errorMessage={firstNameInvalidVal ? translate(INVALID_VALUE) : ''}
          onChange={(event: any, newValue?: string) => {
            setFirstName(newValue);
          }}
        />
        <TextField
          className="content-field"
          required={true}
          label={translate(LAST_NAME)}
          deferredValidationTime={2000}
          value={lastName}
          errorMessage={lastNameInvalidVal ? translate(INVALID_VALUE) : ''}
          onChange={(event: any, newValue?: string) => {
            setLastName(newValue);
          }}
        />
      </div>
    );
  };

  const renderCountryStateFields = () => {
    const allCountries = getAllCountriesSorted().map((countryNames, index) => {
      {
        return { key: countryNames.code, text: countryNames.name };
      }
    });

    let stateData = null;
    let allStates = [];
    const hasStates = CONTRY_CODE_WITH_STATES.includes(country);
    if (hasStates) {
      if (country === USA_COUNTRY_CODE) {
        stateData = getAllUSAStatesSorted();
      } else if (country === CANADA_COUNTRY_CODE) {
        stateData = getAllCanadaStatesSorted();
      }
      allStates = stateData.map((stateNames, index) => {
        {
          return { key: stateNames.code, text: stateNames.name };
        }
      });
    }

    return (
      <div className="content-fields-row-no-margin">
        <ComboBox
          className="content-field"
          autoComplete="on"
          allowFreeform={false}
          required={true}
          label={translate(COUNTRY_REGION)}
          options={allCountries}
          selectedKey={country}
          onChange={(event: any, option?: IDropdownOption, index?: number) => {
            if (option) {
              setCountry(option.key as string);
            }
          }}
        />
        <ComboBox
          className="content-field"
          autoComplete="on"
          allowFreeform={false}
          required={hasStates}
          label={translate(STATE)}
          disabled={!hasStates}
          options={allStates}
          selectedKey={state}
          onChange={(event: any, option?: IDropdownOption, index?: number) => {
            if (option) {
              setState(option.key as string);
            }
          }}
        />
      </div>
    );
  };

  const renderCityZipCodeFields = () => {
    return (
      <div className="content-fields-row-no-margin">
        <TextField
          className="content-field"
          required={true}
          label={translate(CITY)}
          deferredValidationTime={2000}
          value={city}
          errorMessage={cityInvalidVal ? translate(INVALID_VALUE) : ''}
          onChange={(event: any, newValue?: string) => {
            setCity(newValue);
          }}
        />
        <TextField
          className="content-field"
          required={true}
          label={translate(ZIP_CODE)}
          deferredValidationTime={2000}
          value={zipCode}
          errorMessage={zipCodeInvalidVal ? translate(INVALID_VALUE) : ''}
          onChange={(event: any, newValue?: string) => {
            setZipCode(newValue);
          }}
        />
      </div>
    );
  };

  const getExternalEmail = (): string => {
    if (props?.user?.email) return props?.user?.email;
    else if (props?.lastContactDetails?.email) return props?.lastContactDetails?.email;
    return null;
  };

  const isEmailReadOnly = (): boolean => {
    if (getExternalEmail()) return true;
    else return false;
  };

  const isHelpNeededDisabled = (): boolean => {
    const externalEmail = getExternalEmail();
    return isSensitiveEmail(externalEmail);
  };

  const renderPhoneEmailField = () => {
    return (
      <div className="content-fields-row-no-margin">
        <TextField
          className="content-field"
          required={true}
          label={translate(PHONE)}
          deferredValidationTime={2000}
          value={phone}
          errorMessage={phoneInvalidVal ? translate(INVALID_VALUE) : ''}
          onChange={(event: any, newValue?: string) => {
            setPhone(newValue);
          }}
        />
        <TextField
          className="content-field"
          required={true}
          disabled={isEmailReadOnly()}
          label={translate(WORK_EMAIL)}
          deferredValidationTime={2000}
          value={email}
          errorMessage={emailInvalidVal ? translate(INVALID_VALUE) : ''}
          onChange={(event: any, newValue?: string) => {
            setEmail(newValue);
          }}
        />
      </div>
    );
  };

  const renderOrganizationFields = () => {
    const orgSizeDomDataOptions: IDomainData[] = getDomainDataUniqueKey(DominDataType.CompanySize);
    const orgSizeOptions = orgSizeDomDataOptions.map((companySizeObj, index) => {
      {
        return { key: companySizeObj.key, text: translate(companySizeObj.localizationKey) };
      }
    });
    return (
      <div className="content-fields-row">
        <Dropdown
          className="content-field"
          required={true}
          placeholder={translate(SELECT_ORGANIZATION_SIZE)}
          label={translate(ORGANIZATION_SIZE)}
          options={orgSizeOptions}
          selectedKey={organizationSize}
          onChange={(event, option?: IDropdownOption) => {
            if (option) {
              setOrganizationSize(option.key as string);
            }
          }}
        />
        <TextField
          className="content-field"
          required={true}
          placeholder={translate(ENTER_ORGANIZATION_NAME)}
          label={translate(ORGANIZATION_NAME)}
          deferredValidationTime={2000}
          value={organizationName}
          errorMessage={organizationNameInvalidVal ? translate(INVALID_VALUE) : ''}
          onChange={(event: any, newValue?: string) => {
            setOrganizationName(newValue);
          }}
        />
      </div>
    );
  };

  const renderIndustreyFocusFields = () => {
    const industreyDomDataOptions: IDomainData[] = getDomainDataUniqueKey(DominDataType.Industry);
    const industreyOptions = industreyDomDataOptions.map((industreyObj, index) => {
      {
        return { key: industreyObj.key, text: translate(industreyObj.localizationKey) };
      }
    });
    return (
      <div>
        <Dropdown
          className="focus-areas-drop-down"
          required={true}
          label={translate(INDUSTREY_FOCUS)}
          placeholder={translate(SELECT_INDUSTREY_FOCUS)}
          options={industreyOptions}
          selectedKey={industreyFocus}
          onChange={(event, option?: IDropdownOption) => {
            if (option) {
              setIndustreyFocus(option.key as string);
            }
          }}
        />
      </div>
    );
  };

  const renderHelpNeededField = () => {
    if (isHelpNeededDisabled()) return null;
    else
      return (
        <div className="help-needed-box">
          <TextField
            className="multiline-text-field"
            multiline={true}
            required={true}
            label={translate(HOW_CAN_WE_HELP)}
            deferredValidationTime={2000}
            value={helpNeeded}
            errorMessage={helpNeededInvalidVal ? translate(INVALID_VALUE_NO_URLS_ALLOWED) : ''}
            onChange={(event: any, newValue?: string) => {
              setHelpNeeded(newValue);
            }}
            maxLength={MAX_NOTES_LENGTH}
          />
        </div>
      );
  };

  const renderSeparator = () => {
    return <Separator className="separator" />;
  };

  const renderContent = () => {
    return (
      <div className="content-box">
        <p className="content-paragraph">{translate(WE_PULLED_YOUR_PROFILE_INFO_MESSAGE)}</p>
        {renderPartnerLocation()}
        {renderSeparator()}
        {renderNameFields()}
        {renderCountryStateFields()}
        {renderCityZipCodeFields()}
        {renderPhoneEmailField()}
        {renderOrganizationFields()}
        {renderIndustreyFocusFields()}
        {renderHelpNeededField()}
      </div>
    );
  };

  const renderDisclaimer = () => {
    return (
      <div className="disclaimer-box">
        <MessageBar className="disclaimer-text">
          <Trans i18nKey={CONTACT_PARTNER_DISCLAIMER_MESSAGE}>
            <Link style={{ padding: 0 }} href={CONTACT_DISCLAIMER_TERMS_HREF} target="_blank" underline />
            <Link style={{ padding: 0 }} href={CONTACT_DISCLAIMER_PRIVACY_HREF} target="_blank" underline />
            Link
          </Trans>
        </MessageBar>
      </div>
    );
  };

  const contactValuesValid = () => {
    return (
      firstName &&
      !firstNameInvalidVal &&
      lastName &&
      !lastNameInvalidVal &&
      phone &&
      !phoneInvalidVal &&
      city &&
      !cityInvalidVal &&
      country &&
      email &&
      !emailInvalidVal &&
      organizationSize &&
      organizationName &&
      !organizationNameInvalidVal &&
      industreyFocus &&
      zipCode &&
      !zipCodeInvalidVal &&
      (state || !CONTRY_CODE_WITH_STATES.includes(country)) &&
      (isHelpNeededDisabled() || helpNeeded) &&
      !helpNeededInvalidVal
    );
  };

  const renderButtons = () => {
    return (
      <div className="buttons-box">
        <PrimaryButton
          name="button"
          className="request-button c-button contact-button"
          type="submit"
          onClick={onSubmitContactMeClick}
          disabled={!contactValuesValid()}
        >
          <div className="contact-button-progress-box">
            {contactInProgress ? <Spinner className="contact-spinner" size={SpinnerSize.small} /> : null}
            <Trans i18nKey={CONTACT_ME}>Contact Me</Trans>
          </div>
        </PrimaryButton>
      </div>
    );
  };

  const onSubmitContactMeClick = async () => {
    trackUserAction({
      name: ANALYTICS_USER_ACTIONS.CONTACT_SUBMIT_REQUEST_CLICK,
      properties: { partnerId: props.partnerId, partnerListingId: props.partnerListingId }
    });
    if (!contactInProgress && contactValuesValid()) {
      const sendReferralRequest = new SendReferralRequest();
      sendReferralRequest.organizationId = props.partnerId;
      const selectedPartnerProfile = getSelectedPartnerProfile();
      sendReferralRequest.businessProfileId = selectedPartnerProfile ? selectedPartnerProfile.id : props.partnerListingId;
      sendReferralRequest.engagementId = '';
      sendReferralRequest.organizationName = props.partnerName;

      // customer profile
      sendReferralRequest.customerProfile.address.addressLine2 = '';
      sendReferralRequest.customerProfile.address.city = city;
      sendReferralRequest.customerProfile.address.country = country;
      sendReferralRequest.customerProfile.name = organizationName;
      sendReferralRequest.customerProfile.size = organizationSize;
      sendReferralRequest.customerProfile.address.postalCode = zipCode;
      // Team
      sendReferralRequest.customerProfile.team.push(new Team());
      sendReferralRequest.customerProfile.team[0].firstName = firstName;
      sendReferralRequest.customerProfile.team[0].lastName = lastName;
      sendReferralRequest.customerProfile.team[0].email = email;
      sendReferralRequest.customerProfile.team[0].phoneNumber = phone;

      // Set user message or auto generated empty message if notes not allowed for this type of user.
      const notes = isHelpNeededDisabled() ? NO_CONTENT : helpNeeded;

      //Details
      sendReferralRequest.details.notes = notes;
      sendReferralRequest.details.requirements.industries.push(new IdsObj());
      sendReferralRequest.details.requirements.industries[0].id = industreyFocus;

      //inviteContext
      sendReferralRequest.inviteContext.notes = notes;
      const partnerIdMessage = 'partner id = ' + sendReferralRequest.organizationId + '/' + sendReferralRequest.businessProfileId;
      try {
        trackUserAction({
          name: ANALYTICS_USER_ACTIONS.CONTACT_SUBMIT_REQUEST_START,
          properties: { partnerId: props.partnerId, partnerListingId: props.partnerListingId }
        });
        reportPartnerCenterSubmitClick();
        Logger.Info(LOGGER_PREFIX, 'contact partner - ' + partnerIdMessage);
        setContactInProgress(true);
        await contactPartnerRequest([sendReferralRequest], props.user.accessToken);
        Logger.Info(LOGGER_PREFIX, 'contact succeeded - ' + partnerIdMessage);
        trackUserAction({
          name: ANALYTICS_USER_ACTIONS.CONTACT_SUBMIT_REQUEST_SUCCESS,
          properties: { partnerId: props.partnerId, partnerListingId: props.partnerListingId }
        });
        setContactInProgress(false);
        storeContactDetailsIfNeeded();
        if (props.onContactMessageSent) {
          props.onContactMessageSent();
        }
      } catch (error) {
        Logger.Err(LOGGER_PREFIX, 'contact failed - ' + partnerIdMessage + ' - ' + error);
        trackUserAction({
          name: ANALYTICS_USER_ACTIONS.CONTACT_SUBMIT_REQUEST_FAILED,
          properties: { partnerId: props.partnerId, partnerListingId: props.partnerListingId }
        });
        setContactInProgress(false);
        setShowErrorDialog(true);
      }
    }
  };

  const reportPartnerCenterSubmitClick = () => {
    const partnerCenterActionProps = {};
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.EVENT_NAME] =
      ANALYTICS_PARTNER_CENTER_ACTIONS.REQUEST_FOR_INFORMATION_PAGE_SUBMIT;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_ID] = props.partnerId;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_LISTING_ID] = props.partnerListingId;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_NAME] = props.partnerName;
    trackPartnerCenterAction({
      name: ANALYTICS_PARTNER_CENTER_ACTIONS.REQUEST_FOR_INFORMATION_PAGE_SUBMIT,
      properties: partnerCenterActionProps
    });
  };

  const storeContactDetailsIfNeeded = () => {
    if (isIframe) {
      const storeContactDetails: IStoreContactDetailsMessage = {
        messageType: PartnersHostMessageType.StoreContactDetails,
        contactDetails: {
          firstName: firstName,
          lastName: lastName,
          city: city,
          country: country,
          email: email,
          helpNeeded: helpNeeded,
          industreyFocus: industreyFocus,
          organizationName: organizationName,
          organizationSize: organizationSize,
          phone: phone,
          state: state,
          zipCode: zipCode
        }
      };
      const outboundMessagesHandler = new OutboundMessagesHandler();
      outboundMessagesHandler.postStoreContactDetailsMessage(storeContactDetails);
    }
  };

  const onHideErrorDialog = () => {
    setShowErrorDialog(false);
    if (props.onDismissDialog) {
      props.onDismissDialog();
    }
  };

  const onDismissDialog = () => {
    if (!contactInProgress && props.onDismissDialog) {
      props.onDismissDialog();
    }
  };

  const getModalAbsPosStyles = () => {
    let height = DEFAULT_HEIGHT;
    if (hostSize.height > 0) {
      height = hostSize.height;
    }
    let frameTop = DEFAULT_FRAME_TOP;
    if (hostSize.frameTop > 0) {
      frameTop = hostSize.frameTop;
    }
    return mergeStyleSets({
      root: {
        position: 'absolute'
      },
      main: {
        position: 'absolute',
        top: (height - frameTop) * 0.1
      },
      layer: {
        position: 'absolute'
      }
    });
  };

  const getHeight = () => {
    if (isIframe) {
      let height = DEFAULT_HEIGHT;
      if (hostSize.height > 0) {
        height = hostSize.height;
      }
      let frameTop = DEFAULT_FRAME_TOP;
      if (hostSize.frameTop > 0) {
        frameTop = hostSize.frameTop;
      }
      return (height - frameTop) * 0.8;
    } else {
      return window.innerHeight * 0.7;
    }
  };

  if (showErrorDialog)
    return (
      <Dialog hidden={!showErrorDialog} onDismiss={onHideErrorDialog}>
        <p>Submit contact request failed</p>
        <DialogFooter>
          <PrimaryButton onClick={onHideErrorDialog} text="Close" />
        </DialogFooter>
      </Dialog>
    );
  else {
    const height = getHeight();
    const style = isIframe ? getModalAbsPosStyles() : null;
    return (
      <Modal
        styles={style}
        isOpen={props.isDialogOpen}
        onDismiss={onDismissDialog}
        titleAriaId="ContactMeDialogTitle"
        isBlocking={false}
        containerClassName="ms-dialogMainOverride"
      >
        <div
          style={{ direction: props.directionRtl ? 'rtl' : 'ltr', textAlign: props.directionRtl ? 'right' : 'left', height }}
          className="contact-partner-dialog"
        >
          {renderHeader()}
          {renderContent()}
          <Separator className="disclaimer-seperator" />
          {renderDisclaimer()}
          {renderButtons()}
        </div>
      </Modal>
    );
  }
}
