import React, { useEffect, useState } from 'react';
import { DefaultButton, TooltipHost, Image, mergeStyleSets, ImageFit } from '@fluentui/react';
import { Trans, useTranslation } from 'react-i18next';
import { CONTACT_ME, DIVERSE_BUSINESS, PARTNER_LOGO_ALT } from '../../constants/localization';
import { SYSTEM_KEY_CODES } from '../../constants/keyboardNavigation';
import { IPartner } from '../../shared/models/partnerModel';
import { getLocationDisplay } from '../../utils/countryStatesUtils';
import ContactPartnerManager from '../contactPartner/contactPartnerManager';
import { IViewContext } from '../../protocol/partnersViewInboundMessages';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { getContext } from '../../redux/hostContextSlice';
import { runningInIframe } from '../../utils/iframeUtils';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import { IChangeUrlGalleryMessage, PartnersHostMessageType } from '../../protocol/partnersHostInboundMessages';
import { useHistory, useParams } from 'react-router-dom';
import { trimToLengthWithEllipsis } from '../../utils/stringUtils';
import {
  ANALYTICS_PARTNER_CENTER_ACTIONS,
  ANALYTICS_USER_ACTIONS,
  PARTNER_CENTER_ACTIONS_PROPS_NAMES,
  trackPartnerCenterAction,
  trackUserAction
} from '../../services/analyticsService';
import { isRtl } from '../../utils/rtlUtils';
import ProductsBox from '../common/productsBox';
import { GetEnvConfiguration } from '../../services/configurationService';

const MAX_PRODUCT_IN_TILE = 1;
const MAX_CHARS_IN_DESCRIPTION = 120;

interface TileListProps {
  partner: IPartner;
  onClick?: any;
  contactMode: boolean;
  filterQuryParam: string;
}

const contentStyles = mergeStyleSets({
  tileImage: {
    width: '100%',
    height: '100%'
  }
});

export default function TileList(props: TileListProps) {
  const { t: translate } = useTranslation();
  const viewContext: IViewContext = useSelector((store: RootState) => getContext(store.hostContext));
  const { locale } = useParams<{ locale: string }>();
  const history = useHistory();
  const isIframe: boolean = runningInIframe();
  const outboundMessagesHandler = new OutboundMessagesHandler();
  const [isCardHover, setIsCardHover] = useState(false);

  useEffect(() => {
    if (isIframe && props.contactMode) {
      requestLoginfNeeded();
    }
  }, [props.contactMode]);

  const renderName = () => {
    return <p className="name">{props.partner.name}</p>;
  };

  const isDiversedBusiness = () => {
    return (
      (props.partner.daiOwnedLed && props.partner.daiOwnedLed.length > 0) ||
      (props.partner.daiThirdPartyAccreditation && props.partner.daiThirdPartyAccreditation.length > 0)
    );
  };

  const renderTopSection = () => {
    const envConfig = GetEnvConfiguration();
    return (
      <div className="tile-top-section">
        <div className="tile-icon ms-depth-4">
          <Image
            src={props.partner.logo}
            alt={translate(PARTNER_LOGO_ALT, { partnerName: props.partner.name })}
            className={contentStyles.tileImage}
            imageFit={ImageFit.center}
          />
        </div>
        {envConfig.DIVERSE_INCLUSION_ENABLED && isDiversedBusiness() ? (
          <TooltipHost content={translate(DIVERSE_BUSINESS)}>
            <div className={'icon-diversity'} />
          </TooltipHost>
        ) : null}
      </div>
    );
  };

  const renderLocation = () => {
    const locationDisplay = getLocationDisplay(props.partner.location, true);
    return <p className="location">{locationDisplay}</p>;
  };

  const renderDescription = () => {
    const dispcriptionDisplay = trimToLengthWithEllipsis(props.partner.description, MAX_CHARS_IN_DESCRIPTION);
    return <p className="description">{dispcriptionDisplay}</p>;
  };

  const onContactMeClick = (e: any) => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_CONTACT_CLICK });
    reportPartnerCenterContactClick();
    if (!props.contactMode) {
      enterContactPage();
    }
    e.stopPropagation();
  };

  const reportPartnerCenterContactClick = () => {
    const partnerCenterActionProps = {};
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.EVENT_NAME] =
      ANALYTICS_PARTNER_CENTER_ACTIONS.SEARCH_PAGE_CONTACT_PARTNER_BUTTON_CLICKED;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_ID] = props.partner.partnerId;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_LISTING_ID] = props.partner.id;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_NAME] = props.partner.name;
    trackPartnerCenterAction({
      name: ANALYTICS_PARTNER_CENTER_ACTIONS.SEARCH_PAGE_CONTACT_PARTNER_BUTTON_CLICKED,
      properties: partnerCenterActionProps
    });
  };

  const enterContactPage = () => {
    if (isIframe) {
      const changeUrlMessage: IChangeUrlGalleryMessage = {
        messageType: PartnersHostMessageType.ChangeUrlGallery,
        galleryFilter: props.filterQuryParam,
        contact: props.partner.id,
        replaceUrl: true
      };
      outboundMessagesHandler.postChangeUrlGalleryMessage(changeUrlMessage);
    } else {
      const pathName = `/${locale}/partners?filter=${props.filterQuryParam}&contact=${props.partner.id}`;
      history.replace(pathName);
    }
  };

  const requestLoginfNeeded = () => {
    if (!userSignedIn()) {
      outboundMessagesHandler.postLoginRequest();
    }
  };

  const userSignedIn = () => {
    return viewContext && viewContext.user && viewContext.user.signedIn;
  };

  const leaveContactPage = () => {
    if (isIframe) {
      const changeUrlMessage: IChangeUrlGalleryMessage = {
        messageType: PartnersHostMessageType.ChangeUrlGallery,
        galleryFilter: props.filterQuryParam,
        contact: null,
        replaceUrl: true
      };
      outboundMessagesHandler.postChangeUrlGalleryMessage(changeUrlMessage);
    } else {
      const pathName = `/${locale}/partners?filter=${props.filterQuryParam}`;
      history.replace(pathName);
    }
  };

  const renderButtons = () => {
    return (
      <div className="contact-section-wrapper">
        <div className="buttons-box">
          <DefaultButton
            name="button"
            primary={isCardHover}
            className="contact-button"
            type="submit"
            onClick={onContactMeClick}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
              if (e.code === SYSTEM_KEY_CODES.Enter) {
                onContactMeClick(e);
              }
            }}
          >
            <Trans i18nKey={CONTACT_ME}>Contact Me</Trans>
          </DefaultButton>
        </div>
      </div>
    );
  };

  const renderTileProducts = () => {
    return <ProductsBox products={props.partner.product} maxProducts={MAX_PRODUCT_IN_TILE}></ProductsBox>;
  };

  const onContactMeDialogDismiss = () => {
    leaveContactPage();
  };

  const showContactFlow = () => {
    if (isIframe) {
      return props.contactMode && userSignedIn();
    } else {
      return props.contactMode;
    }
  };

  const renderContactMeFlow = () => {
    if (showContactFlow()) {
      return (
        <ContactPartnerManager
          partnerId={props.partner.partnerId}
          partnerListingId={props.partner.id}
          partnerName={props.partner.name}
          partnerLogo={props.partner.logo}
          partnerLocation={props.partner.location}
          allLocations={[props.partner.location]}
          allPartnerDetails={[]}
          loadPartnerDetails={true}
          isRtl={isRtl()}
          onDismiss={onContactMeDialogDismiss}
        />
      );
    } else return null;
  };

  const handleTileClick = () => {
    if (props.onClick) {
      props.onClick(props.partner);
    }
  };

  const handleTileKeyDown = (evt: React.KeyboardEvent) => {
    if (props.onClick && evt.code === SYSTEM_KEY_CODES.Enter) {
      props.onClick(props.partner);
    }
  };

  return (
    <div role="listitem">
      <div
        onMouseEnter={() => setIsCardHover(true)}
        onMouseLeave={() => setIsCardHover(false)}
        className="tile-container card ms-depth-8"
        onClick={() => handleTileClick()}
        onKeyDown={e => {
          handleTileKeyDown(e);
        }}
        tabIndex={0}
      >
        {renderTopSection()}
        {renderName()}
        {renderLocation()}
        {renderTileProducts()}
        <div className="tile-bottom-box">
          {renderDescription()}
          {renderButtons()}
        </div>
      </div>
      {renderContactMeFlow()}
    </div>
  );
}
