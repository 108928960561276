import { RequestOptions } from 'https';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { GetEnvConfiguration } from './configurationService';
import * as Logger from './loggerService';
import { monitorOutgoingRequest } from './monitorService';
import { Timer } from '../utils/timer';

const env = GetEnvConfiguration();

const LOGGER_PREFIX = 'Http Client:';
const CLIENT_NO_RESPONSE_MESSAGE = 'Client_No_Response_Code';
const CLIENT_NO_RESPONSE_CODE = -100;

const RETRIES_COUNT = 3; // number of retries (in addition to the original request).
const RETRY_RESPONSE_CODES = [502, 503, 504]; // response codes that should cause retry.

const APP_SERVICE_HEADER_NAME = 'app-service-name';

const instance = axios.create({
  baseURL: env.SERVER_BASE_API_URL
});

axiosRetry(instance, {
  retries: RETRIES_COUNT,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error: AxiosError<any>) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || RETRY_RESPONSE_CODES.includes(error.response?.status);
  }
});
export class HttpClient {
  public static async get(endpoint: string, monitorName: string, options?: RequestOptions, params?: any) {
    const timer = new Timer();
    try {
      const axiosResponse = await instance.get(endpoint, {
        headers: options?.headers,
        params: params
      });
      const appServiceName = axiosResponse.headers ? axiosResponse.headers[APP_SERVICE_HEADER_NAME] : null;
      monitorSuccessRequest(monitorName, endpoint, 'GET', timer, appServiceName, axiosResponse?.status);
      return axiosResponse;
    } catch (error) {
      handleRequestException(error, 'GET', monitorName, endpoint, timer);
    }
  }

  public static async post(endpoint: string, monitorName: string, data: any, options?: RequestOptions, params?: any) {
    const timer = new Timer();
    try {
      const axiosResponse = await instance.post(endpoint, data, {
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers
        },
        params: params
      });
      const appServiceName = axiosResponse.headers ? axiosResponse.headers[APP_SERVICE_HEADER_NAME] : null;
      monitorSuccessRequest(monitorName, endpoint, 'POST', timer, appServiceName, axiosResponse?.status);
      return axiosResponse;
    } catch (error) {
      handleRequestException(error, 'POST', monitorName, endpoint, timer);
    }
  }
}

const handleRequestException = (error: any, requestType: string, monitorName: string, endpoint: string, timer: Timer) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (!axiosError.response) {
      const errorMessage = axiosError.message;
      const errorType = CLIENT_NO_RESPONSE_MESSAGE;
      const status = CLIENT_NO_RESPONSE_CODE;
      Logger.Exception(LOGGER_PREFIX, errorMessage);
      monitorFailedRequest(monitorName, endpoint, requestType, timer, null, errorMessage, status, errorType);
    } else {
      const appServiceName = axiosError.response.headers ? axiosError.response.headers[APP_SERVICE_HEADER_NAME] : null;
      const errorDetails = axiosError.response.data?.error ? JSON.stringify(axiosError.response.data.error) : axiosError.response.data;
      const errorMessage = `status: ${axiosError.response?.status}, text: ${axiosError.response?.statusText}, details: ${errorDetails}`;
      const errorType = axiosError.response?.data?.error?.errorType;
      Logger.Exception(LOGGER_PREFIX, errorMessage);
      monitorFailedRequest(monitorName, endpoint, requestType, timer, appServiceName, errorMessage, axiosError.response?.status, errorType);
    }
  } else {
    Logger.Exception(LOGGER_PREFIX, error);
    monitorFailedRequest(monitorName, endpoint, requestType, timer, null, error);
  }
  throw new Error(error.message);
};

const monitorFailedRequest = (
  name: string,
  url: string,
  type: string,
  timer: Timer,
  appServiceName: string,
  error: any,
  status?: number,
  errorType?: any
) => {
  monitorOutgoingRequest({
    name,
    url,
    type,
    success: false,
    error: error ? error.toString() : null,
    duration: timer.getElapsedTime(),
    status,
    errorType,
    appServiceName
  });
};

const monitorSuccessRequest = (name: string, url: string, type: string, timer: Timer, appServiceName: string, status?: number) => {
  monitorOutgoingRequest({
    name,
    url,
    type,
    success: true,
    duration: timer.getElapsedTime(),
    status,
    appServiceName
  });
};
