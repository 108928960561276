import { Status } from './commonModels';
import { IPartner } from './partnerModel';
import { IPartnerDetailsResponse } from './Response/partnerDetails';

export interface IPartnersDetailsStore {
  data: IPartnerDetailsList;
  error: string;
  status: Status;
}
export interface IPartnerDetailsList {
  [id: string]: IPartnerDetailsResponse;
}

export interface IPartnerAddress {
  country: string;
  region: string;
  state: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
}
export interface IPartnerLocation {
  address: IPartnerAddress;
  id: string;
}
export interface IPartnerDetails {
  additionalInfo: any;
  description: string;
  friendlyName: string;
  id: string;
  industryFocus: string[];
  industryFocusEndorsements: [];
  linkedInOrganizationProfile: string;
  location: IPartnerLocation;
  logo: string;
  name: string;
  organizationSize: string;
  product: string[];
  productEndorsements: any;
  programQualificationsAsp: string[];
  programQualificationsMsp: any;
  serviceType: string[];
  serviceTypeEndorsements: any;
  skills: string[];
  solutions: string[];
  targetCustomerCompanySizes: string[];
  targetCustomerCompanySize: string;
  universalAccountId: string;
  url: string;
  daiOwnedLed: string[];
  daiThirdPartyAccreditation: string[];
  solutionsPartnerDesignations: string[];
  trainingServicesPartnerDesignations: string[];
}

export class PartnerDetailsImp implements IPartnerDetails {
  constructor(partner: IPartner) {
    this.description = partner.description;
    this.name = partner.name;
    this.id = partner.id;
    this.industryFocus = partner.industryFocus;
    this.location = partner.location;
    this.logo = partner.logo;
    this.name = partner.name;
    this.product = partner.product;
    this.programQualificationsAsp = partner.programQualificationsAsp;
    this.programQualificationsMsp = partner.programQualificationsMsp;
    this.serviceType = partner.serviceType;
    this.solutions = partner.solutions;
    this.universalAccountId = partner.partnerId;
    this.daiOwnedLed = partner.daiOwnedLed;
    this.daiThirdPartyAccreditation = partner.daiThirdPartyAccreditation;
    this.solutionsPartnerDesignations = partner.solutionsPartnerDesignations;
    this.trainingServicesPartnerDesignations = partner.trainingServicesPartnerDesignations;
  }

  additionalInfo: any;
  description: string;
  friendlyName: string;
  id: string;
  industryFocus: string[] = [];
  industryFocusEndorsements: [] = [];
  linkedInOrganizationProfile: string;
  location: IPartnerLocation;
  logo: string;
  name: string;
  organizationSize: string;
  product: string[] = [];
  productEndorsements: any;
  programQualificationsAsp: string[] = [];
  programQualificationsMsp: any;
  serviceType: string[] = [];
  serviceTypeEndorsements: any;
  skills: string[] = [];
  solutions: string[] = [];
  targetCustomerCompanySizes: string[] = [];
  targetCustomerCompanySize: string;
  universalAccountId: string;
  url: string;
  daiOwnedLed: string[];
  daiThirdPartyAccreditation: string[];
  solutionsPartnerDesignations: string[];
  trainingServicesPartnerDesignations: string[];
}
