import React, { useRef, useState } from 'react';
import { Label, Stack, TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { DIVERSE_BUSINESS, DIVERSE_BUSINESS_DESCRIPTION, LOCATION, PARTNER_LOGO_ALT } from '../../constants/localization';
import { IPartnerLocation } from '../../shared/models/partnerDetailsModel';
import PartnerLocationDropDown from '../common/partnerLocationDropDown';

export interface IPartnerDetailsCard {
  title: string;
  location: IPartnerLocation;
  allLocations: IPartnerLocation[];
  logoURL: string;
  children: any;
  isDiverseBusiness: boolean;
  onLocationChange(location: IPartnerLocation): void;
}

export default function PartnerDetailsCard(props: IPartnerDetailsCard) {
  const { t: translate } = useTranslation();
  const MAX_DROPDOWN_HEIGHT = 400;
  const DROPDOWN_WIDTH = 500;

  const renderImage = () => {
    return props.logoURL ? (
      <div className="partner-icon-host ms-depth-8">
        <div className="partner-large-icon">
          <div>
            <img src={props.logoURL} alt={translate(PARTNER_LOGO_ALT, { partnerName: props.title })} />
          </div>
        </div>
      </div>
    ) : null;
  };

  const renderDiverseBusiness = () => {
    return props.isDiverseBusiness ? (
      <div className="diverse-container">
        <TooltipHost content={translate(DIVERSE_BUSINESS_DESCRIPTION)}>
          <div className="diverse-block">
            <div className="icon-diversity" />
            <div className="diverse-label">{translate(DIVERSE_BUSINESS)}</div>
          </div>
        </TooltipHost>
      </div>
    ) : null;
  };

  return (
    <div className="card ms-depth-8">
      {renderImage()}
      <div className="header">
        <div className="title-block">
          <h1 className="ms-fontSize-28 ms-fontWeight-semibold title">{props.title}</h1>
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <Label>{translate(LOCATION)}</Label>
            <Stack.Item grow>
              <div className="partner-location-box">
                <PartnerLocationDropDown
                  key="partner_details_locations_dropdown"
                  width={DROPDOWN_WIDTH}
                  maxDropDownHeight={MAX_DROPDOWN_HEIGHT}
                  location={props.location}
                  allLocations={props.allLocations}
                  underLineBorder={true}
                  loadingProgress={false}
                  onLocationChange={props.onLocationChange}
                />
              </div>
            </Stack.Item>
          </Stack>
          {renderDiverseBusiness()}
          <div className="main-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
