import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFeatureFlags {
  trainingDesignationsEnabled: boolean;
}

export class FeatureFlags implements IFeatureFlags {
  constructor(trainingDesignationsEnabled: boolean) {
    this.trainingDesignationsEnabled = trainingDesignationsEnabled;
  }
  trainingDesignationsEnabled = false;
}

export interface IConfigStore {
  featureFlags: IFeatureFlags;
}

const initialState: IConfigStore = {
  featureFlags: new FeatureFlags(false)
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setFeatureFlags: (state, { payload }: PayloadAction<{ featureFlags: IFeatureFlags }>) => {
      state.featureFlags = payload.featureFlags;
    }
  }
});

export const { setFeatureFlags } = configSlice.actions;
export const getFeatureFlags = (configStore: IConfigStore) => configStore.featureFlags;
export default configSlice.reducer;
