import { Spinner, SpinnerSize } from '@fluentui/react';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOADING } from '../../constants/localization';
import { IViewContext } from '../../protocol/partnersViewInboundMessages';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { getContext } from '../../redux/hostContextSlice';
import { runningInIframe } from '../../utils/iframeUtils';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AutoCompleteSuggestionType } from '../../shared/models/Response/searchModels';
import { PartnersFilter } from '../../shared/models/Request/partnersFilter';
import { getFilterLocation, getUserLocation, IFilterLocation, IUserLocation } from '../../redux/locationSlice';
import { FilterLocationService } from '../../services/filterLocationService';
import { searchPartnerForName } from '../../sagas/apis';
import * as Logger from '../../services/loggerService';
import { monitorDependency, MONITORS_DEPENDENCIES } from '../../services/monitorService';
import { IChangeUrlDetailsMessage, IChangeUrlGalleryMessage, PartnersHostMessageType } from '../../protocol/partnersHostInboundMessages';
import { OVERVIEW_TAB } from '../partnerDetails/partnerDetails';
import * as PartnerActions from '../../redux/partnersSlice';

const LOGGER_PREFIX = 'SearchSuggestionHandler:';

export default function SearchSuggestionHandler() {
  const { t: translate } = useTranslation();
  const urlLocation = useLocation();
  const viewContext: IViewContext = useSelector((store: RootState) => getContext(store.hostContext));
  const userLocation: IUserLocation = useSelector((store: RootState) => getUserLocation(store.location));
  const lastFilterLocation: IFilterLocation = useSelector((store: RootState) => getFilterLocation(store.location));
  const { locale } = useParams<{ locale: string }>();
  const history = useHistory();
  const isIframe: boolean = runningInIframe();
  const [suggestionValue, setSuggestionValue] = useState('');
  const [suggestionText, setSuggestionText] = useState('');
  const [suggestionType, setSuggestionType] = useState<AutoCompleteSuggestionType>();
  const [suggestionFilter, setSuggestionFilter] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(urlLocation.search);
    // We have filter in the url.
    const value = decodeURIComponent(searchParams.get('value'));
    const text = decodeURIComponent(searchParams.get('text'));
    const type = decodeURIComponent(searchParams.get('type'));
    const filter = decodeURIComponent(searchParams.get('filter'));
    const suggestionType: AutoCompleteSuggestionType = type ? (parseInt(type) as AutoCompleteSuggestionType) : undefined;
    setSuggestionValue(value);
    setSuggestionText(text);
    setSuggestionFilter(filter);
    setSuggestionType(suggestionType);
  }, []);

  useEffect(() => {
    if (suggestionType != undefined && suggestionValue && suggestionText) {
      handleSuggestion();
    }
  }, [suggestionType, suggestionValue, suggestionText]);

  const handleSuggestion = async () => {
    if (suggestionType === AutoCompleteSuggestionType.Partner) {
      handlePartnerSuggestion();
    } else {
      handleFilterSuggestion();
    }
  };

  const handlePartnerSuggestion = async () => {
    try {
      const partner = await searchPartnerForName(suggestionValue);
      if (partner) {
        // Add this partner to cache to enable PDP to load fast.
        dispatch(PartnerActions.addPartnerToCache(partner));
        // iframe mode - external routing.
        if (isIframe) {
          monitorDependency({ name: MONITORS_DEPENDENCIES.SEARCH_ROUTING_PARTNER_NAME_TO_ID, success: true });
          const outboundMessagesHandler = new OutboundMessagesHandler();
          const changeUrlMessage: IChangeUrlDetailsMessage = {
            messageType: PartnersHostMessageType.ChangeUrlDetails,
            partnerId: partner.partnerId,
            tab: OVERVIEW_TAB,
            contact: false,
            replaceUrl: true
          };
          outboundMessagesHandler.postChangeUrlDetailsMessage(changeUrlMessage);
        }
        // standalone mode - local routing.
        else {
          const pathName = `/${locale}/partners/${partner.partnerId}`;
          history.replace(pathName);
        }
      } else {
        monitorDependency({ name: MONITORS_DEPENDENCIES.SEARCH_ROUTING_PARTNER_NAME_TO_ID, success: false });
      }
    } catch (error) {
      Logger.Exception(LOGGER_PREFIX, error.toString(), 'Exception during searchPartnerIdForName call');
      monitorDependency({ name: MONITORS_DEPENDENCIES.SEARCH_ROUTING_PARTNER_NAME_TO_ID, success: false });
    }
  };

  const handleFilterSuggestion = () => {
    const filter = PartnersFilter.createFilterFromSearchSuggestion(suggestionValue, suggestionType, suggestionFilter);
    const filterLocationService = new FilterLocationService(filter, userLocation, lastFilterLocation, viewContext);
    filterLocationService.updateFilterLocationIfNeeded();
    const filterQueryParam = filter.getPrettyQueryParam();
    // iframe mode - external routing.
    if (isIframe) {
      const outboundMessagesHandler = new OutboundMessagesHandler();
      const changeUrlMessage: IChangeUrlGalleryMessage = {
        messageType: PartnersHostMessageType.ChangeUrlGallery,
        galleryFilter: filterQueryParam,
        contact: null,
        replaceUrl: true
      };
      outboundMessagesHandler.postChangeUrlGalleryMessage(changeUrlMessage);
      // standalone mode - use local routing.
    } else {
      const pathName = `/${locale}/partners?filter=${filterQueryParam}`;
      history.replace(pathName);
    }
  };

  return (
    <div className="loading-panel">
      <Spinner size={SpinnerSize.large} label={translate(LOADING)} />
    </div>
  );
}
