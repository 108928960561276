import { IPartnerLocation } from '../shared/models/partnerDetailsModel';
import * as Logger from '../services/loggerService';
import { GetEnvConfiguration } from '../services/configurationService';

const LOGGER_PREFIX = 'Country State Utils:';

export enum LocationSubType {
  Country,
  State,
  City
}

export const USA_COUNTRY_CODE = 'US';
export const CANADA_COUNTRY_CODE = 'CA';
export const CONTRY_CODE_WITH_STATES = [USA_COUNTRY_CODE, CANADA_COUNTRY_CODE];

export interface IPartnerLocationNode {
  name: string;
  subType: LocationSubType;
  children: IPartnerLocationNode[];
  location?: IPartnerLocation;
}

export interface ILocationNames {
  code: string;
  name: string;
}

//Create tree from list of locations in the following structure -
// [Country] --> [State(optionsl available for US and CA)] --> [City]
export function createLocationsTree(locations: IPartnerLocation[]): IPartnerLocationNode[] {
  const countryToNodeMap: Map<string, IPartnerLocationNode> = new Map<string, IPartnerLocationNode>();
  const countryToLocationsMap: Map<string, IPartnerLocation[]> = new Map<string, IPartnerLocation[]>();
  const stateToNodeMap: Map<string, IPartnerLocationNode> = new Map<string, IPartnerLocationNode>();
  const statesToLocationsMap: Map<string, IPartnerLocation[]> = new Map<string, IPartnerLocation[]>();

  //Map countries
  locations.forEach((partnerLocation: IPartnerLocation) => {
    const country = partnerLocation.address.country;

    if (!countryToLocationsMap.has(country)) {
      countryToLocationsMap.set(country, []);
      countryToNodeMap.set(country, {
        name: country,
        subType: LocationSubType.Country,
        children: []
      });
    }

    countryToLocationsMap.get(country).push(partnerLocation);
  });

  //Map states
  countryToLocationsMap.forEach((countryLocations: IPartnerLocation[], country: string) => {
    countryLocations.forEach((partnerLocation: IPartnerLocation) => {
      const state = partnerLocation.address.state;
      const city = partnerLocation.address.city;

      // Map states in case it is relevant
      if (CONTRY_CODE_WITH_STATES.includes(country) && state && state !== country && state !== city) {
        if (!statesToLocationsMap.has(state)) {
          statesToLocationsMap.set(state, []);
          const stateLocationNode: IPartnerLocationNode = {
            name: state,
            subType: LocationSubType.State,
            children: []
          };
          stateToNodeMap.set(state, stateLocationNode);
          countryToNodeMap.get(country).children.push(stateLocationNode);
        }
        statesToLocationsMap.get(state).push(partnerLocation);
        // Map cities under countries
      } else if (city) {
        const cityLocationNode: IPartnerLocationNode = {
          name: city,
          subType: LocationSubType.City,
          children: [],
          location: partnerLocation
        };
        countryToNodeMap.get(country).children.push(cityLocationNode);
      }
    });
  });

  // Map cities under states
  statesToLocationsMap.forEach((stateLocations: IPartnerLocation[], state: string) => {
    stateLocations.forEach((partnerLocation: IPartnerLocation) => {
      const cityLocationNode: IPartnerLocationNode = {
        name: partnerLocation.address.city,
        subType: LocationSubType.City,
        children: [],
        location: partnerLocation
      };

      stateToNodeMap.get(state).children.push(cityLocationNode);
    });
  });
  return Array.from(countryToNodeMap.values());
}

export const AllCountriesObj = (): ILocationNames[] => {
  return [
    {
      code: 'AF',
      name: 'Afghanistan'
    },
    {
      code: 'AL',
      name: 'Albania'
    },
    {
      code: 'DZ',
      name: 'Algeria'
    },
    {
      code: 'AD',
      name: 'Andorra'
    },
    {
      code: 'AO',
      name: 'Angola'
    },
    {
      code: 'AR',
      name: 'Argentina'
    },
    {
      code: 'AM',
      name: 'Armenia'
    },
    {
      code: 'AU',
      name: 'Australia'
    },
    {
      code: 'AT',
      name: 'Austria'
    },
    {
      code: 'AZ',
      name: 'Azerbaijan'
    },
    {
      code: 'BH',
      name: 'Bahrain'
    },
    {
      code: 'BD',
      name: 'Bangladesh'
    },
    {
      code: 'BB',
      name: 'Barbados'
    },
    {
      code: 'BY',
      name: 'Belarus'
    },
    {
      code: 'BE',
      name: 'Belgium'
    },
    {
      code: 'BU',
      name: 'Burma'
    },
    {
      code: 'BZ',
      name: 'Belize'
    },
    {
      code: 'BM',
      name: 'Bermuda'
    },
    {
      code: 'BO',
      name: 'Bolivia'
    },
    {
      code: 'BA',
      name: 'Bosnia and Herzegovina'
    },
    {
      code: 'BW',
      name: 'Botswana'
    },
    {
      code: 'BR',
      name: 'Brazil'
    },
    {
      code: 'BN',
      name: 'Brunei'
    },
    {
      code: 'BG',
      name: 'Bulgaria'
    },
    {
      code: 'CM',
      name: 'Cameroon'
    },
    {
      code: 'CA',
      name: 'Canada'
    },
    {
      code: 'CV',
      name: 'Cabo Verde'
    },
    {
      code: 'KY',
      name: 'Cayman Islands'
    },
    {
      code: 'CL',
      name: 'Chile'
    },
    {
      code: 'CO',
      name: 'Colombia'
    },
    {
      code: 'CR',
      name: 'Costa Rica'
    },
    {
      code: 'CI',
      name: 'Côte d"Ivoire'
    },
    {
      code: 'HR',
      name: 'Croatia'
    },
    {
      code: 'CW',
      name: 'Curaçao'
    },
    {
      code: 'CY',
      name: 'Cyprus'
    },
    {
      code: 'CZ',
      name: 'Czech Republic'
    },
    {
      code: 'DK',
      name: 'Denmark'
    },
    {
      code: 'DO',
      name: 'Dominican Republic'
    },
    {
      code: 'EC',
      name: 'Ecuador'
    },
    {
      code: 'EG',
      name: 'Egypt'
    },
    {
      code: 'SV',
      name: 'El Salvador'
    },
    {
      code: 'EE',
      name: 'Estonia'
    },
    {
      code: 'ET',
      name: 'Ethiopia'
    },
    {
      code: 'FO',
      name: 'Faroe Islands'
    },
    {
      code: 'FJ',
      name: 'Fiji'
    },
    {
      code: 'FI',
      name: 'Finland'
    },
    {
      code: 'FR',
      name: 'France'
    },
    {
      code: 'GE',
      name: 'Georgia'
    },
    {
      code: 'DE',
      name: 'Germany'
    },
    {
      code: 'GH',
      name: 'Ghana'
    },
    {
      code: 'GR',
      name: 'Greece'
    },
    {
      code: 'GT',
      name: 'Guatemala'
    },
    {
      code: 'HN',
      name: 'Honduras'
    },
    {
      code: 'HK',
      name: 'Hong Kong SAR'
    },
    {
      code: 'HU',
      name: 'Hungary'
    },
    {
      code: 'IS',
      name: 'Iceland'
    },
    {
      code: 'IN',
      name: 'India'
    },
    {
      code: 'ID',
      name: 'Indonesia'
    },
    {
      code: 'IQ',
      name: 'Iraq'
    },
    {
      code: 'IE',
      name: 'Ireland'
    },
    {
      code: 'IL',
      name: 'Israel'
    },
    {
      code: 'IT',
      name: 'Italy'
    },
    {
      code: 'JM',
      name: 'Jamaica'
    },
    {
      code: 'JP',
      name: 'Japan'
    },
    {
      code: 'JO',
      name: 'Jordan'
    },
    {
      code: 'KZ',
      name: 'Kazakhstan'
    },
    {
      code: 'KE',
      name: 'Kenya'
    },
    {
      code: 'KR',
      name: 'Korea (South)'
    },
    {
      code: 'KW',
      name: 'Kuwait'
    },
    {
      code: 'KG',
      name: 'Kyrgyzstan'
    },
    {
      code: 'LV',
      name: 'Latvia'
    },
    {
      code: 'LB',
      name: 'Lebanon'
    },
    {
      code: 'LR',
      name: 'Liberia'
    },
    {
      code: 'LY',
      name: 'Libya'
    },
    {
      code: 'LI',
      name: 'Liechtenstein'
    },
    {
      code: 'LT',
      name: 'Lithuania'
    },
    {
      code: 'LU',
      name: 'Luxembourg'
    },
    {
      code: 'MO',
      name: 'Macao SAR'
    },
    {
      code: 'MK',
      name: 'North Macedonia'
    },
    {
      code: 'MY',
      name: 'Malaysia'
    },
    {
      code: 'MT',
      name: 'Malta'
    },
    {
      code: 'MU',
      name: 'Mauritius'
    },
    {
      code: 'MX',
      name: 'Mexico'
    },
    {
      code: 'MD',
      name: 'Moldova'
    },
    {
      code: 'MC',
      name: 'Monaco'
    },
    {
      code: 'MN',
      name: 'Mongolia'
    },
    {
      code: 'ME',
      name: 'Montenegro'
    },
    {
      code: 'MA',
      name: 'Morocco'
    },
    {
      code: 'NA',
      name: 'Namibia'
    },
    {
      code: 'NP',
      name: 'Nepal'
    },
    {
      code: 'NL',
      name: 'Netherlands'
    },
    {
      code: 'NI',
      name: 'Nicaragua'
    },
    {
      code: 'NZ',
      name: 'New Zealand'
    },
    {
      code: 'NG',
      name: 'Nigeria'
    },
    {
      code: 'NO',
      name: 'Norway'
    },
    {
      code: 'OM',
      name: 'Oman'
    },
    {
      code: 'PK',
      name: 'Pakistan'
    },
    {
      code: 'PS',
      name: 'Palestinian Authority'
    },
    {
      code: 'PA',
      name: 'Panama'
    },
    {
      code: 'PY',
      name: 'Paraguay'
    },
    {
      code: 'PE',
      name: 'Peru'
    },
    {
      code: 'PH',
      name: 'Philippines'
    },
    {
      code: 'PL',
      name: 'Poland'
    },
    {
      code: 'PT',
      name: 'Portugal'
    },
    {
      code: 'PR',
      name: 'Puerto Rico'
    },
    {
      code: 'QA',
      name: 'Qatar'
    },
    {
      code: 'RO',
      name: 'Romania'
    },
    {
      code: 'RU',
      name: 'Russia'
    },
    {
      code: 'RW',
      name: 'Rwanda'
    },
    {
      code: 'KN',
      name: 'Saint Kitts and Nevis'
    },
    {
      code: 'SA',
      name: 'Saudi Arabia'
    },
    {
      code: 'SN',
      name: 'Senegal'
    },
    {
      code: 'RS',
      name: 'Serbia'
    },
    {
      code: 'SG',
      name: 'Singapore'
    },
    {
      code: 'SK',
      name: 'Slovakia'
    },
    {
      code: 'SI',
      name: 'Slovenia'
    },
    {
      code: 'ZA',
      name: 'South Africa'
    },
    {
      code: 'ES',
      name: 'Spain'
    },
    {
      code: 'LK',
      name: 'Sri Lanka'
    },
    {
      code: 'SE',
      name: 'Sweden'
    },
    {
      code: 'CH',
      name: 'Switzerland'
    },
    {
      code: 'TW',
      name: 'Taiwan'
    },
    {
      code: 'TJ',
      name: 'Tajikistan'
    },
    {
      code: 'TZ',
      name: 'Tanzania'
    },
    {
      code: 'TH',
      name: 'Thailand'
    },
    {
      code: 'TT',
      name: 'Trinidad and Tobago'
    },
    {
      code: 'TN',
      name: 'Tunisia'
    },
    {
      code: 'TR',
      name: 'Turkey'
    },
    {
      code: 'TM',
      name: 'Turkmenistan'
    },
    {
      code: 'VA',
      name: 'Vatican City (Holy See)'
    },
    {
      code: 'VI',
      name: 'Virgin Islands of the United States'
    },
    {
      code: 'UG',
      name: 'Uganda'
    },
    {
      code: 'UA',
      name: 'Ukraine'
    },
    {
      code: 'AE',
      name: 'United Arab Emirates'
    },
    {
      code: 'GB',
      name: 'United Kingdom'
    },
    {
      code: 'US',
      name: 'United States'
    },
    {
      code: 'UY',
      name: 'Uruguay'
    },
    {
      code: 'UZ',
      name: 'Uzbekistan'
    },
    {
      code: 'VE',
      name: 'Venezuela'
    },
    {
      code: 'VN',
      name: 'Vietnam'
    },
    {
      code: 'YE',
      name: 'Yemen'
    },
    {
      code: 'ZM',
      name: 'Zambia'
    },
    {
      code: 'ZW',
      name: 'Zimbabwe'
    },
    {
      code: 'CN',
      name: 'China'
    },
    {
      code: 'SZ',
      name: 'Swaziland'
    },
    {
      code: 'MZ',
      name: 'Mozambique'
    },
    {
      code: 'LS',
      name: 'Lesotho'
    },
    {
      code: 'CK',
      name: 'Cook Islands'
    },
    {
      code: 'CX',
      name: 'Christmas Island'
    },
    {
      code: 'XK',
      name: 'Kosovo'
    },
    {
      code: 'MR',
      name: 'Mauritania'
    },
    { code: 'SO', name: 'MS Official Short Form' },
    { code: 'AX', name: 'Aland Islands' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AI', name: 'Anguilla' },
    { code: 'AQ', name: 'Antarctica' },
    { code: 'AG', name: 'Antigua and Barbuda' },
    { code: 'AW', name: 'Aruba' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BJ', name: 'Benin' },
    { code: 'BT', name: 'Bhutan' },
    { code: 'BQ', name: 'Bonaire' },
    { code: 'BV', name: 'Bouvet Island' },
    { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'VG', name: 'British Virgin Islands' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BI', name: 'Burundi' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'TD', name: 'Chad' },
    { code: 'CC', name: 'Cocos (Keeling) Islands' },
    { code: 'KM', name: 'Comoros' },
    { code: 'CG', name: 'Congo' },
    { code: 'CD', name: 'Congo (DRC)' },
    { code: 'CU', name: 'Cuba' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DM', name: 'Dominica' },
    { code: 'GQ', name: 'Equatorial Guinea' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'FK', name: 'Falkland Islands' },
    { code: 'GF', name: 'French Guiana' },
    { code: 'PF', name: 'French Polynesia' },
    { code: 'TF', name: 'French Southern Territories' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GM', name: 'Gambia' },
    { code: 'GI', name: 'Gibraltar' },
    { code: 'GL', name: 'Greenland' },
    { code: 'GD', name: 'Grenada' },
    { code: 'GP', name: 'Guadeloupe' },
    { code: 'GU', name: 'Guam' },
    { code: 'GG', name: 'Guernsey' },
    { code: 'GN', name: 'Guinea' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'GY', name: 'Guyana' },
    { code: 'HT', name: 'Haiti' },
    { code: 'HM', name: 'Heard Island and McDona,ld Islands' },
    { code: 'IR', name: 'Iran' },
    { code: 'IM', name: 'Isle of Man' },
    { code: 'XJ', name: 'Jan Mayen' },
    { code: 'JE', name: 'Jersey' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'LA', name: 'Laos' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'MW', name: 'Malawi' },
    { code: 'MV', name: 'Maldives' },
    { code: 'ML', name: 'Mali' },
    { code: 'MH', name: 'Marshall Islands' },
    { code: 'MQ', name: 'Martinique' },
    { code: 'YT', name: 'Mayotte' },
    { code: 'FM', name: 'Micronesia' },
    { code: 'MS', name: 'Montserrat' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'NR', name: 'Nauru' },
    { code: 'NC', name: 'New Caledonia' },
    { code: 'NE', name: 'Niger' },
    { code: 'NU', name: 'Niue' },
    { code: 'NF', name: 'Norfolk Island' },
    { code: 'KP', name: 'North Korea' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'PW', name: 'Palau' },
    { code: 'PG', name: 'Papua New Guinea' },
    { code: 'He', name: 'Pitcairn Islands' },
    { code: 'RE', name: 'Réunion' },
    { code: 'XS', name: 'Saba' },
    { code: 'BL', name: 'Saint Barthélemy' },
    { code: 'LC', name: 'Saint Lucia' },
    { code: 'MF', name: 'Saint Martin' },
    { code: 'PM', name: 'Saint Pierre and Miquelon' },
    { code: 'VC', name: 'Saint Vincent and the G,renadines' },
    { code: 'WS', name: 'Samoa' },
    { code: 'SM', name: 'San Marino' },
    { code: 'ST', name: 'Sao Tomé and Príncipe' },
    { code: 'SC', name: 'Seychelles' },
    { code: 'SL', name: 'Sierra Leone' },
    { code: 'XE', name: 'Sint Eustatius' },
    { code: 'SX', name: 'Sint Maarten' },
    { code: 'SB', name: 'Solomon Islands' },
    { code: 'SO', name: 'Somalia' },
    { code: 'GS', name: 'South Georgia and South, Sandwich Islands' },
    { code: 'SS', name: 'South Sudan' },
    { code: 'Tr', name: 'St Helena' },
    { code: 'SD', name: 'Sudan' },
    { code: 'SR', name: 'Suriname' },
    { code: 'SJ', name: 'Svalbard' },
    { code: 'SY', name: 'Syria' },
    { code: 'TL', name: 'Timor-Leste' },
    { code: 'TG', name: 'Togo' },
    { code: 'TK', name: 'Tokelau' },
    { code: 'TO', name: 'Tonga' },
    { code: 'TC', name: 'Turks and Caicos Islands' },
    { code: 'TV', name: 'Tuvalu' },
    { code: 'UM', name: 'U.S. Outlying Islands' },
    { code: 'VU', name: 'Vanuatu' },
    { code: 'WF', name: 'Wallis and Futuna' }
  ];
};

const allCountriesSorted = [];
export const getAllCountriesSorted = (): ILocationNames[] => {
  if (allCountriesSorted.length === 0) {
    allCountriesSorted.push(...AllCountriesObj().sort((countryA, countryB) => (countryA.name > countryB.name ? 1 : -1)));
  }
  return allCountriesSorted;
};

export const getCountryByCode = (countryCode: string): ILocationNames => {
  const countryNames = getCountryByCodeInternal(countryCode);
  if (countryNames) {
    return countryNames;
  }
  // Not found use the code as country name.
  else {
    Logger.Warning(LOGGER_PREFIX, `Country code "${countryCode}" is not listed and need to be added.`);
    return { name: countryCode, code: countryCode };
  }
};

const countryCodeToCountryMap = new Map();
function getCountryByCodeInternal(countryCode: string): ILocationNames {
  if (!countryCode) {
    return null;
  }
  //Create domain data map for the first and last time.
  if (countryCodeToCountryMap.size == 0) {
    AllCountriesObj().forEach(countryData => {
      if (!countryCodeToCountryMap.has(countryData.code)) {
        countryCodeToCountryMap.set(countryData.code, countryData);
      }
    });
  }
  return countryCodeToCountryMap.get(countryCode.toUpperCase());
}

export const getCountryByName = (locationName: string): ILocationNames => {
  const countryNames = getCountryByNameInternal(locationName);
  if (countryNames) {
    return countryNames;
  } else {
    return null;
  }
};

// Get all domain data objects from certain type.
// i.e. const products = getDomainData(DominDataType.Product);
const countryNameToCountryMap = new Map();
function getCountryByNameInternal(countryName: string): ILocationNames {
  if (!countryName) {
    return null;
  }
  //Create domain data map for the first and last time.
  if (countryNameToCountryMap.size == 0) {
    AllCountriesObj().forEach(countryData => {
      if (!countryNameToCountryMap.has(countryData.name)) {
        countryNameToCountryMap.set(countryData.name.toLowerCase(), countryData);
      }
    });
  }
  return countryNameToCountryMap.get(countryName.toLowerCase());
}

export const AllUSAStatesObj = (): ILocationNames[] => {
  return [
    {
      code: 'AL',
      name: 'Alabama'
    },
    {
      code: 'AK',
      name: 'Alaska'
    },
    {
      code: 'AZ',
      name: 'Arizona'
    },
    {
      code: 'AR',
      name: 'Arkansas'
    },
    {
      code: 'CA',
      name: 'California'
    },
    {
      code: 'CO',
      name: 'Colorado'
    },
    {
      code: 'CT',
      name: 'Connecticut'
    },
    {
      code: 'DC',
      name: 'District of Columbia'
    },
    {
      code: 'DE',
      name: 'Delaware'
    },
    {
      code: 'FL',
      name: 'Florida'
    },
    {
      code: 'GA',
      name: 'Georgia'
    },
    {
      code: 'HI',
      name: 'Hawaii'
    },
    {
      code: 'IA',
      name: 'Iowa'
    },
    {
      code: 'ID',
      name: 'Idaho'
    },
    {
      code: 'IL',
      name: 'Illinois'
    },
    {
      code: 'IN',
      name: 'Indiana'
    },
    {
      code: 'KS',
      name: 'Kansas'
    },
    {
      code: 'KY',
      name: 'Kentucky'
    },
    {
      code: 'LA',
      name: 'Louisiana'
    },
    {
      code: 'ME',
      name: 'Maine'
    },
    {
      code: 'MD',
      name: 'Maryland'
    },
    {
      code: 'MA',
      name: 'Massachusetts'
    },
    {
      code: 'MI',
      name: 'Michigan'
    },
    {
      code: 'MN',
      name: 'Minnesota'
    },
    {
      code: 'MS',
      name: 'Mississippi'
    },
    {
      code: 'MO',
      name: 'Missouri'
    },
    {
      code: 'MT',
      name: 'Montana'
    },
    {
      code: 'NE',
      name: 'Nebraska'
    },
    {
      code: 'NV',
      name: 'Nevada'
    },
    {
      code: 'NH',
      name: 'New Hampshire'
    },
    {
      code: 'NJ',
      name: 'New Jersey'
    },
    {
      code: 'NM',
      name: 'New Mexico'
    },
    {
      code: 'NY',
      name: 'New York'
    },
    {
      code: 'NC',
      name: 'North Carolina'
    },
    {
      code: 'ND',
      name: 'North Dakota'
    },
    {
      code: 'OH',
      name: 'Ohio'
    },
    {
      code: 'OK',
      name: 'Oklahoma'
    },
    {
      code: 'OR',
      name: 'Oregon'
    },
    {
      code: 'PA',
      name: 'Pennsylvania'
    },
    {
      code: 'RI',
      name: 'Rhode Island'
    },
    {
      code: 'SC',
      name: 'South Carolina'
    },
    {
      code: 'SD',
      name: 'South Dakota'
    },
    {
      code: 'TN',
      name: 'Tennessee'
    },
    {
      code: 'TX',
      name: 'Texas'
    },
    {
      code: 'UT',
      name: 'Utah'
    },
    {
      code: 'VT',
      name: 'Vermont'
    },
    {
      code: 'VA',
      name: 'Virginia'
    },
    {
      code: 'WA',
      name: 'Washington'
    },
    {
      code: 'WV',
      name: 'West Virginia'
    },
    {
      code: 'WI',
      name: 'Wisconsin'
    },
    {
      code: 'WY',
      name: 'Wyoming'
    }
  ];
};

const allUSAStatesSorted = [];
export const getAllUSAStatesSorted = (): ILocationNames[] => {
  if (allUSAStatesSorted.length === 0) {
    allUSAStatesSorted.push(...AllUSAStatesObj().sort((stateA, stateB) => (stateA.name > stateB.name ? 1 : -1)));
  }
  return allUSAStatesSorted;
};

const usaStatesDataMap = new Map();
export function getUSAStatesNames(stateCode: string): ILocationNames {
  //Create domain data map for the first and last time.
  if (usaStatesDataMap.size == 0) {
    AllUSAStatesObj().forEach(state => {
      if (!usaStatesDataMap.has(state.code)) {
        usaStatesDataMap.set(state.code, state);
      }
    });
  }
  return usaStatesDataMap.get(stateCode);
}

export const AllCanadaStatesObj = (): ILocationNames[] => {
  return [
    {
      code: 'AB',
      name: 'Alberta'
    },
    {
      code: 'BC',
      name: 'British Columbia'
    },
    {
      code: 'MB',
      name: 'Manitoba'
    },
    {
      code: 'NB',
      name: 'New Brunswick'
    },
    {
      code: 'NL',
      name: 'Newfoundland and Labrador'
    },
    {
      code: 'NS',
      name: 'Nova Scotia'
    },
    {
      code: 'ON',
      name: 'Ontario'
    },
    {
      code: 'PE',
      name: 'Prince Edward Island'
    },
    {
      code: 'QC',
      name: 'Quebec'
    },
    {
      code: 'SK',
      name: 'Saskatchewan'
    },
    {
      code: 'NT',
      name: 'Northwest Territories'
    },
    {
      code: 'NU',
      name: 'Nunavut'
    },
    {
      code: 'YT',
      name: 'Yukon'
    }
  ];
};
const allCanadaStatesSorted = [];
export const getAllCanadaStatesSorted = (): ILocationNames[] => {
  if (allCanadaStatesSorted.length === 0) {
    allCanadaStatesSorted.push(...AllCanadaStatesObj().sort((stateA, stateB) => (stateA.name > stateB.name ? 1 : -1)));
  }
  return allCanadaStatesSorted;
};

const caStatesDataMap = new Map();
export function getCanadaStatesNames(stateCode: string): ILocationNames {
  //Create domain data map for the first and last time.
  if (caStatesDataMap.size == 0) {
    AllCanadaStatesObj().forEach(state => {
      if (!caStatesDataMap.has(state.code)) {
        caStatesDataMap.set(state.code, state);
      }
    });
  }

  return caStatesDataMap.get(stateCode);
}

export function getLocationDisplay(location: IPartnerLocation, includeCountry: boolean) {
  if (!location) {
    return undefined;
  }
  //Country
  const country = getCountryByCode(location.address.country);
  const countryName = country ? country.name : location.address.country;
  const countryCode = country && country.code ? country.code : location.address.country;

  //State
  let state = undefined;
  if (countryCode && countryCode.toUpperCase() === CANADA_COUNTRY_CODE) {
    state = getCanadaStatesNames(location.address.state);
  } else if (countryCode && countryCode.toUpperCase() === USA_COUNTRY_CODE) {
    state = getUSAStatesNames(location.address.state);
  }
  const stateName = state ? state.name : undefined;
  let result = location.address.city;
  if (stateName) {
    !result ? (result = stateName) : result.endsWith(',') ? (result += ' ' + stateName) : (result += ', ' + stateName);
  }
  if (includeCountry && countryName) {
    !result ? (result = countryName) : result.endsWith(',') ? (result += ' ' + countryName) : (result += ', ' + countryName);
  }
  return result;
}

export function getCountryLocationMap(locations: IPartnerLocation[]) {
  const countryToLocationsMap = new Map<string, IPartnerLocation[]>();
  if (!locations || locations.length == 0) {
    return countryToLocationsMap;
  }
  //Map locations to country
  for (let i = 0; i < locations.length; i++) {
    const country = getCountryByCode(locations[i].address.country);
    const countryCode = country && country.code ? country.code : locations[i].address.country;
    if (!countryToLocationsMap.has(countryCode)) {
      countryToLocationsMap.set(countryCode, []);
    }
    countryToLocationsMap.get(countryCode).push(locations[i]);
  }
  //Sort locations
  countryToLocationsMap.forEach((countryLocations: IPartnerLocation[], countryCode: string) => {
    const sortedLocations = countryLocations.sort((a, b) => (a.address.city > b.address.city ? 1 : -1));
    countryToLocationsMap.set(countryCode, sortedLocations);
  });
  return countryToLocationsMap;
}
const imperialSystemCountryCodes = ['US', 'BU', 'LR'];

export function countryUseImperialSystem(countryCode: string): boolean {
  return countryCode && imperialSystemCountryCodes.includes(countryCode);
}

// Assumed locale format language-country - i.e. en-us
export function localeUseImperialSystem(locale: string): boolean {
  if (locale) {
    const splittedLocale = locale.split('-');
    if (splittedLocale.length == 2) {
      return countryUseImperialSystem(splittedLocale[1].toUpperCase());
    }
  }
  return false;
}

const diversityAndInclusionCountryCodes = ['US', 'CA', 'GB', 'BR', 'AU', 'DE', 'IN', 'JP', 'ES', 'NL', 'FR', 'SG'];

export function isDiversityAndInclusionSupported(countryCode: string): boolean {
  if (!countryCode) return false;
  const diverseMultiGeoEnabled = GetEnvConfiguration().DIVERSE_INCLUSION_MULTIGEO_ENABLED;
  return diverseMultiGeoEnabled
    ? diversityAndInclusionCountryCodes.includes(countryCode.toUpperCase())
    : countryCode.toUpperCase() === USA_COUNTRY_CODE;
}
