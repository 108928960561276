import { appInsights } from '../providers/appInsightProvider';
import { LOGGER } from '../shared/constants';

const FRONTEND_APP = 'FRONTEND_APP:';

export function Info(prefix: string, message: string, suffix?: string) {
  const formarttedMesage = `${FRONTEND_APP} ${LOGGER.INFO}: ${prefix} ${message} ${suffix ?? ''}`;
  appInsights.trackTrace({ message: formarttedMesage });
}

export function Warning(prefix: string, message: string, suffix?: string) {
  const formarttedMesage = `${FRONTEND_APP} ${LOGGER.WARNING}: ${prefix} ${message} ${suffix ?? ''}`;
  appInsights.trackTrace({ message: formarttedMesage });
}

export function Exception(prefix: string, message: string, suffix?: string) {
  const formarttedMesage = `${FRONTEND_APP} ${LOGGER.ERROR}: ${prefix} ${message} ${suffix ?? ''}`;
  appInsights.trackException({ exception: new Error(formarttedMesage) });
  appInsights.trackTrace({ message: formarttedMesage });
}

export function Err(prefix: string, message: string, suffix?: string) {
  const formarttedMesage = `${FRONTEND_APP} ${LOGGER.ERROR}: ${prefix} ${message} ${suffix ?? ''}`;
  appInsights.trackTrace({ message: formarttedMesage });
}
