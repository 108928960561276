import { IContactDetails, IFilterLocation } from './partnersHostInboundMessages';

/*
  Data structure of messages recieved by partner view. 
 */
export enum PartnersViewMessageType {
  SetContext, // Partners view recieve context data from partner host.
  ScrollChange, // Partners view recieve scroll cordinates from partners host.
  SizeChange // Partners view recieve size change from partners host.
}

export interface IPartnersViewMessage {
  messageType: PartnersViewMessageType;
}

export interface IPartnersUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  company: string;
  title: string;
  signedIn: boolean;
  accessToken: string;
}

export interface IViewContext {
  user: IPartnersUserInfo;
  hostUrl: string;
  partnersUrl: string;
  filterLocation: IFilterLocation;
  contactDetails: IContactDetails;
}

export interface ISetContextMessage extends IPartnersViewMessage {
  context: IViewContext;
}

export interface IScrollChangeMessage extends IPartnersViewMessage {
  xOffset: number;
  yOffset: number;
}

export interface ISizeChangeMessage extends IPartnersViewMessage {
  width: number;
  height: number;
  frameLeft: number;
  frameTop: number;
}
