import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import { runningInIframe } from '../../utils/iframeUtils';
import { IChangeUrlDetailsMessage, PartnersHostMessageType } from '../../protocol/partnersHostInboundMessages';
import { ADDITIONAL_INFO_TAB, OVERVIEW_TAB } from '../partnerDetails/partnerDetails';
import { ANALYTICS_USER_ACTIONS, trackUserAction } from '../../services/analyticsService';

export interface TabChild {
  title: string;
  name: string;
  getContent: () => JSX.Element;
  href: string;
  id: string;
}

export interface ITabProps {
  title: string;
  name: string;
  otherProps?: any;
  href: string;
  id: string;
  children: any;
}

export function Tab(props: ITabProps) {
  return props.children;
}

export interface ITabsProps {
  defaultTab: string;
  theme?: TabsTheme;
  children: any;
}

export enum TabsTheme {
  'gray'
}

export default function Tabs(props: ITabsProps) {
  const history = useHistory();

  function getCurrentTabIndex() {
    const tabsList = getNonNullChildren();
    const length = tabsList.length;

    for (let index = 0; index < length; index++) {
      const tab = tabsList[index].valueOf() as any;

      if (tab.props.name === props.defaultTab) {
        return index;
      }
    }

    return 0;
  }

  const onTabClick = (e: any, to: string, index: number, tabName: string, id: string) => {
    if (index === getCurrentTabIndex()) {
      return;
    }
    const userActionName =
      index === 0 ? ANALYTICS_USER_ACTIONS.DETAILS_OVERVIEW_TAB_CLICK : ANALYTICS_USER_ACTIONS.DETAILS_ADDITIONAL_TAB_CLICK;
    trackUserAction({
      name: userActionName,
      properties: { partnerId: id }
    });
    if (runningInIframe()) {
      const outboundMessagesHandler = new OutboundMessagesHandler();
      const changeUrlMessage: IChangeUrlDetailsMessage = {
        messageType: PartnersHostMessageType.ChangeUrlDetails,
        partnerId: id,
        tab: tabName,
        contact: false,
        replaceUrl: false
      };
      outboundMessagesHandler.postChangeUrlDetailsMessage(changeUrlMessage);
    } else {
      history.push(to);
    }
    e.preventDefault();
  };

  const getHeaderElement = (child: any, index: number, currentTabIndex: number) => {
    const className = index === currentTabIndex ? 'default-tab tab-selected' : 'default-tab';
    if (child.props.children) {
      return (
        <Link
          className={className}
          role="tab"
          aria-selected={index === currentTabIndex}
          title={child.props.title}
          key={'partnerDetailsTab ' + index}
          onClick={e => onTabClick(e, child.props.href, index, index === 0 ? OVERVIEW_TAB : ADDITIONAL_INFO_TAB, child.props.id)}
          replace={runningInIframe ? true : false}
          to={child.props.href}
        >
          <label>
            <Trans i18nKey={child.props.title}>{child.props.title}</Trans>
          </label>
        </Link>
      );
    } else {
      return null;
    }
  };

  const getNonNullChildren = (): React.ReactNode[] => {
    return React.Children.toArray(props.children);
  };

  // Returns the number of non-null children.
  const getNonNullChildrenCount = (): number => {
    return getNonNullChildren().length;
  };

  const shouldRenderHeader = (): boolean => {
    return getNonNullChildrenCount() > 1;
  };

  const renderHeaders = (currentTabIndex: number) => {
    // we do not render a header if there's only one tab
    if (!shouldRenderHeader()) {
      return null;
    } else {
      const children = getNonNullChildren();
      const elements = children.map((child, index) => getHeaderElement(child, index, currentTabIndex));
      return (
        <div className="tab-headers" role="tablist">
          {elements}
        </div>
      );
    }
  };

  const currentTabIndex = getCurrentTabIndex();
  const tabContainerClass = 'tab-container ' + (props.theme === TabsTheme['gray'] ? 'gray' : '');
  return (
    <div className={tabContainerClass}>
      {renderHeaders(currentTabIndex)}
      <div className="tab-content" role="tabpanel">
        {getNonNullChildren()[currentTabIndex]}
      </div>
    </div>
  );
}
