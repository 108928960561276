import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IViewContext } from '../protocol/partnersViewInboundMessages';
import { getDefaultDistanceOption } from '../utils/distanceUtils';

export interface IUserLocation {
  coordExist(): boolean;
  name: string;
  latitude: number;
  longitude: number;
  countryCode: string;
}

export interface IFilterLocation {
  location: IUserLocation;
  filterRadiusMiles: number;
  filterThisLocationOnly: boolean;
}

export class UserLocation implements IUserLocation {
  constructor(name: string, latitude: number, longitude: number, countryCode: string) {
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
    this.countryCode = countryCode;
  }
  name = '';
  latitude = undefined;
  longitude = undefined;
  countryCode = '';

  coordExist(): boolean {
    return this.latitude && this.longitude;
  }
}

export class FilterLocation implements IFilterLocation {
  constructor(location: IUserLocation, filterRadiusMiles: number, filterThisLocationOnly: boolean) {
    this.location = location;
    this.filterRadiusMiles = filterRadiusMiles;
    this.filterThisLocationOnly = filterThisLocationOnly;
  }
  location = new UserLocation('', undefined, undefined, '');
  filterRadiusMiles = getDefaultDistanceOption().distanceMiles;
  filterThisLocationOnly = true;
}

export interface ILocationStore {
  userLocation: IUserLocation;
  filterLocation: IFilterLocation;
}

const initialState: ILocationStore = {
  userLocation: new UserLocation('', undefined, undefined, ''),
  filterLocation: new FilterLocation(new UserLocation('', undefined, undefined, ''), 100, true)
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setUserLocation: (state, { payload }: PayloadAction<{ userLocation: IUserLocation }>) => {
      state.userLocation = payload.userLocation;
    },
    setFilterLocation: (state, { payload }: PayloadAction<{ filterLocation: IFilterLocation }>) => {
      state.filterLocation = payload.filterLocation;
    }
  }
});

export const { setUserLocation, setFilterLocation: setFilterLocation } = locationSlice.actions;
export const getUserLocation = (locationStore: ILocationStore) => locationStore.userLocation;
export const getFilterLocation = (locationStore: ILocationStore) => locationStore.filterLocation;
export default locationSlice.reducer;
