const URL_PATTERN = /((http|ftp|https):\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

const EMAIL_PATTERN = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$');

const GOV_EMAIL_SUFFIX = '.gov';
const MIL_EMAIL_SUFFIX = '.mil';

export const containsURL = (input: string): boolean => {
  if (input && input.trim().length > 0) {
    return URL_PATTERN.test(input.trim());
  }
  return false;
};

export const isValidEmail = (input: string): boolean => {
  if (input && input.trim().length > 0) {
    const inputParts: string[] = input.split(' ');
    if (inputParts.length != 1) {
      return false;
    }
    const exist = EMAIL_PATTERN.test(inputParts[0]);
    return exist;
  }
  return false;
};

export const isSensitiveEmail = (input: string): boolean => {
  return input && (input.toLowerCase().endsWith(GOV_EMAIL_SUFFIX) || input.toLowerCase().endsWith(MIL_EMAIL_SUFFIX));
};
