import { appInsights } from '../providers/appInsightProvider';
import { runningInIframe } from '../utils/iframeUtils';
import { getUserLoggedIn } from '../utils/stateUtil';

// Incoming requests - called from frontend to proxy.
export const ANALYTICS_USER_ACTIONS = {
  // Home page
  HOME_USE_MY_LOCATION_CLICK: 'ANALYTICS.USER.ACTION.HOME_USE_MY_LOCATION_CLICK',
  HOME_SET_LOCATION_CLICK: 'ANALYTICS.USER.ACTION.HOME_SET_LOCATION_CLICK',
  HOME_SET_DISTANCE_RADUIUS: 'ANALYTICS.USER.ACTION.HOME_SET_DISTANCE_RADUIUS',
  HOME_PARTNERS_FROM_THIS_LOCATION: 'ANALYTICS.USER.ACTION.HOME_PARTNERS_FROM_THIS_LOCATION',

  // Gallery page
  GALLERY_FILTER_EDIT_LOCATION_CLICK: 'ANALYTICS.USER.ACTION.GALLERY_EDIT_LOCATION_CLICK',
  GALLERY_EMPTY_EDIT_LOCATION_CLICK: 'ANALYTICS.USER.ACTION.GALLERY_EMPTY_EDIT_LOCATION_CLICK',
  GALLERY_USE_MY_LOCATION_CLICK: 'ANALYTICS.USER.ACTION.GALLERY_USE_MY_LOCATION_CLICK',
  GALLERY_SET_LOCATION_CLICK: 'ANALYTICS.USER.ACTION.GALLERY_SET_LOCATION_CLICK',
  GALLERY_FILTER: 'ANALYTICS.USER.ACTION.GALLERY_FILTER',
  GALLERY_FILTER_REMOVE_TAG: 'ANALYTICS.USER.ACTION.GALLERY_FILTER_REMOVE_TAG',
  GALLERY_FILTER_CLEAR_ALL: 'ANALYTICS.USER.ACTION.GALLERY_FILTER_CLEAR_ALL',
  GALLERY_SORT: 'ANALYTICS.USER.ACTION.GALLERY_SORT',
  GALLERY_CHANGE_PAGE: 'ANALYTICS.USER.ACTION.GALLERY_CHANGE_PAGE',
  GALLERY_TILE_CLICK: 'ANALYTICS.USER.ACTION.GALLERY_TILE_CLICK',
  GALLERY_CONTACT_CLICK: 'ANALYTICS.USER.ACTION.GALLERY_CONTACT_CLICK',
  GALLERY_EMPTY_CLEAR_ALL: 'ANALYTICS.USER.ACTION.GALLERY_EMPTY_CLEAR_ALL',

  // Details page
  DETAILS_CHANGE_PARTNER_LOCATION: 'ANALYTICS.USER.ACTION.DETAILS_PARTNER_LOCATION_CHANGED',
  DETAILS_OVERVIEW_TAB_CLICK: 'ANALYTICS.USER.ACTION.DETAILS_OVERVIEW_TAB_CLICK',
  DETAILS_ADDITIONAL_TAB_CLICK: 'ANALYTICS.USER.ACTION.DETAILS_ADDITIONAL_TAB_CLICK',
  DETAILS_CONTACT_CLICK: 'ANALYTICS.USER.ACTION.DETAILS_CONTACT_CLICK',
  DETAILS_PARTNER_LINK_CLICK: 'ANALYTICS.USER.ACTION.DETAILS_PARTNER_LINK_CLICK',
  DETAILS_LOCATION_TREE_CLICK: 'ANALYTICS.USER.ACTION.DETAILS_LOCATION_TREE_CLICK',
  DETAILS_GALLERY_BREADCRUMB_CLICK: 'ANALYTICS.USER.ACTION.DETAILS_GALLERY_BREADCRUMB_CLICK',
  DETAILS_PARTNER_LINKEDIN_LINK_CLICK: 'ANALYTICS.USER.ACTION.DETAILS_LINKEDIN_LINK_CLICK',

  // Contact page
  CONTACT_CHANGE_PARTNER_LOCATION: 'ANALYTICS.USER.ACTION.CONTACT_CHANGE_PARTNER_LOCATION',
  CONTACT_SUBMIT_REQUEST_CLICK: 'ANALYTICS.USER.ACTION.CONTACT_SUBMIT_REQUEST_CLICK',
  CONTACT_SUBMIT_REQUEST_START: 'ANALYTICS.USER.ACTION.CONTACT_SUBMIT_REQUEST_START',
  CONTACT_SUBMIT_REQUEST_SUCCESS: 'ANALYTICS.USER.ACTION.CONTACT_SUBMIT_REQUEST_SUCCESS',
  CONTACT_SUBMIT_REQUEST_FAILED: 'ANALYTICS.USER.ACTION.CONTACT_SUBMIT_REQUEST_FAILED',

  // Location
  LOCATION_USER_ALLOW_ACCESS: 'ANALYTICS.USER.ACTION.LOCATION_USER_ALLOW_ACCESS',
  LOCATION_USER_DENY_ACCESS: 'ANALYTICS.USER.ACTION.LOCATION_USER_DENY_ACCESS'
};

export const ANALYTICS_PARTNER_CENTER_ACTIONS = {
  // Gallery tile click.
  SEARCH_PAGE_PARTNER_DETAILS_LINK: 'SearchPagePartnerDetailsLink',
  // Contact click from partnetrs gallery.
  SEARCH_PAGE_CONTACT_PARTNER_BUTTON_CLICKED: 'SearchPageContactPartnerButtonClicked',
  // Details page open from deep link.
  PARTNER_DETAILS_PAGE_DEEP_LINK: 'PartnerDetailsPageDeepLink',
  // Contact click from partner details.
  PARTNER_DETAILS_PAGE_CONTACT_BUTTON: 'PartnerDetailsPageContactButton',
  // Submit contact partner request click.
  REQUEST_FOR_INFORMATION_PAGE_SUBMIT: 'RequestForInformationPageSubmit'
};

const ANALYTICS_USER_ACTIONS_PROPS_NAMES = {
  IFRAME: 'iframe',
  USER_LOGGED_IN: 'userLoggedIn'
};

export interface UserActionProps {
  [key: string]: any;
}

export interface UserAction {
  name: string;
  properties?: UserActionProps;
}

export const PARTNER_CENTER_ACTIONS_PROPS_NAMES = {
  EVENT_NAME: 'EventName',
  PARTNER_LISTING_ID: 'locationId',
  PARTNER_ID: 'partnerId',
  PARTNER_NAME: 'partnerName'
};
export interface PartnerCenterActionProps {
  [key: string]: any;
}

export interface PartnerCenterAction {
  name: string;
  properties?: PartnerCenterActionProps;
}

const isIframe = runningInIframe();

export const trackUserAction = (userAction: UserAction) => {
  // Try/catch because telemetry shouldn't fail the app.
  try {
    if (!userAction.properties) {
      userAction.properties = {};
    }
    userAction.properties[ANALYTICS_USER_ACTIONS_PROPS_NAMES.IFRAME] = isIframe;
    userAction.properties[ANALYTICS_USER_ACTIONS_PROPS_NAMES.USER_LOGGED_IN] = getUserLoggedIn();
    appInsights.trackEvent({ name: userAction.name }, userAction.properties);
  } catch (err) {
    const errorMessage = `Unexpected error in trackUserAction. error: ${err}`;
    console.log(errorMessage);
  }
};

export const trackPartnerCenterAction = (partnerCenterAction: PartnerCenterAction) => {
  // Try/catch because telemetry shouldn't fail the app.
  try {
    if (!partnerCenterAction.properties) {
      partnerCenterAction.properties = {};
    }
    appInsights.trackEvent({ name: partnerCenterAction.name }, partnerCenterAction.properties);
  } catch (err) {
    const errorMessage = `Unexpected error in trackPartnerCenterAction. error: ${err}`;
    console.log(errorMessage);
  }
};
