import React from 'react';
import { IPartnerDetails } from '../../shared/models/partnerDetailsModel';
import { Trans, useTranslation } from 'react-i18next';
import {
  SERVICES,
  CATEGORIES,
  PRODUCTS,
  INDUSTRIES,
  ADVANCED_SPECIALIZATION,
  AZURE_EXPERT_MSP,
  PROVIDER_COMPETENCY_AREA,
  PROVIDER_COMPETENCY_AREA_WARNING,
  AZURE_EXPERT_MSPS_EXPLANATION,
  ADVANCED_SPECIALIZATION_EXPLANATION,
  SPECIALIZATION_EXPLANATION,
  SPECIALIZATION,
  DIVERSE_OWNED_TITLE,
  DIVERSE_ACCREDIATION_TITLE,
  LEARN_MORE,
  DESIGNATIONS,
  DESIGNATIONS_EXPLANATION,
  ADV_SPEC_PARENT_AZURE,
  ADV_SPEC_PARENT_MODERN_WORK,
  ADV_SPEC_PARENT_BUSINESS_APPS,
  ADV_SPEC_PARENT_SECURITY,
  TRAINING_DESIGNATIONS_EXPLANATION,
  TRAINING_DESIGNATIONS
} from '../../constants/localization';
import {
  AdvSpecParent,
  DiverseThirdPartyAccreditationKey,
  getAdvSpecDomainDataObject,
  getLocalizationKey,
  IDomainData
} from '../../shared/domainDataObj';
import { Link } from '@fluentui/react';
import { GetEnvConfiguration } from '../../services/configurationService';
import { getFeatureFlags, IFeatureFlags } from '../../redux/configSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export interface IPartnerDetailsOverview {
  partner: IPartnerDetails;
}

const DIVERSE_OWN_KEY = 'diverse_own';
const DIVERSE_ACCRED_KEY = 'diverse_accred';

export default function PartnerDetailsOverview(props: IPartnerDetailsOverview) {
  const { t: translate } = useTranslation();
  const partner: IPartnerDetails = props.partner;
  const featureFlags: IFeatureFlags = useSelector((store: RootState) => getFeatureFlags(store.config));

  function renderDescription() {
    return (
      <div className="appdetail-content">
        <div className="partner-description">{partner.description}</div>
      </div>
    );
  }

  const renderAzureExpertMSP = () => {
    if (partner.programQualificationsMsp && partner.programQualificationsMsp.length > 0) {
      return (
        <div>
          <h5 className="title-section">
            <div className={'icon-partner-azure-msp'}></div>
            <div className="title-text">
              <Trans i18nKey={AZURE_EXPERT_MSP}>This provider is an Azure Expert MSP</Trans>
            </div>
          </h5>
          <p className="title-explanation">
            <Trans i18nKey={AZURE_EXPERT_MSPS_EXPLANATION} />
          </p>
        </div>
      );
    }
    return null;
  };

  const splitArray = (array: string[], resultArraysCount: number): string[][] => {
    if (resultArraysCount <= 0) throw Error('resultArraysCount should be greater than 0');
    const resultSubArrays: string[][] = [];
    let colSize = Math.floor(array.length / resultArraysCount);
    if (array.length % resultArraysCount > 0) {
      colSize += 1;
    }
    //Init sub arrays
    for (let subArrayIndex = 0; subArrayIndex < resultArraysCount; subArrayIndex++) {
      resultSubArrays[subArrayIndex] = [];
    }
    // Asign items to the right array.
    for (let i = 0; i < array.length; i++) {
      const subArrayIndex = Math.floor(i / colSize);
      resultSubArrays[subArrayIndex].push(array[i]);
    }
    return resultSubArrays;
  };

  const renderAdvancedSpecializationByDesignations = () => {
    if (partner.programQualificationsAsp && partner.programQualificationsAsp.length > 0) {
      const aspDomainDataArr = partner.programQualificationsAsp.map(getAdvSpecDomainDataObject);
      const azureAspKeys = getAdvSpecKeys(AdvSpecParent.Azure, aspDomainDataArr);
      const modernWorkKeys = getAdvSpecKeys(AdvSpecParent.ModernWork, aspDomainDataArr);
      const securityAspKeys = getAdvSpecKeys(AdvSpecParent.Security, aspDomainDataArr);
      const bizAppsAspArr = getAdvSpecKeys(AdvSpecParent.BusinessApplications, aspDomainDataArr);
      return (
        <div>
          <h5 className="title-section">
            <div className={'icon-partner-asp title-icon'}></div>
            <div className="title-text">
              <Trans i18nKey={SPECIALIZATION}>Advanced Specialization</Trans>
            </div>
          </h5>
          <p className="title-explanation">
            <Trans i18nKey={SPECIALIZATION_EXPLANATION} />
          </p>
          <div className="adv-spec-box">
            <div className="four-columns-box">
              {renderAdvSpecDesignationsColumn('Azure', ADV_SPEC_PARENT_AZURE, azureAspKeys)}
              {renderAdvSpecDesignationsColumn('Modern Work', ADV_SPEC_PARENT_MODERN_WORK, modernWorkKeys)}
              {renderAdvSpecDesignationsColumn('Security', ADV_SPEC_PARENT_SECURITY, securityAspKeys)}
              {renderAdvSpecDesignationsColumn('Business Apps', ADV_SPEC_PARENT_BUSINESS_APPS, bizAppsAspArr)}
            </div>
          </div>
        </div>
      );
    }
  };

  const getAdvSpecKeys = (advSpecParentKey: string, specializationsDomainData: IDomainData[]): string[] => {
    return specializationsDomainData
      .filter(aspDomainData => aspDomainData?.parent === advSpecParentKey)
      .map(aspDomainData => {
        return aspDomainData.newKey;
      });
  };

  const renderAdvSpecDesignationsColumn = (columnId: string, columnTitleTranslationKey: string, advSpecItems: string[]) => {
    if (advSpecItems?.length > 0) {
      return (
        <div className="col" key={'azure asp column ' + columnId}>
          <h6 className="competency-sub-title">
            <Trans i18nKey={columnTitleTranslationKey}>Designation</Trans>
          </h6>
          <ul className="description-ul">
            {localizeAndSortAdvSpec(advSpecItems).map((asp, index) => (
              <li className="description-li" key={'adv spec desc' + index}>
                {asp}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderAdvancedSpecialization = () => {
    if (partner.programQualificationsAsp && partner.programQualificationsAsp.length > 0) {
      const locSortedArray = localizeAndSort(partner.programQualificationsAsp);
      const aspArrays: string[][] = splitArray(locSortedArray, 3);
      return (
        <div>
          <h5 className="title-section">
            <div className={'icon-partner-asp title-icon'}></div>
            <div className="title-text">
              <Trans i18nKey={ADVANCED_SPECIALIZATION}>Advanced Specialization</Trans>
            </div>
          </h5>
          <p className="title-explanation">
            <Trans i18nKey={ADVANCED_SPECIALIZATION_EXPLANATION} />
          </p>
          <div className="adv-spec-box">
            <div className="four-columns-box">
              {aspArrays.map((aspArr, index) => (
                <div className="col" key={'asp column' + index}>
                  <ul className="description-ul">
                    {aspArr.map((asp, index) => (
                      <li className="description-li" key={'asp desc' + index}>
                        {asp}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderDesignations = () => {
    if (partner.solutionsPartnerDesignations?.length > 0) {
      const locSortedArray = localizeAndSort(partner.solutionsPartnerDesignations);
      const designationsArrays: string[][] = splitArray(locSortedArray, 3);
      return (
        <div>
          <h5 className="title-section">
            <div className={'icon-partner-expertise title-icon'}></div>
            <div className="title-text">
              <Trans i18nKey={DESIGNATIONS}>Partner Designations</Trans>
            </div>
          </h5>
          <p className="title-explanation">
            <Trans i18nKey={DESIGNATIONS_EXPLANATION} />
          </p>
          <div className="adv-spec-box">
            <div className="four-columns-box">
              {designationsArrays.map((designationArr, index) => (
                <div className="col" key={'asp column' + index}>
                  <ul className="description-ul">
                    {designationArr.map((designation, index) => (
                      <li className="description-li" key={'designation desc' + index}>
                        {designation}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderTrainingDesignations = () => {
    if (partner.trainingServicesPartnerDesignations?.length > 0) {
      const locSortedArray = localizeAndSort(partner.trainingServicesPartnerDesignations);
      const designationsArrays: string[][] = splitArray(locSortedArray, 3);
      return (
        <div>
          <h5 className="title-section">
            <div className={'icon-partner-expertise title-icon'}></div>
            <div className="title-text">
              <Trans i18nKey={TRAINING_DESIGNATIONS}>Training Partner Designations</Trans>
            </div>
          </h5>
          <p className="title-explanation">
            <Trans i18nKey={TRAINING_DESIGNATIONS_EXPLANATION} />
          </p>
          <div className="adv-spec-box">
            <div className="four-columns-box">
              {designationsArrays.map((designationArr, index) => (
                <div className="col" key={'asp column' + index}>
                  <ul className="description-ul">
                    {designationArr.map((designation, index) => (
                      <li className="description-li" key={'designation desc' + index}>
                        {designation}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const localizeAndSort = (items: string[]) => {
    const localizedItems: string[] = items.map(item => {
      const locKey = getLocalizationKey(item);
      return translate(locKey ? locKey : item);
    });
    return localizedItems.sort();
  };

  const localizeAndSortAdvSpec = (items: string[]) => {
    const localizedItems: string[] = items.map(item => {
      const advSpecItem = getAdvSpecDomainDataObject(item);
      return translate(advSpecItem.localizationKey ? advSpecItem.localizationKey : item);
    });
    return localizedItems.sort();
  };

  const renderExperties = () => {
    if (partner.product || partner.serviceType || partner.solutions || partner.industryFocus) {
      return (
        <div>
          <h5 className="title-section">
            <div className={'icon-partner-expertise title-icon'}></div>
            <div className="title-text">
              <Trans i18nKey={PROVIDER_COMPETENCY_AREA}>Services Partners Experties</Trans>
            </div>
          </h5>
          <div className="legal-warning">
            <Trans i18nKey={PROVIDER_COMPETENCY_AREA_WARNING}>Services Partners Experties</Trans>
          </div>
          <div className="four-columns-box" key="expertiesBox">
            {partner.serviceType && partner.serviceType.length > 0 ? (
              <div className="col" key={'services column'}>
                <h6 className="competency-sub-title">
                  <Trans i18nKey={SERVICES}>Services</Trans>{' '}
                </h6>
                <ul className="description-ul">
                  {localizeAndSort(partner.serviceType).map((service, index) => (
                    <li className="description-li" key={'service desc' + index}>
                      {service}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {partner.solutions && partner.solutions.length > 0 ? (
              <div className="col" key={'category column'}>
                <h6 className="competency-sub-title">
                  <Trans i18nKey={CATEGORIES}>Categories</Trans>
                </h6>
                <ul className="description-ul">
                  {localizeAndSort(partner.solutions).map((category, index) => (
                    <li className="description-li" key={'solution desc' + index}>
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {partner.industryFocus && partner.industryFocus.length > 0 ? (
              <div className="col" key={'industrey column'}>
                <h6 className="competency-sub-title">
                  {' '}
                  <Trans i18nKey={INDUSTRIES}>Industries</Trans>{' '}
                </h6>
                <ul className="description-ul">
                  {localizeAndSort(partner.industryFocus).map((industrey, index) => (
                    <li className="description-li" key={'industrey desc' + index}>
                      {industrey}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {partner.product && partner.product.length > 0 ? (
              <div className="col" key={'product column'}>
                <h6 className="competency-sub-title">
                  {' '}
                  <Trans i18nKey={PRODUCTS}>Products</Trans>{' '}
                </h6>
                <ul className="description-ul">
                  {localizeAndSort(partner.product).map((product, index) => (
                    <li className="description-li" key={'product desc' + index}>
                      {product}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderDiversitySection = (diversities: string[], title: string, itemKey: string) => {
    if (diversities && diversities.length > 0) {
      const locSortedArray = localizeAndSort(diversities);
      const diverseArrays: string[][] = splitArray(locSortedArray, 2);
      return (
        <div>
          <h5 className="title-section">
            <div className="title-text-no-icon">
              <Trans i18nKey={title}>This business attests to being the following:</Trans>
            </div>
            <Link
              className="learn-more-link"
              href="https://docs.microsoft.com/marketplace/diversity"
              target="_blank"
              rel="noreferrer"
              underline
            >
              <Trans i18nKey={LEARN_MORE}>Learn more</Trans>
            </Link>
          </h5>
          <div className="diverse-business-box">
            <div className="two-columns-box">
              {diverseArrays.map((diverseArrays, index) => (
                <div className="col" key={'asp column' + index}>
                  <ul className="description-ul">
                    {diverseArrays.map((diversity, index) => (
                      <li className="description-li-wide" key={`${itemKey}_desc_${index}`}>
                        {diversity}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  if (partner) {
    const envConfig = GetEnvConfiguration();
    const trainingDesignationsEnabled = envConfig.TRAINING_DESIGNATIONS_ENABLED || featureFlags?.trainingDesignationsEnabled;
    // Temporarly (until backend solved this) filter out this type of accridation.
    const filteredThirdPartyAccreditation = partner.daiThirdPartyAccreditation
      ? partner.daiThirdPartyAccreditation.filter(accred => accred !== DiverseThirdPartyAccreditationKey.OtherCertAgency)
      : [];
    return (
      <div className="overview-box">
        {renderDescription()}
        {renderAzureExpertMSP()}
        {renderAdvancedSpecializationByDesignations()}
        {renderDesignations()}
        {trainingDesignationsEnabled && renderTrainingDesignations()}
        {renderExperties()}
        {envConfig.DIVERSE_INCLUSION_ENABLED && renderDiversitySection(partner.daiOwnedLed, DIVERSE_OWNED_TITLE, DIVERSE_OWN_KEY)}
        {envConfig.DIVERSE_INCLUSION_ENABLED &&
          renderDiversitySection(filteredThirdPartyAccreditation, DIVERSE_ACCREDIATION_TITLE, DIVERSE_ACCRED_KEY)}
      </div>
    );
  } else {
    return null;
  }
}
