import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../shared/models/commonModels';
import { IPartner, IPartnerList, IPartnerStore } from '../shared/models/partnerModel';
import { PartnersFilter } from '../shared/models/Request/partnersFilter';

const MAX_PARTNERS_CACHE_SIZE = 10;

const initialState: IPartnerStore = {
  data: {
    estimatedTotalMatchingPartners: 0,
    matchingPartners: {
      items: []
    }
  },
  error: '',
  status: Status.Uninitialized,
  requestFilter: null,
  responseFilter: null,
  partnersCache: []
};

export interface IPartnerRequestPayload {
  id: number;
}

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    fetchPartnersAsync: (state, { payload }: PayloadAction<{ filter: PartnersFilter }>) => {
      state.data = undefined;
      state.status = Status.InProgress;
      state.requestFilter = payload.filter;
      return state;
    },
    setPartners: (state, { payload }: PayloadAction<IPartnerList>) => {
      state.data = payload;
      return state;
    },
    onSuccess: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.responseFilter = state.requestFilter;
      state.status = Status.Done;
    },
    onError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.status = Status.Failed;
    },
    addPartnerToCache: (state, { payload }: PayloadAction<IPartner>) => {
      // Cache too big - remove the oldest item in the cache.
      if (state.partnersCache.length > MAX_PARTNERS_CACHE_SIZE) {
        state.partnersCache.splice(0, 1);
      }
      state.partnersCache.push(payload);
    }
  }
});

export const { fetchPartnersAsync, onSuccess, onError, setPartners, addPartnerToCache } = partnerSlice.actions;
const getPartnersList = (partnersStore: IPartnerStore) =>
  (partnersStore.data && partnersStore.data.matchingPartners && partnersStore.data.matchingPartners.items) || [];
const getEstimatedTotalMatchingPartners = (partnersStore: IPartnerStore) =>
  (partnersStore.data && partnersStore.data.estimatedTotalMatchingPartners) || 0;
const fetchPartnersStatus = (partnersStore: IPartnerStore): Status => partnersStore.status;

export const getPartners = createSelector([getPartnersList], getPartnersList => getPartnersList);
export const getEstimatedTotalMatchPartners = createSelector(
  [getEstimatedTotalMatchingPartners],
  getEstimatedTotalMatchingPartners => getEstimatedTotalMatchingPartners
);
export const getFetchPartnersStatus = createSelector([fetchPartnersStatus], fetchPartnersStatus => fetchPartnersStatus);

export const getPartnerById = (partnersStore: IPartnerStore, id: string): IPartner => {
  // Look in partner filter results.
  if (
    partnersStore.data &&
    partnersStore.data.matchingPartners &&
    partnersStore.data.matchingPartners.items &&
    partnersStore.data.matchingPartners.items.length > 0
  ) {
    const partners = partnersStore.data.matchingPartners.items;
    for (let i = 0; i < partners.length; i++) {
      if (partners[i].partnerId === id) {
        return partners[i];
      }
    }
  }
  // Look in partners cache.
  if (partnersStore.partnersCache) {
    for (let i = 0; i < partnersStore.partnersCache.length; i++) {
      if (partnersStore.partnersCache[i].partnerId === id) {
        return partnersStore.partnersCache[i];
      }
    }
  }
  return null;
};

export const getResponseFilter = (partnersStore: IPartnerStore) => partnersStore.data && partnersStore.responseFilter;

export default partnerSlice.reducer;
