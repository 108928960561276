import {
  AdvSpecKey,
  CategoryKey,
  CompanySizeKey,
  DesignationsKey,
  IndustryKey,
  ProductKey,
  ServiceKey,
  TrainingDesignationsKey
} from '../../domainDataObj';
import { AutoCompleteSuggestionType } from '../Response/searchModels';
import * as Logger from '../../../services/loggerService';
import * as DOMPurify from 'dompurify';
export const LOCATION_NAME_PARAM = '';
export const LOCATION_LATITUDE_PARAM = '';
export const LOCATION_LONGTITUDE_PARAM = '';
export const RESULTS_PAGE_SIZE = 18;
const LOGGER_PREFIX = 'Partners Filter:';

const TRUE_STR = 'true';
const QUERY_PARAMS = {
  lat: 'lat',
  lng: 'lng',
  locname: 'locname',
  country: 'country',
  radius: 'radius',
  orgSize: 'orgSize',
  products: 'products',
  services: 'services',
  categories: 'categories',
  industries: 'industries',
  asp: 'asp',
  azuremsp: 'azuremsp',
  sort: 'sort',
  pageSize: 'pageSize',
  pageOffset: 'pageOffset',
  userLocation: 'userLocation',
  onlyThisCountry: 'onlyThisCountry',
  suggestion: 'suggestion',
  locationNotRequired: 'locationNotRequired',
  freeText: 'freetext',
  diverseOwn: 'diverseOwn',
  diverseAccred: 'diverseAccred',
  designations: 'designations',
  trainingDesignations: 'trainingDesignations',
  endorsedProduct: 'endorsedProduct',
  endorsedWorkloads: 'endorsedWorkloads'
};

export enum PartnersResultsSort {
  BestMatch,
  MostResponsive,
  NearestToYou
}

export interface FilterValidationResult {
  isValid: boolean;
  validationError?: string;
}

export interface SearchSuggestion {
  filterKey: string;
  suggestType: AutoCompleteSuggestionType;
  filterQueryParam: string;
}

export class PartnersFilter {
  countryCode: string = undefined;
  locationLatitude: number = undefined;
  locationLongtitude: number = undefined;
  locationName: string = undefined;
  orgSize: string = undefined;
  advSpecialization: string[] = undefined;
  azureMSP = false;
  products: string[] = undefined;
  industries: string[] = undefined;
  services: string[] = undefined;
  categories: string[] = undefined;
  resultSortBy: PartnersResultsSort = PartnersResultsSort.BestMatch;
  resultPageSize = RESULTS_PAGE_SIZE;
  resultPageOffset = 0;
  radiusMiles = 100;
  onlyThisCountryResults = true;
  userLocation = false;
  freeText: string = undefined;
  locationNotRequiredMode: boolean = undefined;
  searchSuggestionMode: boolean = undefined;
  diverseOwned = false;
  diverseAccrediation = false;
  designations: string[] = undefined;
  trainingDesignations: string[] = undefined;
  endorsedProduct: string = undefined;
  endorsedWorkloads: string[] = undefined;

  static cloneFilter(filter: PartnersFilter): PartnersFilter {
    const cloneFilter = new PartnersFilter();
    cloneFilter.countryCode = filter.countryCode;
    cloneFilter.locationLatitude = filter.locationLatitude;
    cloneFilter.locationLongtitude = filter.locationLongtitude;
    cloneFilter.locationName = filter.locationName;
    cloneFilter.orgSize = filter.orgSize;
    cloneFilter.advSpecialization = filter.advSpecialization;
    cloneFilter.azureMSP = filter.azureMSP;
    cloneFilter.products = filter.products;
    cloneFilter.industries = filter.industries;
    cloneFilter.services = filter.services;
    cloneFilter.categories = filter.categories;
    cloneFilter.resultSortBy = filter.resultSortBy;
    cloneFilter.resultPageSize = filter.resultPageSize;
    cloneFilter.resultPageOffset = filter.resultPageOffset;
    cloneFilter.radiusMiles = filter.radiusMiles;
    cloneFilter.userLocation = filter.userLocation;
    cloneFilter.onlyThisCountryResults = filter.onlyThisCountryResults;
    cloneFilter.freeText = filter.freeText;
    cloneFilter.locationNotRequiredMode = filter.locationNotRequiredMode;
    cloneFilter.searchSuggestionMode = filter.searchSuggestionMode;
    cloneFilter.diverseOwned = filter.diverseOwned;
    cloneFilter.diverseAccrediation = filter.diverseAccrediation;
    cloneFilter.designations = filter.designations;
    cloneFilter.trainingDesignations = filter.trainingDesignations;
    cloneFilter.endorsedProduct = filter.endorsedProduct;
    cloneFilter.endorsedWorkloads = filter.endorsedWorkloads;
    return cloneFilter;
  }

  static createFromQueryParam(queryParam: string): PartnersFilter {
    const partnersFilter = new PartnersFilter();
    if (queryParam) {
      const partnersFilterParsed: PartnersFilter = JSON.parse(decodeURIComponent(queryParam));
      partnersFilter.countryCode = partnersFilterParsed.countryCode;
      partnersFilter.locationLatitude = partnersFilterParsed.locationLatitude;
      partnersFilter.locationLongtitude = partnersFilterParsed.locationLongtitude;
      partnersFilter.locationName = partnersFilterParsed.locationName;
      partnersFilter.orgSize = partnersFilterParsed.orgSize;
      partnersFilter.azureMSP = partnersFilterParsed.azureMSP;
      partnersFilter.resultSortBy = partnersFilterParsed.resultSortBy;
      partnersFilter.resultPageSize = partnersFilterParsed.resultPageSize;
      partnersFilter.resultPageOffset = partnersFilterParsed.resultPageOffset;
      partnersFilter.radiusMiles = partnersFilterParsed.radiusMiles;
      partnersFilter.advSpecialization = partnersFilterParsed.advSpecialization;
      partnersFilter.products = partnersFilterParsed.products;
      partnersFilter.industries = partnersFilterParsed.industries;
      partnersFilter.services = partnersFilterParsed.services;
      partnersFilter.categories = partnersFilterParsed.categories;
      partnersFilter.userLocation = partnersFilterParsed.userLocation;
      partnersFilter.onlyThisCountryResults = partnersFilterParsed.onlyThisCountryResults;
      partnersFilter.freeText = partnersFilterParsed.freeText;
      partnersFilter.locationNotRequiredMode = partnersFilterParsed.locationNotRequiredMode;
      partnersFilter.searchSuggestionMode = partnersFilterParsed.searchSuggestionMode;
      partnersFilter.diverseOwned = partnersFilterParsed.diverseOwned;
      partnersFilter.diverseAccrediation = partnersFilterParsed.diverseAccrediation;
      partnersFilter.designations = partnersFilterParsed.designations;
      partnersFilter.trainingDesignations = partnersFilterParsed.trainingDesignations;
      partnersFilter.endorsedProduct = partnersFilterParsed.endorsedProduct;
      partnersFilter.endorsedWorkloads = partnersFilterParsed.endorsedWorkloads;
    }
    return partnersFilter;
  }

  getQueryParam = () => {
    return encodeURIComponent(JSON.stringify(this));
  };

  getPrettyQueryParam = (): string => {
    const params: { [key: string]: string } = {};
    if (this.orgSize) params[QUERY_PARAMS.orgSize] = this.orgSize;

    if (this.products && this.products.length > 0) params[QUERY_PARAMS.products] = this.products.join(',');
    if (this.services && this.services.length > 0) params[QUERY_PARAMS.services] = this.services.join(',');
    if (this.categories && this.categories.length > 0) params[QUERY_PARAMS.categories] = this.categories.join(',');
    if (this.industries && this.industries.length > 0) params[QUERY_PARAMS.industries] = this.industries.join(',');
    if (this.diverseOwned) params[QUERY_PARAMS.diverseOwn] = TRUE_STR;
    if (this.diverseAccrediation) params[QUERY_PARAMS.diverseAccred] = TRUE_STR;

    if (this.advSpecialization && this.advSpecialization.length > 0) params[QUERY_PARAMS.asp] = this.advSpecialization.join(',');
    if (this.azureMSP) params[QUERY_PARAMS.azuremsp] = TRUE_STR;
    if (this.designations?.length > 0) params[QUERY_PARAMS.designations] = this.designations.join(',');
    if (this.trainingDesignations?.length > 0) params[QUERY_PARAMS.trainingDesignations] = this.trainingDesignations.join(',');

    if (this.resultSortBy !== undefined) params[QUERY_PARAMS.sort] = this.resultSortBy.toString();
    if (this.resultPageSize) params[QUERY_PARAMS.pageSize] = this.resultPageSize.toString();
    if (this.resultPageOffset) params[QUERY_PARAMS.pageOffset] = this.resultPageOffset.toString();
    if (this.userLocation) params[QUERY_PARAMS.userLocation] = TRUE_STR;
    if (this.onlyThisCountryResults) params[QUERY_PARAMS.onlyThisCountry] = TRUE_STR;

    if (this.locationLatitude) params[QUERY_PARAMS.lat] = this.locationLatitude.toString();
    if (this.locationLongtitude) params[QUERY_PARAMS.lng] = this.locationLongtitude.toString();
    if (this.countryCode) params[QUERY_PARAMS.country] = this.countryCode;
    if (this.radiusMiles) params[QUERY_PARAMS.radius] = this.radiusMiles.toString();
    if (this.locationName) params[QUERY_PARAMS.locname] = this.locationName.toString();
    if (this.freeText) params[QUERY_PARAMS.freeText] = this.freeText;
    if (this.searchSuggestionMode) params[QUERY_PARAMS.suggestion] = TRUE_STR;
    if (this.locationNotRequiredMode) params[QUERY_PARAMS.locationNotRequired] = TRUE_STR;
    if (this.endorsedProduct) params[QUERY_PARAMS.endorsedProduct] = this.endorsedProduct;
    if (this.endorsedWorkloads?.length > 0) params[QUERY_PARAMS.endorsedWorkloads] = this.endorsedWorkloads.join(',');

    const queryParams = Object.keys(params)
      .map(function (key) {
        return '' + key + '=' + encodeURIComponent(params[key]); // line break for wrapping only
      })
      .join(';');
    return queryParams;
  };

  static createFromPrettyQueryParam(queryParam: string): PartnersFilter {
    const partnersFilter = new PartnersFilter();
    if (queryParam) {
      const splittedQueryParams: string[] = queryParam.split(';');
      const params: { [key: string]: string } = {};
      for (let i = 0; i < splittedQueryParams.length; i++) {
        const splittedQueryParam = splittedQueryParams[i].split('=');
        params[splittedQueryParam[0]] = decodeURIComponent(splittedQueryParam[1]); // lgtm[js/remote-property-injection]
      }

      //const partnersFilterParsed: { [key: string]: string } = JSON.parse(decodeURIComponent(queryParam));
      const partnersFilterParsed = params;
      if (partnersFilterParsed[QUERY_PARAMS.lat]) partnersFilter.locationLatitude = parseFloat(partnersFilterParsed[QUERY_PARAMS.lat]);
      if (partnersFilterParsed[QUERY_PARAMS.lng]) partnersFilter.locationLongtitude = parseFloat(partnersFilterParsed[QUERY_PARAMS.lng]);
      if (partnersFilterParsed[QUERY_PARAMS.country]) partnersFilter.countryCode = partnersFilterParsed[QUERY_PARAMS.country];
      if (partnersFilterParsed[QUERY_PARAMS.locname]) partnersFilter.locationName = partnersFilterParsed[QUERY_PARAMS.locname];
      if (partnersFilterParsed[QUERY_PARAMS.radius]) partnersFilter.radiusMiles = parseInt(partnersFilterParsed[QUERY_PARAMS.radius]);

      if (partnersFilterParsed[QUERY_PARAMS.orgSize]) partnersFilter.orgSize = partnersFilterParsed[QUERY_PARAMS.orgSize];

      partnersFilter.products = partnersFilterParsed[QUERY_PARAMS.products] ? partnersFilterParsed[QUERY_PARAMS.products].split(',') : [];
      partnersFilter.services = partnersFilterParsed[QUERY_PARAMS.services] ? partnersFilterParsed[QUERY_PARAMS.services].split(',') : [];
      partnersFilter.categories = partnersFilterParsed[QUERY_PARAMS.categories]
        ? partnersFilterParsed[QUERY_PARAMS.categories].split(',')
        : [];
      partnersFilter.industries = partnersFilterParsed[QUERY_PARAMS.industries]
        ? partnersFilterParsed[QUERY_PARAMS.industries].split(',')
        : [];
      partnersFilter.advSpecialization = partnersFilterParsed[QUERY_PARAMS.asp] ? partnersFilterParsed[QUERY_PARAMS.asp].split(',') : [];
      partnersFilter.designations = partnersFilterParsed[QUERY_PARAMS.designations]
        ? partnersFilterParsed[QUERY_PARAMS.designations].split(',')
        : [];
      partnersFilter.trainingDesignations = partnersFilterParsed[QUERY_PARAMS.trainingDesignations]
        ? partnersFilterParsed[QUERY_PARAMS.trainingDesignations].split(',')
        : [];

      partnersFilter.diverseOwned =
        partnersFilterParsed[QUERY_PARAMS.diverseOwn] && partnersFilterParsed[QUERY_PARAMS.diverseOwn] === TRUE_STR;
      partnersFilter.diverseAccrediation =
        partnersFilterParsed[QUERY_PARAMS.diverseAccred] && partnersFilterParsed[QUERY_PARAMS.diverseAccred] === TRUE_STR;

      partnersFilter.azureMSP = partnersFilterParsed[QUERY_PARAMS.azuremsp] && partnersFilterParsed[QUERY_PARAMS.azuremsp] === TRUE_STR;

      if (partnersFilterParsed[QUERY_PARAMS.sort]) partnersFilter.resultSortBy = parseInt(partnersFilterParsed[QUERY_PARAMS.sort]);
      if (partnersFilterParsed[QUERY_PARAMS.pageOffset]) {
        partnersFilter.resultPageOffset = parseInt(partnersFilterParsed[QUERY_PARAMS.pageOffset]);
      }
      if (partnersFilterParsed[QUERY_PARAMS.pageSize])
        partnersFilter.resultPageSize = parseInt(partnersFilterParsed[QUERY_PARAMS.pageSize]);
      partnersFilter.userLocation =
        partnersFilterParsed[QUERY_PARAMS.userLocation] && partnersFilterParsed[QUERY_PARAMS.userLocation] === TRUE_STR;
      partnersFilter.onlyThisCountryResults =
        partnersFilterParsed[QUERY_PARAMS.onlyThisCountry] && partnersFilterParsed[QUERY_PARAMS.onlyThisCountry] === TRUE_STR;

      if (partnersFilterParsed[QUERY_PARAMS.freeText]) {
        const sanitizedFreeText = DOMPurify.sanitize(partnersFilterParsed[QUERY_PARAMS.freeText], {
          USE_PROFILES: { html: false },
          KEEP_CONTENT: true
        });
        if (sanitizedFreeText) {
          partnersFilter.freeText = sanitizedFreeText;
        }
      }

      partnersFilter.locationNotRequiredMode =
        partnersFilterParsed[QUERY_PARAMS.locationNotRequired] && partnersFilterParsed[QUERY_PARAMS.locationNotRequired] === TRUE_STR;

      partnersFilter.searchSuggestionMode =
        partnersFilterParsed[QUERY_PARAMS.suggestion] && partnersFilterParsed[QUERY_PARAMS.suggestion] === TRUE_STR;

      if (partnersFilterParsed[QUERY_PARAMS.endorsedProduct])
        partnersFilter.endorsedProduct = partnersFilterParsed[QUERY_PARAMS.endorsedProduct];

      partnersFilter.endorsedWorkloads = partnersFilterParsed[QUERY_PARAMS.endorsedWorkloads]
        ? partnersFilterParsed[QUERY_PARAMS.endorsedWorkloads].split(',')
        : [];
    }
    return partnersFilter;
  }

  hasNonLocationActiveFilter = (): boolean => {
    return (
      (this.orgSize && this.orgSize != '') ||
      this.azureMSP ||
      (this.advSpecialization && this.advSpecialization.length > 0) ||
      (this.designations && this.designations.length > 0) ||
      (this.trainingDesignations && this.trainingDesignations.length > 0) ||
      (this.products && this.products.length > 0) ||
      (this.categories && this.categories.length > 0) ||
      (this.services && this.services.length > 0) ||
      (this.industries && this.industries.length > 0) ||
      (this.freeText && this.freeText !== '') ||
      (this.endorsedProduct && this.endorsedProduct !== '') ||
      (this.endorsedWorkloads && this.endorsedWorkloads.length > 0)
    );
  };

  hasLocationFilterCoordinates = (): boolean => {
    return this.locationLatitude != undefined && this.locationLongtitude != undefined;
  };

  hasLocationFilter = (): boolean => {
    if (this.hasLocationFilterCoordinates()) {
      return true;
    } else if (this.countryCode) {
      return true;
    }
    return false;
  };

  isEquals = (filter: PartnersFilter): boolean => {
    if (!filter) {
      return false;
    }
    return JSON.stringify(filter) === JSON.stringify(this);
  };

  validateFilter = (): FilterValidationResult => {
    const invalidResult =
      this.validateValueByType(this.orgSize ? [this.orgSize] : [], Object.values(CompanySizeKey), 'org size') ||
      this.validateValueByType(this.products, Object.values(ProductKey), 'product') ||
      this.validateValueByType(this.categories, Object.values(CategoryKey), 'category') ||
      this.validateValueByType(this.services, Object.values(ServiceKey), 'service') ||
      this.validateValueByType(this.industries, Object.values(IndustryKey), 'industry') ||
      this.validateValueByType(this.advSpecialization, Object.values(AdvSpecKey), 'adv spec') ||
      this.validateValueByType(this.designations, Object.values(DesignationsKey), 'designation') ||
      this.validateValueByType(this.trainingDesignations, Object.values(TrainingDesignationsKey), 'training designation') ||
      this.validateValueByType(this.resultSortBy ? [this.resultSortBy] : [], Object.values(PartnersResultsSort), 'sort');
    return invalidResult || { isValid: true };
  };

  synthesizeFilter = (logSynthesizeOperations: boolean): void => {
    this.products = this.synthesizeValuesByType(this.products, Object.values(ProductKey), logSynthesizeOperations);
    this.categories = this.synthesizeValuesByType(this.categories, Object.values(CategoryKey), logSynthesizeOperations);
    this.services = this.synthesizeValuesByType(this.services, Object.values(ServiceKey), logSynthesizeOperations);
    this.industries = this.synthesizeValuesByType(this.industries, Object.values(IndustryKey), logSynthesizeOperations);
    this.advSpecialization = this.synthesizeValuesByType(this.advSpecialization, Object.values(AdvSpecKey), logSynthesizeOperations);
    this.designations = this.synthesizeValuesByType(this.designations, Object.values(DesignationsKey), logSynthesizeOperations);
    this.trainingDesignations = this.synthesizeValuesByType(
      this.trainingDesignations,
      Object.values(TrainingDesignationsKey),
      logSynthesizeOperations
    );
    const syntOrg = this.synthesizeValuesByType(this.orgSize ? [this.orgSize] : [], Object.values(CompanySizeKey), logSynthesizeOperations);
    this.orgSize = syntOrg.length > 0 ? syntOrg[0] : null;
  };

  synthesizeValuesByType = <T>(valuesToValidate: T[], validValues: T[], logSynthesizeOperations: boolean): T[] => {
    const values: T[] = [];
    if (!valuesToValidate || valuesToValidate.length == 0) {
      return values;
    }
    for (let i = 0; i < valuesToValidate.length; i++) {
      if (validValues.includes(valuesToValidate[i])) {
        values.push(valuesToValidate[i]);
      } else {
        logSynthesizeOperations && Logger.Warning(LOGGER_PREFIX, `${valuesToValidate[i]} synthesized out of the filter.`);
      }
    }
    return values;
  };

  validateValueByType = <T>(valuesToValidate: T[], validValues: T[], typeName: string): FilterValidationResult => {
    if (!valuesToValidate) {
      return null;
    }
    for (let i = 0; i < valuesToValidate.length; i++) {
      if (!validValues.includes(valuesToValidate[i])) {
        return { isValid: false, validationError: `Invalid ${typeName}: ${valuesToValidate[i]}` };
      }
    }
    return null;
  };

  static createFilterFromSearchSuggestion = (
    filterKey: string,
    suggestType: AutoCompleteSuggestionType,
    filterQueryParam: string
  ): PartnersFilter => {
    const partnersFilter = PartnersFilter.createFromPrettyQueryParam(filterQueryParam);
    if (suggestType != undefined) {
      // Allow gallery to work w/o location.
      partnersFilter.locationNotRequiredMode = true;
      partnersFilter.searchSuggestionMode = true;
      if (suggestType === AutoCompleteSuggestionType.FreeText) {
        partnersFilter.freeText = filterKey;
      } else if (suggestType === AutoCompleteSuggestionType.AdvSpec) {
        partnersFilter.advSpecialization = [filterKey];
      } else if (suggestType === AutoCompleteSuggestionType.Category) {
        partnersFilter.categories = [filterKey];
      } else if (suggestType === AutoCompleteSuggestionType.Industry) {
        partnersFilter.industries = [filterKey];
      } else if (suggestType === AutoCompleteSuggestionType.Product) {
        partnersFilter.products = [filterKey];
      } else if (suggestType === AutoCompleteSuggestionType.Service) {
        partnersFilter.services = [filterKey];
      } else if (suggestType === AutoCompleteSuggestionType.Designation) {
        partnersFilter.designations = [filterKey];
      } else if (suggestType === AutoCompleteSuggestionType.TrainingDesignation) {
        partnersFilter.trainingDesignations = [filterKey];
      } else if (suggestType === AutoCompleteSuggestionType.DiversityAndInclusion) {
        partnersFilter.diverseOwned = filterKey === QUERY_PARAMS.diverseOwn;
        partnersFilter.diverseAccrediation = filterKey === QUERY_PARAMS.diverseAccred;
      } else if (suggestType === AutoCompleteSuggestionType.AzureMSP) {
        partnersFilter.azureMSP = filterKey === QUERY_PARAMS.azuremsp;
      }
    }
    return partnersFilter;
  };
}
