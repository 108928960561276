export interface IAutoCompleteResponse {
  suggestions: IAutoCompleteSuggestion[];
}

export enum AutoCompleteSuggestionType {
  Industry,
  Product,
  Service,
  Category,
  AdvSpec,
  Partner,
  FreeText,
  Designation,
  DiversityAndInclusion,
  AzureMSP,
  TrainingDesignation
}

export interface IAutoCompleteSuggestion {
  val: string;
  type: AutoCompleteSuggestionType;
  text: string;
}
