export interface IFilterDistanceOption {
  name: string;
  distanceMiles: number;
  distanceKM: number;
  localizationKey: string;
}

export const FILTER_DISTANCE_NEARBY = 'FILTER_DISTANCE_NEARBY';
export const FILTER_DISTANCE_MIDRANGE = 'FILTER_DISTANCE_MIDRANGE';
export const FILTER_DISTANCE_FAR = 'FILTER_DISTANCE_FAR';

const filterDistanceOptions: IFilterDistanceOption[] = [
  {
    name: 'neratBy',
    distanceMiles: 100,
    distanceKM: 160,
    localizationKey: 'FILTER_DISTANCE_NEARBY'
  },
  {
    name: 'midRangeBy',
    distanceMiles: 500,
    distanceKM: 800,
    localizationKey: 'FILTER_DISTANCE_MIDRANGE'
  },
  {
    name: 'far',
    distanceMiles: 1000,
    distanceKM: 1600,
    localizationKey: 'FILTER_DISTANCE_FAR'
  }
];

export const getFilterDistanceOptions = (): IFilterDistanceOption[] => {
  return filterDistanceOptions;
};

export const getDefaultDistanceOption = (): IFilterDistanceOption => {
  return filterDistanceOptions[0];
};

const filterDistanceOptionsMap = new Map<string, IFilterDistanceOption>();

export const getFilterDistanceOptionsMap = (): Map<string, IFilterDistanceOption> => {
  if (filterDistanceOptionsMap.size === 0) {
    filterDistanceOptions.forEach(option => {
      filterDistanceOptionsMap.set(option.name, option);
    });
  }
  return filterDistanceOptionsMap;
};

export const getFilterDistanceOptionByMilesRadius = (distanceMiles: number): IFilterDistanceOption => {
  for (let i = 0; i < filterDistanceOptions.length; i++) {
    if (filterDistanceOptions[i].distanceMiles === distanceMiles) {
      return filterDistanceOptions[i];
    }
  }
  return null;
};
