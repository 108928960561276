import React from 'react';

export interface ICollapsiblePanel {
  children: any;
  collapsed: boolean;
}

export default function CollapsiblePanel(props: ICollapsiblePanel) {
  return (
    <div>
      <div className="children" style={{ display: props.collapsed ? 'none' : 'block' }}>
        {props.children}
      </div>
    </div>
  );
}
