export const LOGGER = {
  START: 'START',
  END: 'END',
  INPROGRESS: 'INPROGRESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO'
};

export const FEATURE_FLAGS = {
  DesignationsEnabled: 'designationsEnabled'
};
