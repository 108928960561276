export class SplitStringResult {
  prefix = '';
  match = '';
  suffix = '';
  foudAndSplitted: boolean;
}

export const containsCaseInsesitive = (searchIn: string, searchVal: string): boolean => {
  if (!searchIn || !searchVal) return false;
  return searchIn.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1;
};

export const splitStringBySearchPhrase = (searchIn: string, searchVal: string): SplitStringResult => {
  const result: SplitStringResult = new SplitStringResult();
  // Invalid input --> empty result
  if (!searchIn || !searchVal) {
    result.foudAndSplitted = false;
  } else {
    const foundIndex = searchIn.toLowerCase().indexOf(searchVal.toLowerCase());
    // Not found
    if (foundIndex === -1) {
      result.foudAndSplitted = false;
      // Found and splitted
    } else {
      result.foudAndSplitted = true;
      result.prefix = foundIndex > 0 ? searchIn.substr(0, foundIndex) : '';
      result.match = searchIn.substr(foundIndex, searchVal.length);
      const suffixStartIndex = foundIndex + searchVal.length;
      result.suffix = suffixStartIndex < searchIn.length ? searchIn.substr(suffixStartIndex) : '';
    }
  }
  return result;
};

export const trimToLengthWithEllipsis = (str: string, length: number): string => {
  if (!length || length < 4 || !str || str.length <= length) {
    return str;
  }
  return str.substr(0, length - 3) + '...';
};
