export function throttle(callback, interval) {
  let pending = false;
  return () => {
    if (!pending) {
      pending = true;
      setTimeout(() => {
        pending = false;
        callback();
      }, interval);
    }
  };
}
