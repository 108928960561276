import { combineReducers } from 'redux';
import partnersReducer from './partnersSlice';
import partnersDetailsReducer from './partnersDetailsSlice';
import hostContextReducer from './hostContextSlice';
import locationReducer from './locationSlice';
import configReducer from './configSlice';

const rootReducer = combineReducers({
  partners: partnersReducer,
  partnersDetails: partnersDetailsReducer,
  hostContext: hostContextReducer,
  location: locationReducer,
  config: configReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
