import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IViewContext } from '../protocol/partnersViewInboundMessages';
import { setUserLoggedIn } from '../utils/stateUtil';

export interface IScrollOffset {
  xOffset: number;
  yOffset: number;
}

export interface HostSizeInfo {
  width: number;
  height: number;
  frameLeft: number;
  frameTop: number;
}

export interface IHostContextStore {
  context: IViewContext;
  contextInit: boolean;
  scrollOffset: IScrollOffset;
  hostSize: HostSizeInfo;
}

const initialState: IHostContextStore = {
  context: {
    hostUrl: '',
    partnersUrl: '',
    user: {
      firstName: '',
      lastName: '',
      country: '',
      email: '',
      phone: '',
      company: '',
      title: '',
      accessToken: '',
      signedIn: false
    },
    filterLocation: null,
    contactDetails: null
  },
  contextInit: false,
  scrollOffset: { xOffset: 0, yOffset: 0 },
  hostSize: { width: 0, height: 0, frameLeft: 0, frameTop: 0 }
};

const hostContextSlice = createSlice({
  name: 'hostContext',
  initialState,
  reducers: {
    setContextInfo: (state, { payload }: PayloadAction<{ context: IViewContext }>) => {
      state.context = payload.context;
      setUserLoggedIn(payload?.context?.user?.signedIn ? true : false);
      state.contextInit = true;
      return state;
    },
    setScrollOffset: (state, { payload }: PayloadAction<{ scrollOffset: IScrollOffset }>) => {
      state.scrollOffset = payload.scrollOffset;
    },
    setHostSize: (state, { payload }: PayloadAction<{ size: HostSizeInfo }>) => {
      state.hostSize = payload.size;
    }
  }
});

export const { setContextInfo, setScrollOffset, setHostSize } = hostContextSlice.actions;
export const getScrollOffset = (hostContext: IHostContextStore) => hostContext.scrollOffset;
export const getHostSize = (hostContext: IHostContextStore) => hostContext.hostSize;
export const getContext = (hostContext: IHostContextStore) => hostContext.context;
export const getContextInit = (hostContext: IHostContextStore) => hostContext.contextInit;
export default hostContextSlice.reducer;
