import { RequestOptions } from 'https';
import { HttpClient } from '../services/httpClient';
import * as Logger from '../services/loggerService';
import { MONITORS_OUTGOING_REQUESTS } from '../services/monitorService';
import { LOGGER } from '../shared/constants';
import { IPartner } from '../shared/models/partnerModel';
import { PartnersFilter } from '../shared/models/Request/partnersFilter';
import { SendReferralRequest } from '../shared/models/Request/sendReferral';
import { IAutoCompleteResponse } from '../shared/models/Response/searchModels';
import { MONITORS_METRICS, monitorMetric } from '../services/monitorService';

export const fetchPartners = async (filter: PartnersFilter) => {
  const queryFilterParam = filter.getPrettyQueryParam();
  Logger.Info('fetchPartners', '/api/partners' + 'filter=' + queryFilterParam, LOGGER.START);
  const result = await HttpClient.get(`/api/partners`, MONITORS_OUTGOING_REQUESTS.FILTER_PARTNERS, null, { filter: queryFilterParam });
  // Log metric only if request succeeded and returned data.
  if (result?.data) {
    const partnersCount = result.data.matchingPartners?.items?.length || 0;
    monitorMetric({ name: MONITORS_METRICS.FILTER_PARTNERS_RESULTS_COUNT, value: partnersCount });
  }
  return result;
};

export const fetchPartnerDetails = async (id: string) => {
  Logger.Info('fetchPartnerDetails', `/api/partners/${id}`, LOGGER.START);
  return await HttpClient.get(`/api/partners/${id}`, MONITORS_OUTGOING_REQUESTS.GET_PARTNER_DETAILS);
};

export const contactPartnerRequest = async (contactInfo: SendReferralRequest[], accessToken: string) => {
  Logger.Info('contactPartnerRequest', `/api/contact`, LOGGER.START);
  const options: RequestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return await HttpClient.post(`/api/contact`, MONITORS_OUTGOING_REQUESTS.SUBMIT_CONTACT, contactInfo, options);
};

export const searchAutocComplete = async (searchQuery: string, locale: string): Promise<IAutoCompleteResponse> => {
  Logger.Info('contactPartnerRequest', `/api/search/autocomplete`, LOGGER.START);
  const response = await HttpClient.get(`/api/search/autocomplete`, MONITORS_OUTGOING_REQUESTS.SEARCH_AUTO_COMPLETE, null, {
    searchQuery: searchQuery,
    searchLocale: locale
  });
  if (response.status == 200) {
    return response.data as IAutoCompleteResponse;
  } else {
    return null;
  }
};

export const searchPartnerForName = async (partnerName: string): Promise<IPartner> => {
  Logger.Info('searchPartnerForName', `/api/search/partner`, LOGGER.START);
  const response = await HttpClient.get(`/api/search/partner`, MONITORS_OUTGOING_REQUESTS.SEARCH_PARTNER_ID, null, {
    partnerName: partnerName
  });
  if (response.status == 200) {
    return response.data as IPartner;
  } else {
    return null;
  }
};
