import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { getContext } from '../../redux/hostContextSlice';
import ContactPartnerDialog from '../contactPartner/contactPartnerDialog';
import { IPartnerDetails, IPartnerLocation } from '../../shared/models/partnerDetailsModel';
import { IViewContext } from '../../protocol/partnersViewInboundMessages';
import ContactMessageSentDialog from '../contactPartner/contactMessageSentDialog';
import * as PartnerActions from '../../redux/partnersDetailsSlice';

export interface IContactPartnerManager {
  partnerId: string;
  partnerListingId: string;
  partnerName: string;
  partnerLogo: string;
  partnerLocation: IPartnerLocation;
  allPartnerDetails: IPartnerDetails[];
  allLocations: IPartnerLocation[];
  isRtl: boolean;
  loadPartnerDetails: boolean;
  onDismiss(): void;
}

// Manage the submit process that is diffrent between PDP and tile.
// Submit from PDP - all the locations of the partner are already loaded.
// Submit from tile - locations are not loaded yet and need to loaded on the fly.
export default function ContactPartnerManager(props: IContactPartnerManager) {
  const dispatch = useDispatch();
  const [contactDialogVisible, setContactDialogVisible] = useState(true);
  const [messageSentDialogVisible, setMessageSentDialogVisible] = useState(false);
  const viewContext: IViewContext = useSelector((store: RootState) => getContext(store.hostContext));
  const loadedPartnerResponse = useSelector((store: RootState) => PartnerActions.getPartnerDetails(store.partnersDetails, props.partnerId));

  useEffect(() => {
    if (props.loadPartnerDetails) {
      dispatch(PartnerActions.fetchPartnerDetailsAsync({ id: props.partnerId }));
    }
  }, []);

  const onContactMeDialogDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const onContactMeMessageSent = () => {
    setContactDialogVisible(false);
    setMessageSentDialogVisible(true);
  };

  const onContactMessageSentDialogDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const renderContactMeDialog = () => {
    if (contactDialogVisible) {
      return (
        <div>
          <ContactPartnerDialog
            partnerId={props.partnerId}
            partnerListingId={props.partnerListingId}
            partnerLocation={props.partnerLocation}
            partnerName={props.partnerName}
            partnerLogoURL={props.partnerLogo}
            isDialogOpen={true}
            allLocations={loadedPartnerResponse ? loadedPartnerResponse.allLocations : props.allLocations}
            allPartnerDetails={props.allPartnerDetails}
            user={viewContext ? viewContext.user : null}
            lastContactDetails={viewContext ? viewContext.contactDetails : null}
            directionRtl={props.isRtl}
            loadingMoreLocations={props.loadPartnerDetails && !loadedPartnerResponse}
            onDismissDialog={onContactMeDialogDismiss}
            onContactMessageSent={onContactMeMessageSent}
          />
        </div>
      );
    } else return null;
  };

  const renderMessageSentDialog = () => {
    if (messageSentDialogVisible) {
      return (
        <div>
          <ContactMessageSentDialog
            partnerName={props.partnerName}
            directionRtl={props.isRtl}
            isDialogOpen={true}
            onDismissDialog={onContactMessageSentDialogDismiss}
          />
        </div>
      );
    } else return null;
  };

  if (props.partnerId) {
    return (
      <div>
        {renderContactMeDialog()}
        {renderMessageSentDialog()}
      </div>
    );
  } else {
    return null;
  }
}
