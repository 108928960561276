import { appInsights } from '../providers/appInsightProvider';
import { runningInIframe } from '../utils/iframeUtils';
import { getUserLoggedIn } from '../utils/stateUtil';

// Outgoing requests - called from proxy to the backend or other services.
export const MONITORS_OUTGOING_REQUESTS = {
  FILTER_PARTNERS: 'MONITOR.FRONTEND.OUTGOING.REQUEST.FILTER_PARTNERS',
  GET_PARTNER_DETAILS: 'MONITOR.FRONTEND.OUTGOING.REQUEST.GET_PARTNER_DETAILS',
  SUBMIT_CONTACT: 'MONITOR.FRONTEND.OUTGOING.REQUEST.SUBMIT_CONTACT',
  SEARCH_AUTO_COMPLETE: 'MONITOR.FRONTEND.OUTGOING.REQUEST.SEARCH_AUTO_COMPLETE',
  SEARCH_PARTNER_ID: 'MONITOR.FRONTEND.OUTGOING.REQUEST.SEARCH_PARTNER_ID'
};

export const MONITORS_METRICS = {
  FILTER_PARTNERS_RESULTS_COUNT: 'MONITOR.FRONTEND.METRIC.FILTER_PARTNERS_RESULTS_COUNT'
};

export const EXCEPTIONS = {
  UNHANDLED: 'MONITOR.FRONTEND.EXCEPTION.UNHANDLED'
};

export const MONITORS_DEPENDENCIES = {
  CONFIG: 'MONITOR.FRONTEND.DEPENDENCY.CONFIG',
  BING_REVERSE_GEO_CODE: 'MONITOR.FRONTEND.DEPENDENCY.BING_REVERSE_GEO_CODE',
  IFRAME_SEND_READY_MESSAGE: 'MONITOR.FRONTEND.DEPENDENCY.IFRAME_SEND_READY_MESSAGE',
  IFRAME_RECIEVED_CONTEXT_MESSAGE: 'MONITOR.FRONTEND.DEPENDENCY.IFRAME_RECIEVED_CONTEXT_MESSAGE',
  SEARCH_ROUTING_PARTNER_NAME_TO_ID: 'MONITOR.FRONTEND.DEPENDENCY.SEARCH_ROUTING_PARTNER_NAME_TO_ID'
};

export interface OutgoingRequest {
  name: string;
  url: string;
  type: string;
  success: boolean;
  error?: any;
  duration: number;
  status?: number;
  errorType?: any;
  appServiceName: string;
}

export interface Exception {
  name: string;
  message: string;
  stack: string;
}

export interface Dependency {
  name: string;
  success: boolean;
  error?: any;
}

const isIframe = runningInIframe();

export interface Metric {
  name: string;
  value: number;
}

export const monitorMetric = (metric: Metric) => {
  // Try/catch because telemetry shouldn't fail the app.
  try {
    appInsights.trackMetric({
      name: metric.name,
      average: metric.value,
      properties: {
        iframe: isIframe,
        userLoggedIn: getUserLoggedIn()
      }
    });
  } catch (err) {
    const errorMessage = `Unexpected error in monitorMetric. error: ${err}`;
    console.log(errorMessage);
  }
};

export const monitorOutgoingRequest = (outgoingRequest: OutgoingRequest) => {
  try {
    appInsights.trackEvent({
      name: outgoingRequest.name,
      properties: {
        url: outgoingRequest.url,
        type: outgoingRequest.type,
        success: outgoingRequest.success,
        error: outgoingRequest.error,
        duration: outgoingRequest.duration,
        iframe: isIframe,
        userLoggedIn: getUserLoggedIn(),
        status: outgoingRequest.status,
        errorType: outgoingRequest.errorType,
        appServiceName: outgoingRequest.appServiceName
      }
    });
  } catch (err) {
    const errorMessage = `Unexpected error in monitorOutgoingRequest. error: ${err}`;
    console.log(errorMessage);
  }
};

export const monitorException = (exception: Exception) => {
  // Try/catch because telemetry shouldn't fail the app.
  try {
    appInsights.trackEvent({
      name: exception.name,
      properties: {
        message: exception.message,
        stack: exception.stack,
        iframe: isIframe,
        userLoggedIn: getUserLoggedIn()
      }
    });
  } catch (err) {
    const errorMessage = `Unexpected error in monitorException. error: ${err}`;
    console.log(errorMessage);
  }
};

export const monitorDependency = (dependency: Dependency) => {
  // Try/catch because telemetry shouldn't fail the app.
  try {
    appInsights.trackEvent({
      name: dependency.name,
      properties: {
        success: dependency.success,
        error: dependency.error
      }
    });
  } catch (err) {
    const errorMessage = `Unexpected error in monitorDependency. error: ${err}`;
    console.log(errorMessage);
  }
};
