import { IFilterLocation, IUserLocation } from '../redux/locationSlice';
import { PartnersFilter } from '../shared/models/Request/partnersFilter';
import { IViewContext } from '../protocol/partnersViewInboundMessages';
import { getCountryByCode, ILocationNames } from '../utils/countryStatesUtils';

export class FilterLocationService {
  constructor(filter: PartnersFilter, userLocation: IUserLocation, lastFilterLocation: IFilterLocation, viewContext: IViewContext) {
    this.filter = filter;
    this.userLocation = userLocation;
    this.lastFilterLocation = lastFilterLocation;
    this.viewContext = viewContext;
  }

  filter: PartnersFilter = undefined;
  userLocation: IUserLocation = undefined;
  lastFilterLocation: IFilterLocation = undefined;
  viewContext: IViewContext = undefined;

  filterHasLocation(): boolean {
    return this.filter && this.filter.hasLocationFilter();
  }

  hasUserOrFilterLocation(): boolean {
    return (
      (this.lastFilterLocation && this.lastFilterLocation.location.coordExist()) || (this.userLocation && this.userLocation.coordExist())
    );
  }

  hasViewContextFilterLocation = () => {
    if (!this.viewContext || !this.viewContext.filterLocation) {
      return false;
    }
    const hasCountryCodeAndName = this.viewContext.filterLocation.countryCode && this.viewContext.filterLocation.locationName;
    const hasCoordinates =
      this.viewContext.filterLocation.locationLatitude != undefined && this.viewContext.filterLocation.locationLongtitude != undefined;
    return hasCountryCodeAndName || hasCoordinates;
  };

  shouldUpdateCountryCodeFromUserContext = () => {
    const hasUserCountry = this.viewContext && this.viewContext.user && this.viewContext.user.country;
    // No country for the user - no need to update.
    if (!hasUserCountry) {
      return false;
    }
    // There is country for the user but no partner url at all - update.
    if (!this.viewContext.partnersUrl) {
      return true;
    }
    return !this.hasLocationInPartnersUrlContext();
  };

  hasLocationInPartnersUrlContext = () => {
    if (!this.viewContext || !this.viewContext.partnersUrl) {
      return false;
    }
    const contextPartnerUrl = new URL(this.viewContext.partnersUrl, 'http://fakebase.com');
    const filterString = contextPartnerUrl.searchParams.get('filter');
    // There is country for the user but no filter in partner url - update.
    if (!filterString) {
      return false;
    }
    // Get the filter from context - if it has location - no need to update.
    const filterFromContextUrl = PartnersFilter.createFromPrettyQueryParam(filterString);
    return filterFromContextUrl.hasLocationFilter();
  };

  updateFilterLocationIfNeeded(): boolean {
    // No location in filter try to get location for the filter.
    if (!this.filterHasLocation() && !this.hasLocationInPartnersUrlContext()) {
      // Priority 1 - take location from last used filter
      if (
        this.lastFilterLocation &&
        this.lastFilterLocation.location &&
        (this.lastFilterLocation.location.coordExist() ||
          (this.lastFilterLocation.location.name && this.lastFilterLocation.location.countryCode))
      ) {
        this.filter.locationLatitude = this.lastFilterLocation.location.latitude;
        this.filter.locationLongtitude = this.lastFilterLocation.location.longitude;
        this.filter.locationName = this.lastFilterLocation.location.name;
        this.filter.countryCode = this.lastFilterLocation.location.countryCode;
        this.filter.onlyThisCountryResults = this.lastFilterLocation.filterThisLocationOnly;
        this.filter.radiusMiles = this.lastFilterLocation.filterRadiusMiles;
        this.filter.userLocation = false;
        return true;
        // Priority 2 - take filter location from filter location of hosting context
      } else if (this.hasViewContextFilterLocation()) {
        this.filter.locationLatitude = this.viewContext.filterLocation.locationLatitude;
        this.filter.locationLongtitude = this.viewContext.filterLocation.locationLongtitude;
        this.filter.locationName = this.viewContext.filterLocation.locationName;
        this.filter.countryCode = this.viewContext.filterLocation.countryCode;
        this.filter.radiusMiles = this.viewContext.filterLocation.radiusMiles;
        this.filter.onlyThisCountryResults = this.viewContext.filterLocation.onlyThisCountryResults;
        this.filter.userLocation = false;
        return true;
        // Priority 3 - use country code of the logged in user revieved in the hosting context.
      } else if (this.shouldUpdateCountryCodeFromUserContext()) {
        this.filter.countryCode = this.viewContext.user.country;
        const countryName: ILocationNames = getCountryByCode(this.viewContext.user.country);
        if (countryName) {
          this.filter.locationName = countryName.name;
        }
        return true;
      }
      // Priority 4 - take user location (recieved from browser) if exist.
      else if (this.userLocation && this.userLocation.coordExist()) {
        this.filter.locationLatitude = this.userLocation.latitude;
        this.filter.locationLongtitude = this.userLocation.longitude;
        this.filter.locationName = this.userLocation.name;
        this.filter.countryCode = this.userLocation.countryCode;
        this.filter.onlyThisCountryResults = true;
        // Flag in the filter that this is user location.
        this.filter.userLocation = true;
        return true;
      }
    }
    return false;
  }

  shouldUpdateFilterLocationFromFilter(): boolean {
    return (
      this.filter.hasLocationFilter() &&
      (!this.lastFilterLocation || !this.lastFilterLocation.location || !this.lastFilterLocation.location.coordExist())
    );
  }
}
