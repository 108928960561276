import * as LOCALIZATION from '../constants/localization';

export enum DominDataType {
  Industry,
  Product,
  Service,
  Category,
  AdvSpec,
  CompanySize,
  DiverseOwnedLedKey,
  DiverseAccred,
  Designations,
  AdvSpecParent,
  ServiceParent,
  TrainingDesignations
}

export interface IDomainData {
  key: string;
  newKey: string;
  localizationKey: string;
  isRemoved?: boolean;
  type: DominDataType;
  parent?: string;
}

export const IndustryKey = {
  Agriculture: 'Agriculture',
  MediaCommunications: 'Media & Communications',
  Education: 'Education',
  FinancialServices: 'Financial Services',
  Government: 'Government',
  Healthcare: 'Healthcare',
  HospitalityTravel: 'Hospitality  & Travel',
  Nonprofit: 'Nonprofit & IGO',
  ManufacturingResources: 'Manufacturing & Resources',
  RetailConsumerGoods: 'Retail & Consumer Goods',
  ProfessionalServices: 'Professional services',
  Distribution: 'Distribution',
  PowerAndUtilities: 'Power and utilities',
  PublicSafetyNationalSecurity: 'Public Safety and National Security',
  Transportation: 'Transportation'
};

export const CompanySizeKey = {
  _1_to_9: '1 - 9 employees',
  _10_to_50: '10 - 50 employees',
  _51_to_250: '51 - 250 employees',
  _251_to_1000: '251 - 1000 employees',
  _1001_to_5000: '1001 - 5000 employees',
  _5001_to_10000: '5001 - 10000 employees',
  _10001_to_20000: '10001 - 20000 employees',
  _more_than_20000: 'more than 20000 employees'
};

export const ProductKey = {
  Azure: 'Azure',
  EnterpriseMobilityAndSecurity: 'EnterpriseMobilityAndSecurity',
  Exchange: 'Exchange',
  DeveloperTools: 'DeveloperTools',
  Dynamics365Business: 'Dynamics365Business',
  Dynamics365Enterprise: 'Dynamics365Enterprise',
  DynamicsAX_GP_NAV_SL: 'DynamicsAX GP NAV SL',
  Teams: 'Teams',
  Office: 'Office',
  PowerBI: 'PowerBI',
  Project: 'Project',
  SharePoint: 'SharePoint',
  SkypeForBusiness: 'SkypeForBusiness',
  Visio: 'Visio',
  Windows: 'Windows',
  Yammer: 'Yammer',
  Microsoft365: 'Microsoft365',
  SQL: 'SQL',
  Surface: 'Surface',
  SurfaceHub: 'SurfaceHub'
};

export const ServiceKey = {
  DeploymentMigration: 'Deployment or Migration',
  Licensing: 'Licensing',
  Customsolution: 'Custom solution',
  IPServices: 'IP Services (ISV)',
  ManagedServices: 'Managed Services (MSP)',
  ProjectManagement: 'ProjectManagement',
  LearningCertification: 'Learning and Certification',
  Consulting: 'Consulting',
  Hardware: 'Hardware',
  Integration: 'Integration'
};

export const ServiceParent = {
  TechnologySolutions: 'Technology solutions',
  ProfessionalServices: 'Professional services',
  Resellers: 'Resellers'
};

export const CategoryKey = {
  Integration: 'Integration',
  Networking: 'Networking',
  BackupDisasterRecovery: 'BackupDisasterRecovery',
  NoSQLMigration: 'NoSQLMigration',
  CloudMigration: 'CloudMigration',
  AzureStack: 'Azure Stack',
  DevOps: 'DevOps',
  Security: 'Security',
  IdentityAccessMngmnt: 'IdentityAccessMngmnt',
  WebDevelopment: 'WebDevelopment',
  MobileApplications: 'Mobile applications',
  Gaming: 'Gaming',
  Media: 'Media',
  Blockchain: 'Blockchain',
  Containers: 'Containers',
  Analytics: 'Analytics',
  AIMachineLearning: 'AI + Machine Learning',
  CognitiveServices: 'Cognitive Services',
  InternetofThings: 'Internet of Things',
  DeveloperTools: 'Developer Tools',
  Collaboration: 'Collaboration',
  AzureExpertMSPs: 'Azure Expert MSPs',
  ApplicationIntegration: 'Application Integration',
  InformationManagement: 'Information Management',
  CloudDatabaseMigration: 'Cloud Database Migration',
  ThreatProtection: 'Threat Protection',
  SQLServerUpgrade: 'SQL Server upgrade',
  ArtificialIntelligence: 'Artificial Intelligence',
  EnterpriseBusinessIntelligence: 'Enterprise Business Intelligence',
  DataWarehouse: 'Data Warehouse',
  BigData: 'Big Data',
  SharepointOnAzure: 'SharepointOnAzure',
  CloudVoice: 'CloudVoice',
  ServerlessComputing: 'ServerlessComputing',
  HybridStorage: 'Hybrid Storage',
  Dynamics365forSales: 'Dynamics365forSales',
  Dynamics365forCustomerService: 'Dynamics365forCustomerService',
  Dynamics365forFieldService: 'Dynamics365forFieldService',
  Dynamics365forFinance: 'Dynamics365forFinance',
  Dynamics365forRetails: 'Dynamics365forRetails',
  Dynamics365forTalent: 'Dynamics365forTalent',
  HighPerformanceComputing: 'HighPerformanceComputing',
  Microserviceapplications: 'Microserviceapplications',
  RegulatorycomplianceGDPR: 'RegulatorycomplianceGDPR',
  CompetitiveDatabaseMigration: 'CompetitiveDatabaseMigration',
  MySQLPostgresMigrationtoAzure: 'MySQLPostgresMigrationtoAzure',
  DynamicsonAzure: 'DynamicsonAzure',
  DatabaseonLinux: 'DatabaseonLinux',
  SAPonAzure: 'SAPonAzure',
  RedhatonAzure: 'RedhatonAzure',
  InformationProtection: 'InformationProtection',
  Chatbot: 'Chatbot',
  Surface: 'Surface1'
};

export const AdvSpecKey = {
  SAPOnAzure: 'SAP on Microsoft Azure',
  AdoptionChangeMgmt: 'Adoption and Change management',
  ModernWebApp: 'Modernization of Web Applications to Microsoft Azure',
  CallingTeams: 'Calling for Microsoft Teams',
  MeetingTeams: 'Meetings and Meeting Rooms for Microsoft Teams',
  Kubernetes: 'Kubernetes on Microsoft Azure',
  DWMigrate: 'Data Warehouse Migration to Microsoft Azure',
  TeamworkDeployment: 'Teamwork Deployment',
  WinVirtualDesktop: 'Microsoft Windows Virtual Desktop',
  IdAccessManagement: 'Id Access Management',
  InformationProtection: 'Information Protection',
  LowCodeApp: 'Low Code App',
  CustomTeamsSolution: 'Custom Teams Solution',
  AzureAnalytics: 'Analytics on Microsoft Azure',
  ThreatProtection: 'Threat Protection',
  SmallMidBiz: 'Small and Midsize Business Management',
  HybridCloudSecurity: 'Cloud Security',
  AzureVMWare: 'Microsoft Azure VMware Solution',
  AzureHybridCloudArc: 'Hybrid Operations and Management with Microsoft Azure Arc',
  AzureAI: 'AI and Machine Learning in Microsoft Azure',
  AzureHybridCloudStackHCI: 'Hybrid Cloud Infrastructure with Microsoft Azure Stack HCI',
  AzureDevOps: 'DevOps with GitHub on Microsoft Azure',
  AzureNetworking: 'Networking Services in Microsoft Azure',
  ModernizeEndpoints: 'Modernize Endpoints',
  InfraDBMigrate: 'Infra and Database Migration to Microsoft Azure',
  Sales: 'Sales',
  Service: 'Service',
  SupplyChain: 'SupplyChain',
  Finance: 'Finance',
  PowerBI: 'PowerBI',
  PowerAutomate: 'PowerAutomate',
  BuildAndAIApp: 'BuildAndAIApp'
};

export const AdvSpecParent = {
  Azure: 'Azure',
  ModernWork: 'Modern Work​',
  Security: 'Security​',
  BusinessApplications: 'Business Applications​'
};

export const DiverseOwnedLedKey = {
  AfricanAmericanOrBlackOwned: 'African American or Black-Owned',
  AfricanAmericanOrBlackLed: 'African American or Black-Led',
  AsianOwned: 'Asian-Owned',
  AsianLed: 'Asian-Led',
  HispanicLatinoOwned: 'Hispanic or Latino-Owned',
  HispanicLatinoLed: 'Hispanic or Latino-Led',
  MiddleEasternNorthAfricanOwned: 'Middle Eastern or North African-Owned',
  MiddleEasternNorthAfricanLed: 'Middle Eastern or North African-Led',
  NativeHawaiianPacificIslanderOwned: 'Native Hawaiian or Pacific Islander-Owned',
  NativeHawaiianPacificIslanderLed: 'Native Hawaiian or Pacific Islander-Led',
  NativeAmericanAlaskaNativeOwned: 'Native American or Alaska Native-Owned',
  NativeAmericanAlaskaNativeLed: 'Native American or Alaska Native-Led',
  DisabilityOwned: 'Disability-Owned',
  DisabilityLed: 'Disability-Led',
  LGBTQOwned: 'LGBTQ-Owned',
  LGBTQLed: 'LGBTQ-Led',
  VeteranOwned: 'Veteran-Owned',
  VeteranLed: 'Veteran-Led',
  WomanOwned: 'Woman-Owned',
  WomanLed: 'Woman-Led',
  PNTAOwned: 'Prefer not to answer',
  PNTALed: 'Prefer not to answer',
  NAOwned: 'Not applicable',
  NALed: 'Not applicable'
};

export const DiverseThirdPartyAccreditationKey = {
  DisabilityIN: 'Disability:IN',
  NationalLGBTChamberofCommerce: 'National LGBT Chamber of Commerce',
  NationalMinoritySupplierDevelopmentCouncil: 'National Minority Supplier Development Council',
  NationalVeteranBusinessDevelopmentCouncil: 'National Veteran Business Development Council',
  USSBA8A: 'US-SBA: 8(A)',
  USSBAHUBZone: 'US-SBA: HUBZone',
  USSBASmallDisadvantagedBusiness: 'US-SBA: Small disadvantaged business',
  USSBAWomanOwnedSmallBusiness: 'US-SBA: Woman-owned small business',
  USSBAServiceDisabledVeteranOwnedSmallBusiness: 'US-SBA: Service-Disabled/Veteran-owned small business',
  WomensBusinessEnterpriseNationalCouncil: 'Women’s Business Enterprise National Council',
  OtherCertAgency: 'Other certification agency',
  OtherCertAgency2: 'Other diverse business certification: Please specify',
  OTPC: 'Other third-party certification: Please specify',
  NA: 'Not applicable'
};

export const DesignationsKey = {
  DigitalAndAppInnovation: 'AzureDigiAppCompetency',
  Infrastructure: 'AzureInfraCompetency',
  DataAndAI: 'AzureDataAICompetency',
  ModernWork: 'ModernCompetency',
  Security: 'SecurityCompetency',
  BusinessApplications: 'BAGCompetency'
};

export const TrainingDesignationsKey = {
  DigitalAndAppInnovation: 'TrainingDigiApp',
  Infrastructure: 'TrainingInfrastructure',
  DataAndAI: 'TrainingDataAI',
  ModernWork: 'TrainingModernWork',
  Security: 'TrainingSecurity',
  BusinessApplications: 'TrainingBusinessApplications'
};

export const DomainDataObj = (): IDomainData[] => {
  return [
    //Industries
    {
      key: 'Agriculture, Forestry, & Fishing',
      newKey: IndustryKey.Agriculture,
      localizationKey: LOCALIZATION.AGRICULTURE,
      type: DominDataType.Industry
    },
    {
      key: 'Communications & Media',
      newKey: IndustryKey.MediaCommunications,
      localizationKey: LOCALIZATION.MEDIA_COMMUNICATIONS,
      type: DominDataType.Industry
    },
    {
      key: 'Education',
      newKey: IndustryKey.Education,
      localizationKey: LOCALIZATION.EDUCATION,
      type: DominDataType.Industry
    },
    {
      key: 'Financial Services',
      newKey: IndustryKey.FinancialServices,
      localizationKey: LOCALIZATION.FINANCIAL_SERVICES,
      type: DominDataType.Industry
    },
    {
      key: 'Government',
      newKey: IndustryKey.Government,
      localizationKey: LOCALIZATION.GOVERNMENT,
      type: DominDataType.Industry
    },
    {
      key: 'Healthcare',
      newKey: IndustryKey.Healthcare,
      localizationKey: LOCALIZATION.HEALTH_CARE,
      type: DominDataType.Industry
    },
    {
      key: 'Hospitality',
      newKey: IndustryKey.HospitalityTravel,
      localizationKey: LOCALIZATION.HOSPITALITY_TRAVEL,
      type: DominDataType.Industry
    },
    {
      key: 'Nonprofit',
      newKey: IndustryKey.Nonprofit,
      localizationKey: LOCALIZATION.NON_PROFIT,
      type: DominDataType.Industry
    },
    {
      key: 'Manufacturing',
      newKey: IndustryKey.ManufacturingResources,
      localizationKey: LOCALIZATION.MANUFACTURING_RESOURCES,
      type: DominDataType.Industry
    },
    {
      key: 'Power & Utilities',
      newKey: IndustryKey.PowerAndUtilities,
      localizationKey: LOCALIZATION.POWER_AND_UTILS,
      type: DominDataType.Industry
    },
    {
      key: 'Public Safety and National Security',
      newKey: IndustryKey.PublicSafetyNationalSecurity,
      localizationKey: LOCALIZATION.PUBLIC_SAFETY_AND_NATIONAL_SECURITY,
      type: DominDataType.Industry
    },
    {
      key: 'Retail & Consumer Goods',
      newKey: IndustryKey.RetailConsumerGoods,
      localizationKey: LOCALIZATION.RETAIL_CONSUMER_GOODS,
      type: DominDataType.Industry
    },
    {
      key: 'Services',
      newKey: IndustryKey.ProfessionalServices,
      localizationKey: LOCALIZATION.PROFESSIONAL_SERVICES,
      type: DominDataType.Industry
    },
    {
      key: 'Travel & Transportation',
      newKey: IndustryKey.Transportation,
      localizationKey: LOCALIZATION.TRANSPORTATION,
      type: DominDataType.Industry
    },
    {
      key: 'Wholesale & Distribution',
      newKey: IndustryKey.Distribution,
      localizationKey: LOCALIZATION.DISTRIBUTION,
      type: DominDataType.Industry
    },
    //Company size
    {
      key: '1to9employees',
      newKey: CompanySizeKey._1_to_9,
      localizationKey: LOCALIZATION._1_TO_9,
      type: DominDataType.CompanySize
    },
    {
      key: '10to50employees',
      newKey: CompanySizeKey._10_to_50,
      localizationKey: LOCALIZATION._10_TO_50,
      type: DominDataType.CompanySize
    },
    {
      key: '51to250employees',
      newKey: CompanySizeKey._51_to_250,
      localizationKey: LOCALIZATION._51_TO_250,
      type: DominDataType.CompanySize
    },
    {
      key: '251to1000employees',
      newKey: CompanySizeKey._251_to_1000,
      localizationKey: LOCALIZATION._251_TO_1000,
      type: DominDataType.CompanySize
    },
    {
      key: '1001to5000employees',
      newKey: CompanySizeKey._1001_to_5000,
      localizationKey: LOCALIZATION._1001_TO_5000,
      type: DominDataType.CompanySize
    },
    {
      key: '5001to10000employees',
      newKey: CompanySizeKey._5001_to_10000,
      localizationKey: LOCALIZATION._5001_TO_10000,
      type: DominDataType.CompanySize
    },
    {
      key: '10001to20000employees',
      newKey: CompanySizeKey._10001_to_20000,
      localizationKey: LOCALIZATION._10001_TO_20000,
      type: DominDataType.CompanySize
    },
    {
      key: 'Morethan20000employees',
      newKey: CompanySizeKey._more_than_20000,
      localizationKey: LOCALIZATION._MORE_THAN_20000,
      type: DominDataType.CompanySize
    },
    // Products
    {
      key: 'Azure',
      newKey: ProductKey.Azure,
      localizationKey: LOCALIZATION.AZURE,
      type: DominDataType.Product
    },
    {
      key: 'EnterpriseMobilityAndSecurity',
      newKey: ProductKey.EnterpriseMobilityAndSecurity,
      localizationKey: LOCALIZATION.ENTERPRISE_MOBILITY_AND_SECURITY,
      type: DominDataType.Product
    },
    {
      key: 'Exchange',
      newKey: ProductKey.Exchange,
      localizationKey: LOCALIZATION.EXCHANGE,
      type: DominDataType.Product
    },
    {
      key: 'DeveloperTools',
      newKey: ProductKey.DeveloperTools,
      localizationKey: LOCALIZATION.DEVELOPER_TOOLS,
      type: DominDataType.Product
    },
    {
      key: 'Dynamics365Business',
      newKey: ProductKey.Dynamics365Business,
      localizationKey: LOCALIZATION.DYNAMICS_365_BUSINESS,
      type: DominDataType.Product
    },
    {
      key: 'Dynamics365Enterprise',
      newKey: ProductKey.Dynamics365Enterprise,
      localizationKey: LOCALIZATION.DYNAMICS_365_ENTERPRISE,
      type: DominDataType.Product
    },
    {
      key: 'Microsoft365',
      newKey: ProductKey.Microsoft365,
      localizationKey: LOCALIZATION.MICROSOFT_365,
      type: DominDataType.Product
    },
    {
      key: 'Teams',
      newKey: ProductKey.Teams,
      localizationKey: LOCALIZATION.TEAMS,
      type: DominDataType.Product
    },
    {
      key: 'Office',
      newKey: ProductKey.Office,
      localizationKey: LOCALIZATION.OFFICE,
      type: DominDataType.Product
    },
    {
      key: 'PowerBI',
      newKey: ProductKey.PowerBI,
      localizationKey: LOCALIZATION.POWER_BI,
      type: DominDataType.Product
    },
    {
      key: 'Project',
      newKey: ProductKey.Project,
      localizationKey: LOCALIZATION.PROJECT,
      type: DominDataType.Product
    },
    {
      key: 'SharePoint',
      newKey: ProductKey.SharePoint,
      localizationKey: LOCALIZATION.SHARE_POINT,
      type: DominDataType.Product
    },
    {
      key: 'SkypeForBusiness',
      newKey: ProductKey.SkypeForBusiness,
      localizationKey: LOCALIZATION.SKYPE_FOR_BUSINESS,
      type: DominDataType.Product
    },
    {
      key: 'Visio',
      newKey: ProductKey.Visio,
      localizationKey: LOCALIZATION.VISIO,
      type: DominDataType.Product
    },
    {
      key: 'Windows',
      newKey: ProductKey.Windows,
      localizationKey: LOCALIZATION.WINDOWS,
      type: DominDataType.Product
    },
    {
      key: 'Yammer',
      newKey: ProductKey.Yammer,
      localizationKey: LOCALIZATION.YAMMER,
      type: DominDataType.Product
    },
    {
      key: 'SQL',
      newKey: ProductKey.SQL,
      localizationKey: LOCALIZATION.SQL,
      type: DominDataType.Product
    },
    {
      key: 'Surface',
      newKey: ProductKey.Surface,
      localizationKey: LOCALIZATION.SURFACE,
      type: DominDataType.Product
    },
    {
      key: 'SurfaceHub',
      newKey: ProductKey.SurfaceHub,
      localizationKey: LOCALIZATION.SURFACE_HUB,
      type: DominDataType.Product
    },
    {
      key: 'DynamicsAX,GP,NAV,SL',
      newKey: ProductKey.DynamicsAX_GP_NAV_SL,
      localizationKey: LOCALIZATION.DYNAMICSAX_GP_NAV_SL,
      type: DominDataType.Product,
      isRemoved: true
    },
    // Services
    {
      key: 'DeploymentOrMigration',
      newKey: ServiceKey.DeploymentMigration,
      localizationKey: LOCALIZATION.DEPLOYMENT_MIGRATION,
      type: DominDataType.Service,
      parent: ServiceParent.ProfessionalServices
    },
    {
      key: 'Licensing',
      newKey: ServiceKey.Licensing,
      localizationKey: LOCALIZATION.LICENSING,
      type: DominDataType.Service,
      parent: ServiceParent.Resellers
    },
    {
      key: 'CustomSolution(ISV)',
      newKey: ServiceKey.Customsolution,
      localizationKey: LOCALIZATION.CUSTOM_SOLUTION,
      type: DominDataType.Service,
      parent: ServiceParent.TechnologySolutions
    },
    {
      key: 'IPServices(ISV)',
      newKey: ServiceKey.IPServices,
      localizationKey: LOCALIZATION.IP_SERVICES,
      type: DominDataType.Service,
      parent: ServiceParent.TechnologySolutions
    },
    {
      key: 'ManagedServices',
      newKey: ServiceKey.ManagedServices,
      localizationKey: LOCALIZATION.MANAGED_SERVICES,
      type: DominDataType.Service,
      parent: ServiceParent.Resellers
    },
    {
      key: 'ProjectServices',
      newKey: ServiceKey.ProjectManagement,
      localizationKey: LOCALIZATION.PROJECT_MANAGEMENT,
      type: DominDataType.Service,
      parent: ServiceParent.ProfessionalServices
    },
    {
      key: 'LearningAndCertification',
      newKey: ServiceKey.LearningCertification,
      localizationKey: LOCALIZATION.LEARNING_CERTIFICATION,
      type: DominDataType.Service,
      parent: ServiceParent.ProfessionalServices
    },
    {
      key: 'ConsultingAndProfessional',
      newKey: ServiceKey.Consulting,
      localizationKey: LOCALIZATION.CONSULTING,
      type: DominDataType.Service,
      parent: ServiceParent.ProfessionalServices
    },
    {
      key: 'Hardware',
      newKey: ServiceKey.Hardware,
      localizationKey: LOCALIZATION.HARDWARE,
      type: DominDataType.Service,
      parent: ServiceParent.TechnologySolutions
    },
    {
      key: 'Integration',
      newKey: ServiceKey.Integration,
      localizationKey: LOCALIZATION.SYSTEM_INTEGRATION,
      type: DominDataType.Service,
      parent: ServiceParent.ProfessionalServices
    },
    // Services parent
    {
      key: ServiceParent.ProfessionalServices,
      newKey: ServiceParent.ProfessionalServices,
      localizationKey: LOCALIZATION.PROFESSIONAL_SERVICES_SERVICE_PARENT,
      type: DominDataType.ServiceParent
    },
    {
      key: ServiceParent.Resellers,
      newKey: ServiceParent.Resellers,
      localizationKey: LOCALIZATION.RESELLERS_SERVICES_SERVICE_PARENT,
      type: DominDataType.ServiceParent
    },
    {
      key: ServiceParent.TechnologySolutions,
      newKey: ServiceParent.TechnologySolutions,
      localizationKey: LOCALIZATION.TECH_SOLUTIONS_SERVICE_PARENT,
      type: DominDataType.ServiceParent
    },
    // Categories
    {
      key: 'Integration',
      newKey: CategoryKey.Integration,
      localizationKey: LOCALIZATION.INTEGRATION,
      type: DominDataType.Category,
      isRemoved: true
    },
    {
      key: 'Networking',
      newKey: CategoryKey.Networking,
      localizationKey: LOCALIZATION.NETWORKING,
      type: DominDataType.Category
    },
    {
      key: 'HybridStorage',
      newKey: CategoryKey.HybridStorage,
      localizationKey: LOCALIZATION.HYBRID_STORAGE,
      type: DominDataType.Category
    },
    {
      key: 'HighPerformanceComputing',
      newKey: CategoryKey.HighPerformanceComputing,
      localizationKey: LOCALIZATION.HIGH_PERFORMANCE_COMPUTING,
      type: DominDataType.Category
    },
    {
      key: 'CloudMigration',
      newKey: CategoryKey.CloudMigration,
      localizationKey: LOCALIZATION.CLOUD_MIGRATION,
      type: DominDataType.Category
    },
    {
      key: 'AzureStack',
      newKey: CategoryKey.AzureStack,
      localizationKey: LOCALIZATION.AZURESTACK,
      type: DominDataType.Category
    },
    {
      key: 'DevOps',
      newKey: CategoryKey.DevOps,
      localizationKey: LOCALIZATION.DEVOPS,
      type: DominDataType.Category,
      isRemoved: true
    },
    {
      key: 'RedhatonAzure',
      newKey: CategoryKey.RedhatonAzure,
      localizationKey: LOCALIZATION.REDHAT_ON_AZURE,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'InformationProtection',
      newKey: CategoryKey.InformationProtection,
      localizationKey: LOCALIZATION.INFORMATION_PROTECTION,
      type: DominDataType.Category
    },
    {
      key: 'AzureSecurityOperationManagement',
      newKey: CategoryKey.Security,
      localizationKey: LOCALIZATION.SECURITY,
      type: DominDataType.Category
    },
    {
      key: 'BackupDisasterRecovery',
      newKey: CategoryKey.BackupDisasterRecovery,
      localizationKey: LOCALIZATION.BACKUP_DISASTER_RECOVERY,
      type: DominDataType.Category
    },
    {
      key: 'ApplicationIntegration',
      newKey: CategoryKey.ApplicationIntegration,
      localizationKey: LOCALIZATION.APPLICATION_INTEGRATION,
      type: DominDataType.Category,
      isRemoved: true
    },
    {
      key: 'WebDevelopment',
      newKey: CategoryKey.WebDevelopment,
      localizationKey: LOCALIZATION.WEB_DEVELOPMENT,
      type: DominDataType.Category
    },
    {
      key: 'MobileApplications',
      newKey: CategoryKey.MobileApplications,
      localizationKey: LOCALIZATION.MOBILE_APPLICATIONS,
      type: DominDataType.Category
    },
    {
      key: 'Gaming',
      newKey: CategoryKey.Gaming,
      localizationKey: LOCALIZATION.GAMING,
      type: DominDataType.Category,
      isRemoved: true
    },
    {
      key: 'DigitalMedia',
      newKey: CategoryKey.Media,
      localizationKey: LOCALIZATION.MEDIA,
      type: DominDataType.Category
    },
    {
      key: 'Blockchain',
      newKey: CategoryKey.Blockchain,
      localizationKey: LOCALIZATION.BLOCKCHAIN,
      type: DominDataType.Category
    },
    {
      key: 'Containers',
      newKey: CategoryKey.Containers,
      localizationKey: 'Containers',
      type: DominDataType.Category
    },
    {
      key: 'ServerlessComputing',
      newKey: CategoryKey.ServerlessComputing,
      localizationKey: LOCALIZATION.SERVERLESS_COMPUTING,
      type: DominDataType.Category
    },
    {
      key: 'Microserviceapplications',
      newKey: CategoryKey.Microserviceapplications,
      localizationKey: LOCALIZATION.MICRO_SERVICE_APPS,
      type: DominDataType.Category
    },
    {
      key: 'CloudDatabaseMigration',
      newKey: CategoryKey.CloudDatabaseMigration,
      localizationKey: LOCALIZATION.CLOUD_DB_MIGRATION,
      type: DominDataType.Category
    },
    {
      key: 'SQLServerUpgrade',
      newKey: CategoryKey.SQLServerUpgrade,
      localizationKey: LOCALIZATION.SQL_SERVER_UPGRADE,
      type: DominDataType.Category,
      isRemoved: true
    },
    {
      key: 'NoSQLMigration',
      newKey: CategoryKey.NoSQLMigration,
      localizationKey: LOCALIZATION.NOSQL_MIGRATION,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'MySQLPostgresMigrationtoAzure',
      newKey: CategoryKey.MySQLPostgresMigrationtoAzure,
      localizationKey: LOCALIZATION.MYSQL_POSTGRES_MIGRATION_TO_AZURE,
      type: DominDataType.Category,
      isRemoved: true
    },
    {
      key: 'DatabaseonLinux',
      newKey: CategoryKey.DatabaseonLinux,
      localizationKey: LOCALIZATION.DATABASE_ON_LINUX,
      type: DominDataType.Category
    },
    {
      key: 'CompetitiveDatabaseMigration',
      newKey: CategoryKey.CompetitiveDatabaseMigration,
      localizationKey: LOCALIZATION.COMPETATIVE_DATABASE_MIGRATION,
      type: DominDataType.Category
    },
    {
      key: 'DataWarehouse',
      newKey: CategoryKey.DataWarehouse,
      localizationKey: LOCALIZATION.DATA_WAREHOUSE,
      type: DominDataType.Category
    },
    {
      key: 'BigData',
      newKey: CategoryKey.BigData,
      localizationKey: LOCALIZATION.BIG_DATA,
      type: DominDataType.Category
    },
    {
      key: 'AdvancedAnalytics',
      newKey: CategoryKey.Analytics,
      localizationKey: LOCALIZATION.ANALYTICS,
      type: DominDataType.Category
    },
    {
      key: 'ArtificialIntelligence',
      newKey: CategoryKey.ArtificialIntelligence,
      localizationKey: LOCALIZATION.ARTIFICIAL_INTELLIGENCE,
      type: DominDataType.Category
    },
    {
      key: 'MachineLearning',
      newKey: CategoryKey.AIMachineLearning,
      localizationKey: LOCALIZATION.AI_MACHINE_LEARNING,
      type: DominDataType.Category
    },
    {
      key: 'CognitiveServices',
      newKey: CategoryKey.CognitiveServices,
      localizationKey: LOCALIZATION.COGNITIVE_SERVICES,
      type: DominDataType.Category
    },
    {
      key: 'Chatbot',
      newKey: CategoryKey.Chatbot,
      localizationKey: LOCALIZATION.CHATBOT,
      type: DominDataType.Category
    },
    {
      key: 'EnterpriseBusinessIntelligence',
      newKey: CategoryKey.EnterpriseBusinessIntelligence,
      localizationKey: LOCALIZATION.ENTERPRISE_BUSINESS_INTELLIGENCE,
      type: DominDataType.Category
    },
    {
      key: 'InternetofThings',
      newKey: CategoryKey.InternetofThings,
      localizationKey: LOCALIZATION.INTERNET_OF_THINGS,
      type: DominDataType.Category
    },
    {
      key: 'DevelopmentandTest',
      newKey: CategoryKey.DeveloperTools,
      localizationKey: LOCALIZATION.DEVELOPER_TOOLS,
      type: DominDataType.Category
    },
    {
      key: 'ThreatProtection',
      newKey: CategoryKey.ThreatProtection,
      localizationKey: LOCALIZATION.THREAT_PROTECTION,
      type: DominDataType.Category
    },
    {
      key: 'IdentityandAccessManagement',
      newKey: CategoryKey.IdentityAccessMngmnt,
      localizationKey: LOCALIZATION.IDENTITY_ACCESS_MNGMNT,
      type: DominDataType.Category
    },
    {
      key: 'InformationManagement',
      newKey: CategoryKey.InformationManagement,
      localizationKey: LOCALIZATION.INFORMATION_MANAGEMENT,
      type: DominDataType.Category
    },
    {
      key: 'RegulatoryComplianceGDPR',
      newKey: CategoryKey.RegulatorycomplianceGDPR,
      localizationKey: LOCALIZATION.REGULATORY_COMPLIANCE_GDPR,
      type: DominDataType.Category
    },
    {
      key: 'CloudVoice',
      newKey: CategoryKey.CloudVoice,
      localizationKey: LOCALIZATION.CLOUD_VOICE,
      type: DominDataType.Category
    },
    {
      key: 'AzureExpert',
      newKey: CategoryKey.AzureExpertMSPs,
      localizationKey: LOCALIZATION.AZURE_EXPERT_MSPS,
      type: DominDataType.Category,
      isRemoved: true
    },
    {
      key: 'Surface',
      newKey: CategoryKey.Surface,
      localizationKey: undefined,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'Dynamics365forCustomerService',
      newKey: CategoryKey.Dynamics365forCustomerService,
      localizationKey: LOCALIZATION.DYNAMICS_365_CUSTOMER_SERVICE,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'Dynamics365forFieldService',
      newKey: CategoryKey.Dynamics365forFieldService,
      localizationKey: LOCALIZATION.DYNAMICS_365_FIELD_SERVICE,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'Dynamics365forFinanceOperations',
      newKey: CategoryKey.Dynamics365forFinance,
      localizationKey: LOCALIZATION.DYNAMICS_365_FINANCE,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'Dynamics365forRetail',
      newKey: CategoryKey.Dynamics365forRetails,
      localizationKey: LOCALIZATION.DYNAMICS_365_RETAIL,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'Dynamics365forTalent',
      newKey: CategoryKey.Dynamics365forTalent,
      localizationKey: LOCALIZATION.DYNAMICS_365_TALENT,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'DynamicsonAzure',
      newKey: CategoryKey.DynamicsonAzure,
      localizationKey: LOCALIZATION.DYNAMICS_ON_AZURE,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'SAPonAzure',
      newKey: CategoryKey.SAPonAzure,
      localizationKey: LOCALIZATION.SAP_ON_AZURE,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'SharepointOnAzure',
      newKey: CategoryKey.SharepointOnAzure,
      localizationKey: LOCALIZATION.SHARE_POINT_AZURE,
      isRemoved: true,
      type: DominDataType.Category
    },
    {
      key: 'Dynamics365forSales',
      newKey: CategoryKey.Dynamics365forSales,
      localizationKey: LOCALIZATION.DYNAMICS_365_SALES,
      isRemoved: true,
      type: DominDataType.Category
    },
    // Advanced specialization
    {
      key: 'SAPonAzure',
      newKey: AdvSpecKey.SAPOnAzure,
      localizationKey: LOCALIZATION.SAP_ON_AZURE,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'AdoptionChangeMgmt',
      newKey: AdvSpecKey.AdoptionChangeMgmt,
      localizationKey: LOCALIZATION.ADOPTION_CHANGE_MGMT,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.ModernWork
    },
    {
      key: 'ModernWebApp',
      newKey: AdvSpecKey.ModernWebApp,
      localizationKey: LOCALIZATION.MODERN_WEBAPP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'CallingTeams',
      newKey: AdvSpecKey.CallingTeams,
      localizationKey: LOCALIZATION.CALLING_TEAMS,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.ModernWork
    },
    {
      key: 'MeetingTeams',
      newKey: AdvSpecKey.MeetingTeams,
      localizationKey: LOCALIZATION.MEETING_TEAMS,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.ModernWork
    },
    {
      key: 'Kubernetes',
      newKey: AdvSpecKey.Kubernetes,
      localizationKey: LOCALIZATION.KUBERNETES,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'DWMigrate',
      newKey: AdvSpecKey.DWMigrate,
      localizationKey: LOCALIZATION.DWMIGRATE,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'InfraDBMigrate',
      newKey: AdvSpecKey.InfraDBMigrate,
      localizationKey: LOCALIZATION.INFRA_DB_MIGRATE,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'Sales',
      newKey: AdvSpecKey.Sales,
      localizationKey: LOCALIZATION.SALES_ASP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'Service',
      newKey: AdvSpecKey.Service,
      localizationKey: LOCALIZATION.SERVICE_ASP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'SupplyChain',
      newKey: AdvSpecKey.SupplyChain,
      localizationKey: LOCALIZATION.SUPPLY_CHAIN_ASP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'Finance',
      newKey: AdvSpecKey.Finance,
      localizationKey: LOCALIZATION.FINANCE_ASP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'PowerAutomate',
      newKey: AdvSpecKey.PowerAutomate,
      localizationKey: LOCALIZATION.INTELLIGENT_AUTOMATION,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'BuildAndAIApp',
      newKey: AdvSpecKey.BuildAndAIApp,
      localizationKey: LOCALIZATION.BUILD_AND_AI_APP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'PowerBI',
      newKey: AdvSpecKey.PowerBI,
      localizationKey: LOCALIZATION.BUSINESS_INTELLIGENCE,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'TeamworkDeployment',
      newKey: AdvSpecKey.TeamworkDeployment,
      localizationKey: LOCALIZATION.TEAMWORK_DEPLOYMENT,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.ModernWork
    },
    {
      key: 'WinVirtualDesktop',
      newKey: AdvSpecKey.WinVirtualDesktop,
      localizationKey: LOCALIZATION.WIN_VIRTUAL_DESKTOP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'IdAccessManagement',
      newKey: AdvSpecKey.IdAccessManagement,
      localizationKey: LOCALIZATION.ID_ACCESS_MANAGEMENT,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Security
    },
    {
      key: 'InformationProtection',
      newKey: AdvSpecKey.InformationProtection,
      localizationKey: LOCALIZATION.INFORMATION_PROTECTION,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Security
    },
    {
      key: 'LowCodeApp',
      newKey: AdvSpecKey.LowCodeApp,
      localizationKey: LOCALIZATION.LOW_CODE_APP,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'CustomTeamsSolution',
      newKey: AdvSpecKey.CustomTeamsSolution,
      localizationKey: LOCALIZATION.CUSTOM_TEAMS_SOLUTION,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.ModernWork
    },
    {
      key: 'AzureAnalytics',
      newKey: AdvSpecKey.AzureAnalytics,
      localizationKey: LOCALIZATION.AZURE_ANALYTICS,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'ThreatProtection',
      newKey: AdvSpecKey.ThreatProtection,
      localizationKey: LOCALIZATION.THREAT_PROTECTION,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Security
    },
    {
      key: 'SmallMidBiz',
      newKey: AdvSpecKey.SmallMidBiz,
      localizationKey: LOCALIZATION.SMALL_MID_BIZ,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.BusinessApplications
    },
    {
      key: 'AzureVMWare',
      newKey: AdvSpecKey.AzureVMWare,
      localizationKey: LOCALIZATION.AZUREVMWARE,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'AzureHybridCloudArc',
      newKey: AdvSpecKey.AzureHybridCloudArc,
      localizationKey: LOCALIZATION.AZUREHYBRIDCLOUDARC,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'AzureAI',
      newKey: AdvSpecKey.AzureAI,
      localizationKey: LOCALIZATION.AZUREAI,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'AzureHybridCloudStackHCI',
      newKey: AdvSpecKey.AzureHybridCloudStackHCI,
      localizationKey: LOCALIZATION.AZUREHYBRIDCLOUDSTACKHCI,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'AzureDevOps',
      newKey: AdvSpecKey.AzureDevOps,
      localizationKey: LOCALIZATION.AZUREDEVOPS,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'AzureNetworking',
      newKey: AdvSpecKey.AzureNetworking,
      localizationKey: LOCALIZATION.AZURENETWORKING,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Azure
    },
    {
      key: 'ModernizeEndpoints',
      newKey: AdvSpecKey.ModernizeEndpoints,
      localizationKey: LOCALIZATION.MODERNIZEENDPOINTS,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.ModernWork
    },
    {
      key: 'HybridCloudSecurity',
      newKey: AdvSpecKey.HybridCloudSecurity,
      localizationKey: LOCALIZATION.HYBRIDCLOUDSECURITY,
      type: DominDataType.AdvSpec,
      parent: AdvSpecParent.Security
    },
    // Diverse owned
    {
      key: DiverseOwnedLedKey.AfricanAmericanOrBlackOwned,
      newKey: DiverseOwnedLedKey.AfricanAmericanOrBlackOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_AFRICANAMERICANORBLACK,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.AsianOwned,
      newKey: DiverseOwnedLedKey.AsianOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_ASIAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.HispanicLatinoOwned,
      newKey: DiverseOwnedLedKey.HispanicLatinoOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_HISPANICLATINO,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.MiddleEasternNorthAfricanOwned,
      newKey: DiverseOwnedLedKey.MiddleEasternNorthAfricanOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_MIDDLEEASTERNNORTHAFRICAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.NativeHawaiianPacificIslanderOwned,
      newKey: DiverseOwnedLedKey.NativeHawaiianPacificIslanderOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_NATIVEHAWAIIANPACIFICISLANDER,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.NativeAmericanAlaskaNativeOwned,
      newKey: DiverseOwnedLedKey.NativeAmericanAlaskaNativeOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_NATIVEAMERICANALASKANATIVE,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.DisabilityOwned,
      newKey: DiverseOwnedLedKey.DisabilityOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_DISABILITY,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.LGBTQOwned,
      newKey: DiverseOwnedLedKey.LGBTQOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_LGBTQ,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.VeteranOwned,
      newKey: DiverseOwnedLedKey.VeteranOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_VETERAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.WomanOwned,
      newKey: DiverseOwnedLedKey.WomanOwned,
      localizationKey: LOCALIZATION.DIVERSITY_OWNED_WOMAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    // Diverse led
    {
      key: DiverseOwnedLedKey.AfricanAmericanOrBlackLed,
      newKey: DiverseOwnedLedKey.AfricanAmericanOrBlackLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_AFRICANAMERICANORBLACK,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.AsianLed,
      newKey: DiverseOwnedLedKey.AsianLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_ASIAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.HispanicLatinoLed,
      newKey: DiverseOwnedLedKey.HispanicLatinoLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_HISPANICLATINO,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.MiddleEasternNorthAfricanLed,
      newKey: DiverseOwnedLedKey.MiddleEasternNorthAfricanLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_MIDDLEEASTERNNORTHAFRICAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.NativeHawaiianPacificIslanderLed,
      newKey: DiverseOwnedLedKey.NativeHawaiianPacificIslanderLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_NATIVEHAWAIIANPACIFICISLANDER,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.NativeAmericanAlaskaNativeLed,
      newKey: DiverseOwnedLedKey.NativeAmericanAlaskaNativeLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_NATIVEAMERICANALASKANATIVE,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.DisabilityLed,
      newKey: DiverseOwnedLedKey.DisabilityLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_DISABILITY,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.LGBTQLed,
      newKey: DiverseOwnedLedKey.LGBTQLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_LGBTQ,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.VeteranLed,
      newKey: DiverseOwnedLedKey.VeteranLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_VETERAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    {
      key: DiverseOwnedLedKey.WomanLed,
      newKey: DiverseOwnedLedKey.WomanLed,
      localizationKey: LOCALIZATION.DIVERSITY_LED_WOMAN,
      type: DominDataType.DiverseOwnedLedKey
    },
    // Diverse Accreditation
    {
      key: DiverseThirdPartyAccreditationKey.DisabilityIN,
      newKey: DiverseThirdPartyAccreditationKey.DisabilityIN,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_DISABILITYIN,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.NationalLGBTChamberofCommerce,
      newKey: DiverseThirdPartyAccreditationKey.NationalLGBTChamberofCommerce,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_NATIONALLGBTCHAMBEROFCOMMERCE,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.NationalMinoritySupplierDevelopmentCouncil,
      newKey: DiverseThirdPartyAccreditationKey.NationalMinoritySupplierDevelopmentCouncil,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_NATIONALMINORITYSUPPLIERDEVELOPMENTCOUNCIL,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.NationalVeteranBusinessDevelopmentCouncil,
      newKey: DiverseThirdPartyAccreditationKey.NationalVeteranBusinessDevelopmentCouncil,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_NATIONALVETERANBUSINESSDEVELOPMENTCOUNCIL,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.USSBA8A,
      newKey: DiverseThirdPartyAccreditationKey.USSBA8A,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_USSBA8A,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.USSBAHUBZone,
      newKey: DiverseThirdPartyAccreditationKey.USSBAHUBZone,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_USSBAHUBZONE,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.USSBASmallDisadvantagedBusiness,
      newKey: DiverseThirdPartyAccreditationKey.USSBASmallDisadvantagedBusiness,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_USSBASMALLDISADVANTAGEDBUSINESS,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.USSBAWomanOwnedSmallBusiness,
      newKey: DiverseThirdPartyAccreditationKey.USSBAWomanOwnedSmallBusiness,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_USSBAWOMANOWNEDSMALLBUSINESS,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.USSBAServiceDisabledVeteranOwnedSmallBusiness,
      newKey: DiverseThirdPartyAccreditationKey.USSBAServiceDisabledVeteranOwnedSmallBusiness,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_USSBASERVICEDISABLEDVETERANOWNEDSMALLBUSINESS,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.WomensBusinessEnterpriseNationalCouncil,
      newKey: DiverseThirdPartyAccreditationKey.WomensBusinessEnterpriseNationalCouncil,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_WOMENSBUSINESSENTERPRISENATIONALCOUNCIL,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.OtherCertAgency,
      newKey: DiverseThirdPartyAccreditationKey.OtherCertAgency,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_OTHERCERTAGENCY,
      type: DominDataType.DiverseAccred
    },
    {
      key: DiverseThirdPartyAccreditationKey.OtherCertAgency2,
      newKey: DiverseThirdPartyAccreditationKey.OtherCertAgency2,
      localizationKey: LOCALIZATION.DIVERSITY_ACCRED_OTHERCERTAGENCY,
      type: DominDataType.DiverseAccred
    },
    // Designations
    {
      key: DesignationsKey.BusinessApplications,
      newKey: DesignationsKey.BusinessApplications,
      localizationKey: LOCALIZATION.DESIGNATIONS_BUSINESS_APP_KEY,
      type: DominDataType.Designations
    },
    {
      key: DesignationsKey.DataAndAI,
      newKey: DesignationsKey.DataAndAI,
      localizationKey: LOCALIZATION.DESIGNATIONS_DATA_AND_AI_KEY,
      type: DominDataType.Designations
    },
    {
      key: DesignationsKey.DigitalAndAppInnovation,
      newKey: DesignationsKey.DigitalAndAppInnovation,
      localizationKey: LOCALIZATION.DESIGNATIONS_DIGITAL_AND_APP_INNOVATION_KEY,
      type: DominDataType.Designations
    },
    {
      key: DesignationsKey.Infrastructure,
      newKey: DesignationsKey.Infrastructure,
      localizationKey: LOCALIZATION.DESIGNATIONS_INFRASTRUCTURE_KEY,
      type: DominDataType.Designations
    },
    {
      key: DesignationsKey.ModernWork,
      newKey: DesignationsKey.ModernWork,
      localizationKey: LOCALIZATION.DESIGNATIONS_MODERN_WORK_INNOVATION_KEY,
      type: DominDataType.Designations
    },
    {
      key: DesignationsKey.Security,
      newKey: DesignationsKey.Security,
      localizationKey: LOCALIZATION.DESIGNATIONS_SECURITY_INNOVATION_KEY,
      type: DominDataType.Designations
    },
    // Training designations
    {
      key: TrainingDesignationsKey.BusinessApplications,
      newKey: TrainingDesignationsKey.BusinessApplications,
      localizationKey: LOCALIZATION.DESIGNATIONS_BUSINESS_APP_KEY,
      type: DominDataType.TrainingDesignations
    },
    {
      key: TrainingDesignationsKey.DataAndAI,
      newKey: TrainingDesignationsKey.DataAndAI,
      localizationKey: LOCALIZATION.DESIGNATIONS_DATA_AND_AI_KEY,
      type: DominDataType.TrainingDesignations
    },
    {
      key: TrainingDesignationsKey.DigitalAndAppInnovation,
      newKey: TrainingDesignationsKey.DigitalAndAppInnovation,
      localizationKey: LOCALIZATION.DESIGNATIONS_DIGITAL_AND_APP_INNOVATION_KEY,
      type: DominDataType.TrainingDesignations
    },
    {
      key: TrainingDesignationsKey.Infrastructure,
      newKey: TrainingDesignationsKey.Infrastructure,
      localizationKey: LOCALIZATION.DESIGNATIONS_INFRASTRUCTURE_KEY,
      type: DominDataType.TrainingDesignations
    },
    {
      key: TrainingDesignationsKey.ModernWork,
      newKey: TrainingDesignationsKey.ModernWork,
      localizationKey: LOCALIZATION.DESIGNATIONS_MODERN_WORK_INNOVATION_KEY,
      type: DominDataType.TrainingDesignations
    },
    {
      key: TrainingDesignationsKey.Security,
      newKey: TrainingDesignationsKey.Security,
      localizationKey: LOCALIZATION.DESIGNATIONS_SECURITY_INNOVATION_KEY,
      type: DominDataType.TrainingDesignations
    },
    // Adv Spec Parent
    {
      key: AdvSpecParent.Azure,
      newKey: AdvSpecParent.Azure,
      localizationKey: LOCALIZATION.ADV_SPEC_PARENT_AZURE,
      type: DominDataType.AdvSpecParent
    },
    {
      key: AdvSpecParent.BusinessApplications,
      newKey: AdvSpecParent.BusinessApplications,
      localizationKey: LOCALIZATION.ADV_SPEC_PARENT_BUSINESS_APPS,
      type: DominDataType.AdvSpecParent
    },
    {
      key: AdvSpecParent.ModernWork,
      newKey: AdvSpecParent.ModernWork,
      localizationKey: LOCALIZATION.ADV_SPEC_PARENT_MODERN_WORK,
      type: DominDataType.AdvSpecParent
    },
    {
      key: AdvSpecParent.Security,
      newKey: AdvSpecParent.Security,
      localizationKey: LOCALIZATION.ADV_SPEC_PARENT_SECURITY,
      type: DominDataType.AdvSpecParent
    }
  ];
};

// Get all domain data objects from certain type.
// i.e. const products = getDomainData(DominDataType.Product);
const domainDataMap = new Map();
export function getDomainData(type: DominDataType): IDomainData[] {
  //Create domain data map for the first and last time.
  if (domainDataMap.size == 0) {
    DomainDataObj().forEach(item => {
      if (!domainDataMap.has(item.type)) {
        domainDataMap.set(item.type, []);
      }
      domainDataMap.get(item.type).push(item);
    });
  }
  return domainDataMap.get(type);
}

export function getDomainDataUniqueKeyParent(type: DominDataType, parent: string): IDomainData[] {
  const result = getDomainDataUniqueKey(type);
  return result.filter(domainDataObj => domainDataObj.parent === parent);
}

const domainDataUniqueNewKeyStrMap = new Map();
const domainDataUniqueNewKeyMap = new Map();

export function getDomainDataUniqueKey(type: DominDataType): IDomainData[] {
  //Create domain data map for the first and last time.
  if (domainDataUniqueNewKeyStrMap.size == 0) {
    DomainDataObj().forEach(item => {
      if (!domainDataUniqueNewKeyMap.has(item.type)) {
        domainDataUniqueNewKeyMap.set(item.type, []);
        domainDataUniqueNewKeyStrMap.set(item.type, new Set<string>());
      }
      if (!domainDataUniqueNewKeyStrMap.get(item.type).has(item.newKey)) {
        domainDataUniqueNewKeyMap.get(item.type).push(item);
      }
      domainDataUniqueNewKeyStrMap.get(item.type).add(item.newKey);
    });
  }
  return domainDataUniqueNewKeyMap.get(type);
}

const newKeyToLocalizationKeyMap = new Map<string, string>();
function getNewKeyToLocalizationKeyMap(): Map<string, string> {
  //Create domain data map for the first and last time.
  if (newKeyToLocalizationKeyMap.size == 0) {
    DomainDataObj().forEach(item => {
      if (!newKeyToLocalizationKeyMap.has(item.newKey)) {
        newKeyToLocalizationKeyMap.set(item.newKey, item.localizationKey);
      }
    });
  }
  return newKeyToLocalizationKeyMap;
}

const newKeyToDomainDataObjMap = new Map<string, IDomainData>();
function getNewKeyToDomainDataObjMap(): Map<string, IDomainData> {
  //Create domain data map for the first and last time.
  if (newKeyToDomainDataObjMap.size == 0) {
    DomainDataObj().forEach(item => {
      if (!newKeyToDomainDataObjMap.has(item.newKey)) {
        newKeyToDomainDataObjMap.set(item.newKey, item);
      }
    });
  }
  return newKeyToDomainDataObjMap;
}

const advSpecNewKeyToDomainDataObjMap = new Map<string, IDomainData>();
function getAdvSpecNewKeyToDomainDataObjMap(): Map<string, IDomainData> {
  //Create domain data map for the first and last time.
  if (advSpecNewKeyToDomainDataObjMap.size == 0) {
    DomainDataObj()
      .filter(item => item.type === DominDataType.AdvSpec)
      .forEach(item => {
        if (!advSpecNewKeyToDomainDataObjMap.has(item.newKey)) {
          advSpecNewKeyToDomainDataObjMap.set(item.newKey, item);
        }
      });
  }
  return advSpecNewKeyToDomainDataObjMap;
}

export function getLocalizationKey(newKey: string): string {
  const newKeyToLocalizationKeyMap = getNewKeyToLocalizationKeyMap();
  return newKeyToLocalizationKeyMap && newKeyToLocalizationKeyMap.has(newKey) ? newKeyToLocalizationKeyMap.get(newKey) : null;
}

export function getDomainDataObject(newKey: string): IDomainData {
  const newKeyToDomainDataObjMap = getNewKeyToDomainDataObjMap();
  return newKeyToDomainDataObjMap && newKeyToDomainDataObjMap.has(newKey) ? newKeyToDomainDataObjMap.get(newKey) : null;
}

export function getAdvSpecDomainDataObject(newKey: string): IDomainData {
  const newKeyToDomainDataObjMap = getAdvSpecNewKeyToDomainDataObjMap();
  return newKeyToDomainDataObjMap && newKeyToDomainDataObjMap.has(newKey) ? newKeyToDomainDataObjMap.get(newKey) : null;
}
