import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../shared/models/commonModels';
import { IPartnersDetailsStore } from '../shared/models/partnerDetailsModel';
import { IPartnerDetailsResponse } from '../shared/models/Response/partnerDetails';

const initialState: IPartnersDetailsStore = {
  data: {},
  error: '',
  status: Status.Uninitialized
};

export interface IPartnerRequestPayload {
  id: number;
}

const partnersDetailsSlice = createSlice({
  name: 'partnersDetails',
  initialState,
  reducers: {
    fetchPartnerDetailsAsync: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.status = Status.InProgress;
      return state;
    },
    setPartner: (state, { payload }: PayloadAction<{ data: IPartnerDetailsResponse }>) => {
      if (payload.data && payload.data.partnerDetails.universalAccountId) {
        state.data[payload.data.partnerDetails.universalAccountId] = payload.data;
      }
    },
    onSuccess: (state, { payload }: PayloadAction<string>) => {
      state.error = '';
      state.status = Status.Done;
    },
    onError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.status = Status.Failed;
    }
  }
});

export const { onSuccess, onError, setPartner, fetchPartnerDetailsAsync } = partnersDetailsSlice.actions;

export const getPartnerDetails = (partnersDetailsStore: IPartnersDetailsStore, id: string) => partnersDetailsStore.data[id];

export const getPartner = createSelector([getPartnerDetails], getPartnerDetails => getPartnerDetails);

export default partnersDetailsSlice.reducer;
