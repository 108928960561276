import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { runningInIframe } from '../../utils/iframeUtils';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete, ISuggestionItem } from '../common/autoComplete';
import { AutoCompleteSuggestionType, IAutoCompleteResponse, IAutoCompleteSuggestion } from '../../shared/models/Response/searchModels';
import { searchAutocComplete } from '../../sagas/apis';
import * as Logger from '../../services/loggerService';

const MINIMUM_SEARCH_QUERY_LENGTH = 2;
const LOGGER_PREFIX = 'PartnerSearchBox:';

export interface AutoCompleteOption {
  key: string;
  text: string;
}

export default function PartnerSearchBox() {
  const { locale } = useParams<{ locale: string }>();
  const history = useHistory();
  const isIframe: boolean = runningInIframe();
  const [autoCompleteResponse, setAutoCompleteResponse] = useState<IAutoCompleteResponse>(null);

  const onSearchSelect = (suggestion: ISuggestionItem): void => {
    const suggestionTag: IAutoCompleteSuggestion = suggestion.tag as IAutoCompleteSuggestion;
    if (isIframe) {
      // this component used only for standalone mode - do nothing.
    } else {
      const encodedVal = encodeURIComponent(suggestionTag.val);
      const encodedText = encodeURIComponent(suggestionTag.text);
      const encodedType = encodeURIComponent(suggestionTag.type.toString());
      const pathName = `/${locale}/partners/suggestion?value=${encodedVal}&text=${encodedText}&type=${encodedType}`;
      history.push(pathName);
    }
  };

  const onSearchText = async (searchQuery: string): Promise<void> => {
    if (isIframe) {
      // this component used only for standalone mode - do nothing.
    } else {
      const encodedVal = encodeURIComponent(searchQuery);
      const encodedText = encodeURIComponent(searchQuery);
      const encodedType = encodeURIComponent(AutoCompleteSuggestionType.FreeText.toString());
      const pathName = `/${locale}/partners/suggestion?value=${encodedVal}&text=${encodedText}&type=${encodedType}`;
      history.push(pathName);
    }
  };

  const onSearchQueryChange = async (searchQuery: string): Promise<void> => {
    setAutoCompleteResponse(null);
    if (searchQuery && searchQuery.length >= MINIMUM_SEARCH_QUERY_LENGTH) {
      try {
        const autoCompleteResponse = await searchAutocComplete(searchQuery, locale);
        setAutoCompleteResponse(autoCompleteResponse);
      } catch (error) {
        Logger.Exception(LOGGER_PREFIX, error ? error.toString() : '', 'Exception during searchAutocComplete call');
      }
    }
  };

  const suggestions: ISuggestionItem[] = [];
  if (autoCompleteResponse && autoCompleteResponse.suggestions && autoCompleteResponse.suggestions.length) {
    for (let i = 0; i < autoCompleteResponse.suggestions.length; i++) {
      const suggestion = autoCompleteResponse.suggestions[i];
      suggestions.push({
        key: i,
        displayValue: suggestion.text,
        searchValue: suggestion.text,
        tag: suggestion
      });
    }
  }

  return (
    <div>
      <Stack>
        <Autocomplete
          items={suggestions}
          searchTitle=""
          suggestionCallback={onSearchSelect}
          searchCallback={onSearchText}
          searchQueryChangeCallback={onSearchQueryChange}
        />
      </Stack>
    </div>
  );
}
