import React from 'react';
import { ITabsProps, Tab, TabChild } from './tabs';
import Tabs from './tabs';

const TOP_OFFSET = 43;
const MIN_VISIBLE_HEIGHT = 450;

export interface IStickyCard {
  title: string;
  logoURL: string;
  tabsProps: ITabsProps;
  tabsChilds: TabChild[];
  children: any;
  top: number;
}

export function isStickyCardVisible(appearUnderElementId: string, scrollYOffset: number, windowHeight: number): boolean {
  const elementToAppearAfter = document.getElementById(appearUnderElementId);
  if (elementToAppearAfter && elementToAppearAfter.offsetTop) {
    const tabsYOffset = elementToAppearAfter.offsetTop + TOP_OFFSET;
    if (scrollYOffset > tabsYOffset && windowHeight >= MIN_VISIBLE_HEIGHT) {
      return true;
    }
  }
  return false;
}

export default function StickyCard(props: IStickyCard) {
  return (
    <div className="stickycard" style={{ top: props.top }}>
      <div className="stickycard-container">
        <div className="stickycard-sub-container">
          <div className="stickycard-left">
            <div className="stickcard-publisher">
              <div className="stickycard-icon">
                <div>
                  <img
                    className="sticky-icon-img"
                    src={props.logoURL}
                    alt={props.title + ' icon'}
                    onError={e =>
                      e && e.target && (e.target as HTMLElement).style ? ((e.target as HTMLElement).style.display = 'none') : null
                    }
                  />
                </div>
              </div>
              <div className="stickycard-title">
                <div>{props.title}</div>
              </div>
            </div>
            <div className="stickycard-tabs">
              <Tabs defaultTab={props.tabsProps.defaultTab}>
                {props.tabsChilds.map(tabChild => (
                  <Tab
                    title={tabChild.title}
                    key={'stickyCardTab' + tabChild.name}
                    name={tabChild.name}
                    href={tabChild.href}
                    id={tabChild.id}
                  >
                    <div></div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
        <div>
          <div className="stickycard-right">
            <div className="stickycard-sub-container">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
