import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../redux';
import * as PartnerActions from '../../redux/partnersSlice';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Trans, useTranslation } from 'react-i18next';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import { runningInIframe } from '../../utils/iframeUtils';
import { OVERVIEW_TAB } from '../partnerDetails/partnerDetails';
import { PartnersFilter, PartnersResultsSort, RESULTS_PAGE_SIZE } from '../../shared/models/Request/partnersFilter';
import Gallery, { IFilterTag } from './gallery';
import {
  FilterLocation,
  getFilterLocation,
  getUserLocation,
  IFilterLocation,
  IUserLocation,
  setFilterLocation,
  UserLocation
} from '../../redux/locationSlice';
import { getAdvSpecDomainDataObject, getLocalizationKey } from '../../shared/domainDataObj';
import { Status } from '../../shared/models/commonModels';
import Filter, { AZURE_MSP_FILTER_KEY, DIVERSE_BUSINESS_ACCREDIATION_FILTER_KEY, DIVERSE_BUSINESS_OWNED_FILTER_KEY } from './filter';
import {
  AZURE_EXPERT_MSP,
  BROWSE_SERVICE_PARTNERS,
  DESIGNATION_IN_SOLUTION,
  DESIGNATION_IN_TRAINING,
  DIVERSE_ACCREDIATION,
  DIVERSE_OWNED,
  FILTER_SORT_REFINE_RESULTS,
  NO_LOCATION_SEARCH_ALERT_ACTION,
  NO_LOCATION_SEARCH_ALERT_MESSAGE,
  SHOWING_SEARCH_RESULT_FOR,
  SORT_BY_BEST_MATCH,
  SORT_BY_MOST_RESPONSIVE,
  SORT_BY_NEAREST_TO_YOU
} from '../../constants/localization';
import { FontIcon, mergeStyles, Link } from '@fluentui/react';
import SelectLocationDialog from '../common/selectLocationDialog';
import { getFilterDistanceOptionByMilesRadius, getFilterDistanceOptionsMap } from '../../utils/distanceUtils';
import {
  IChangeUrlGalleryMessage,
  IChangeUrlDetailsMessage,
  PartnersHostMessageType,
  IStoreFilterLocationMessage
} from '../../protocol/partnersHostInboundMessages';
import { FilterLocationService } from '../../services/filterLocationService';
import { getContext, getContextInit } from '../../redux/hostContextSlice';
import { IViewContext } from '../../protocol/partnersViewInboundMessages';
import {
  ANALYTICS_PARTNER_CENTER_ACTIONS,
  ANALYTICS_USER_ACTIONS,
  PARTNER_CENTER_ACTIONS_PROPS_NAMES,
  trackPartnerCenterAction,
  trackUserAction
} from '../../services/analyticsService';
import { isRtl } from '../../utils/rtlUtils';
import PartnerSearchBox from '../partnerSearch/partnerSearchBox';
import { getCountryByName, isDiversityAndInclusionSupported } from '../../utils/countryStatesUtils';
import { IPartner } from '../../shared/models/partnerModel';
import { SYSTEM_KEY_CODES } from '../../constants/keyboardNavigation';
import { GetEnvConfiguration } from '../../services/configurationService';

export enum FilterType {
  Group,
  Location,
  OrgSize,
  AzureMSP,
  AdvSpec,
  Product,
  Industry,
  Category,
  Service,
  DiverseOwn,
  DiverseAccred,
  Designation,
  TrainingDesignation,
  FreeText,
  SubGroup,
  EndorsedProduct,
  EndorsedWorkload
}

const sortOptionsNames: { [key: number]: string } = {
  0: SORT_BY_BEST_MATCH,
  1: SORT_BY_MOST_RESPONSIVE,
  2: SORT_BY_NEAREST_TO_YOU
};

export default function PartnersGallery() {
  const dispatch = useDispatch();
  const appInsights = useAppInsightsContext();
  const urlLocation = useLocation();
  const { locale } = useParams<{ locale: string }>();
  const partners = useSelector((store: RootState) => PartnerActions.getPartners(store.partners));
  const lastResultsFilter = useSelector((store: RootState) => PartnerActions.getResponseFilter(store.partners));
  const estimatedTotalMatchingPartners = useSelector((store: RootState) => PartnerActions.getEstimatedTotalMatchPartners(store.partners));
  const fetchPartnersStatus: Status = useSelector((store: RootState) => PartnerActions.getFetchPartnersStatus(store.partners));
  const history = useHistory();
  const userLocation: IUserLocation = useSelector((store: RootState) => getUserLocation(store.location));
  const lastFilterLocation: IFilterLocation = useSelector((store: RootState) => getFilterLocation(store.location));
  const viewContext: IViewContext = useSelector((store: RootState) => getContext(store.hostContext));
  const viewContextInit: boolean = useSelector((store: RootState) => getContextInit(store.hostContext));
  const { t: translate } = useTranslation();
  const [selectLocationDialogOpen, setSelectLocationDialogOpen] = useState(false);
  const [filter, setFilter] = useState(new PartnersFilter());
  const [contactPartner, setContactPartner] = useState('');
  const envConfig = GetEnvConfiguration();

  const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
    margin: '0px 6px',
    verticalAlign: 'middle'
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(urlLocation.search);
    // We have filter in the url.
    handleUrlFilter(searchParams.get('filter'));
    handleUrlContact(searchParams.get('contact'));
  }, [urlLocation.pathname + urlLocation.search, userLocation, viewContext?.user?.country]);

  const handleUrlFilter = (filterString: string) => {
    if (filterString) {
      const filterFromUrl = PartnersFilter.createFromPrettyQueryParam(filterString);
      filterFromUrl.synthesizeFilter(true);
      const filterLocationService = new FilterLocationService(filterFromUrl, userLocation, lastFilterLocation, viewContext);
      if (filterLocationService.shouldUpdateFilterLocationFromFilter()) {
        updateParentIframeFilterLocation(filterFromUrl);
      }
      const locationUpdated = filterLocationService.updateFilterLocationIfNeeded();
      let diverseUpdated = false;
      if ((filterFromUrl.diverseOwned || filterFromUrl.diverseAccrediation) && !showDiversityFilter(filterFromUrl)) {
        filterFromUrl.diverseOwned = false;
        filterFromUrl.diverseAccrediation = false;
        diverseUpdated = true;
      }
      if (locationUpdated || diverseUpdated) {
        updateFilterUrl(filterFromUrl, true);
      } else {
        // Update filter state and call filter partners in the backend.
        setFilter(filterFromUrl);
        // Last filter different from current filter - get partners from backend.
        if (!filterFromUrl.isEquals(lastResultsFilter)) {
          getFilteredPartners(filterFromUrl);
        }
      }
    }
    // No filter in url.
    else {
      const updatedFilter = PartnersFilter.cloneFilter(filter);
      const filterLocationService = new FilterLocationService(updatedFilter, userLocation, lastFilterLocation, viewContext);
      // Update filter url with the updated filter params.
      const updated = filterLocationService.updateFilterLocationIfNeeded();
      if (showDiversityFilter(updatedFilter)) {
        updatedFilter.diverseOwned = null;
        updatedFilter.diverseAccrediation = null;
      }
      if (updated) {
        updateFilterUrl(updatedFilter, true);
      } else {
        updatedFilter.locationNotRequiredMode = true;
        setFilter(updatedFilter);
        getFilteredPartners(updatedFilter);
      }
    }
  };

  const updateParentIframeFilterLocation = (filterFromUrl: PartnersFilter) => {
    if (runningInIframe()) {
      const outboundMessagesHandler = new OutboundMessagesHandler();
      const storeFilterLocation: IStoreFilterLocationMessage = {
        messageType: PartnersHostMessageType.StoreFilterLocation,
        filterLocation: {
          countryCode: filterFromUrl.countryCode,
          locationLatitude: filterFromUrl.locationLatitude,
          locationLongtitude: filterFromUrl.locationLongtitude,
          locationName: filterFromUrl.locationName,
          onlyThisCountryResults: filterFromUrl.onlyThisCountryResults,
          radiusMiles: filterFromUrl.radiusMiles
        },
        diversityAndInclusionEnabled: showDiversityFilter(filterFromUrl)
      };
      outboundMessagesHandler.postStoreFilterLocationMessage(storeFilterLocation);
    }
  };

  const handleUrlContact = (contact: string) => {
    setContactPartner(contact);
  };

  const onPartnerClick = (partner: IPartner) => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_TILE_CLICK, properties: { partnerId: partner.partnerId } });
    reportPartnerCenterSearchDetailsClick(partner);

    // Running in iframe - use extenal website for routing.
    if (runningInIframe()) {
      const outboundMessagesHandler = new OutboundMessagesHandler();
      const changeUrlMessage: IChangeUrlDetailsMessage = {
        messageType: PartnersHostMessageType.ChangeUrlDetails,
        partnerId: partner.partnerId,
        tab: OVERVIEW_TAB,
        contact: false,
        replaceUrl: false
      };
      outboundMessagesHandler.postChangeUrlDetailsMessage(changeUrlMessage);
      // standalone mode - use locale routing.
    } else {
      const pathName = `/${locale}/partners/${partner.partnerId}/${OVERVIEW_TAB}`;
      history.push(pathName);
    }
  };

  const reportPartnerCenterSearchDetailsClick = (partner: IPartner) => {
    const partnerCenterActionProps = {};
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.EVENT_NAME] =
      ANALYTICS_PARTNER_CENTER_ACTIONS.SEARCH_PAGE_PARTNER_DETAILS_LINK;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_ID] = partner.partnerId;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_LISTING_ID] = partner.id;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_NAME] = partner.name;
    trackPartnerCenterAction({
      name: ANALYTICS_PARTNER_CENTER_ACTIONS.SEARCH_PAGE_PARTNER_DETAILS_LINK,
      properties: partnerCenterActionProps
    });
  };

  const getFilterTags = (): IFilterTag[] => {
    const filetrTags: IFilterTag[] = [];
    if (filter.orgSize) {
      filetrTags.push({ filterType: FilterType.OrgSize, key: filter.orgSize, text: translate(getLocalizationKey(filter.orgSize)) });
    }
    if (filter.freeText) {
      filetrTags.push({ filterType: FilterType.FreeText, key: filter.freeText, text: filter.freeText });
    }
    if (filter.azureMSP) {
      filetrTags.push({ filterType: FilterType.AzureMSP, key: AZURE_MSP_FILTER_KEY, text: translate(AZURE_EXPERT_MSP) });
    }
    if (filter.advSpecialization && filter.advSpecialization.length > 0) {
      filter.advSpecialization.map(itemKey => {
        const advSpecItem = getAdvSpecDomainDataObject(itemKey);
        filetrTags.push({ filterType: FilterType.AdvSpec, key: itemKey, text: translate(advSpecItem.localizationKey) });
      });
    }
    if (filter.products && filter.products.length > 0) {
      filter.products.map(itemKey => {
        filetrTags.push({ filterType: FilterType.Product, key: itemKey, text: translate(getLocalizationKey(itemKey)) });
      });
    }
    if (filter.industries && filter.industries.length > 0) {
      filter.industries.map(itemKey => {
        filetrTags.push({ filterType: FilterType.Industry, key: itemKey, text: translate(getLocalizationKey(itemKey)) });
      });
    }
    if (filter.categories && filter.categories.length > 0) {
      filter.categories.map(itemKey => {
        filetrTags.push({ filterType: FilterType.Category, key: itemKey, text: translate(getLocalizationKey(itemKey)) });
      });
    }
    if (filter.services && filter.services.length > 0) {
      filter.services.map(itemKey => {
        filetrTags.push({ filterType: FilterType.Service, key: itemKey, text: translate(getLocalizationKey(itemKey)) });
      });
    }
    if (filter.diverseOwned) {
      filetrTags.push({
        filterType: FilterType.DiverseOwn,
        key: DIVERSE_BUSINESS_OWNED_FILTER_KEY,
        text: translate(DIVERSE_OWNED)
      });
    }
    if (filter.diverseAccrediation) {
      filetrTags.push({
        filterType: FilterType.DiverseAccred,
        key: DIVERSE_BUSINESS_ACCREDIATION_FILTER_KEY,
        text: translate(DIVERSE_ACCREDIATION)
      });
    }
    if (filter.designations?.length > 0) {
      filter.designations.map(itemKey => {
        filetrTags.push({
          filterType: FilterType.Designation,
          key: itemKey,
          text: translate(DESIGNATION_IN_SOLUTION, { designation: translate(getLocalizationKey(itemKey)) })
        });
      });
    }
    if (filter.trainingDesignations?.length > 0) {
      filter.trainingDesignations.map(itemKey => {
        filetrTags.push({
          filterType: FilterType.TrainingDesignation,
          key: itemKey,
          text: translate(DESIGNATION_IN_TRAINING, { designation: translate(getLocalizationKey(itemKey)) })
        });
      });
    }
    if (filter.endorsedProduct) {
      filetrTags.push({
        filterType: FilterType.EndorsedProduct,
        key: filter.endorsedProduct,
        text: filter.endorsedProduct
      });
    }
    if (filter.endorsedWorkloads?.length > 0) {
      filter.endorsedWorkloads.map(itemKey => {
        filetrTags.push({
          filterType: FilterType.EndorsedWorkload,
          key: itemKey,
          text: itemKey
        });
      });
    }
    return filetrTags;
  };

  const onRemoveFilterTagClick = (tag: IFilterTag) => {
    trackUserAction({
      name: ANALYTICS_USER_ACTIONS.GALLERY_FILTER_REMOVE_TAG,
      properties: { filterType: tag.filterType, filterKey: tag.key }
    });
    updateFilter(tag.filterType, tag.key);
  };

  const onPageClick = (page: number) => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_CHANGE_PAGE, properties: { page } });
    const updatedFilter = PartnersFilter.cloneFilter(filter);
    updatedFilter.resultPageOffset = RESULTS_PAGE_SIZE * page;
    updateFilterUrl(updatedFilter);
  };

  const getFilteredPartners = (filter: PartnersFilter) => {
    // Filter partner only if location is set.
    if (filter.hasLocationFilter() || filter.locationNotRequiredMode) {
      dispatch(PartnerActions.fetchPartnersAsync({ filter: filter }));
    }
  };

  const updateFilterUrl = (updatedFilter: PartnersFilter, replaceUrl?: boolean) => {
    if (updatedFilter.isEquals(filter)) {
      return;
    }
    const filterQueryParam = updatedFilter.getPrettyQueryParam();
    const pathName = `/${locale}/partners?filter=${filterQueryParam}`;
    // Running in iframe - use extenal website for routing.
    if (runningInIframe()) {
      const outboundMessagesHandler = new OutboundMessagesHandler();
      const chnageUrlMessage: IChangeUrlGalleryMessage = {
        messageType: PartnersHostMessageType.ChangeUrlGallery,
        galleryFilter: filterQueryParam,
        contact: undefined, // If filter was triggered by the yser - contact state should be reset.
        replaceUrl: replaceUrl
      };
      outboundMessagesHandler.postChangeUrlGalleryMessage(chnageUrlMessage);
      // standalone mode - use local routing.
    } else {
      if (replaceUrl) {
        history.replace(pathName);
      } else {
        history.push(pathName);
      }
    }
  };

  const onFilterCheckChange = (filterType: FilterType, filterKey: string) => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_FILTER, properties: { filterType, filterKey } });
    updateFilter(filterType, filterKey);
  };

  const updateFilter = (filterType: FilterType, filterKey: string) => {
    const updatedFilter = PartnersFilter.cloneFilter(filter);
    let filterChanged = true;
    if (filterType === FilterType.OrgSize) {
      if (filterKey === filter.orgSize) {
        updatedFilter.orgSize = '';
      } else {
        updatedFilter.orgSize = filterKey;
      }
    } else if (filterType === FilterType.FreeText) {
      updatedFilter.freeText = '';
    } else if (filterType === FilterType.AzureMSP) {
      updatedFilter.azureMSP = !filter.azureMSP;
    } else if (filterType === FilterType.AdvSpec) {
      //Multi selection for advances specialization.
      const advSpecialNew = filter.advSpecialization ? [...filter.advSpecialization] : [];
      const itemIndex = advSpecialNew.indexOf(filterKey);
      if (itemIndex === -1) {
        advSpecialNew.push(filterKey);
      } else {
        advSpecialNew.splice(itemIndex, 1);
      }
      updatedFilter.advSpecialization = advSpecialNew;
    } else if (filterType === FilterType.Industry) {
      //Single selection for industrey.
      if (filter.industries?.length === 1 && filter.industries[0] === filterKey) {
        updatedFilter.industries = [];
      } else {
        updatedFilter.industries = [filterKey];
      }
    } else if (filterType === FilterType.Product) {
      //Multi selection for products.
      const productsNew = filter.products ? [...filter.products] : [];
      const itemIndex = productsNew.indexOf(filterKey);
      if (itemIndex === -1) {
        productsNew.push(filterKey);
      } else {
        productsNew.splice(itemIndex, 1);
      }
      updatedFilter.products = productsNew;
    } else if (filterType === FilterType.Category) {
      //Multi selection for categories.
      const categoryNew = filter.categories ? [...filter.categories] : [];
      const itemIndex = categoryNew.indexOf(filterKey);
      if (itemIndex === -1) {
        categoryNew.push(filterKey);
      } else {
        categoryNew.splice(itemIndex, 1);
      }
      updatedFilter.categories = categoryNew;
    } else if (filterType === FilterType.Service) {
      //Multi selection for services.
      const servicesNew = filter.services ? [...filter.services] : [];
      const itemIndex = servicesNew.indexOf(filterKey);
      if (itemIndex === -1) {
        servicesNew.push(filterKey);
      } else {
        servicesNew.splice(itemIndex, 1);
      }
      updatedFilter.services = servicesNew;
    } else if (filterType === FilterType.DiverseOwn) {
      updatedFilter.diverseOwned = !filter.diverseOwned;
    } else if (filterType === FilterType.DiverseAccred) {
      updatedFilter.diverseAccrediation = !filter.diverseAccrediation;
    } else if (filterType === FilterType.Designation) {
      const designationNew = filter.designations ? [...filter.designations] : [];
      const itemIndex = designationNew.indexOf(filterKey);
      if (itemIndex === -1) {
        designationNew.push(filterKey);
      } else {
        designationNew.splice(itemIndex, 1);
      }
      updatedFilter.designations = designationNew;
    } else if (filterType === FilterType.EndorsedWorkload) {
      const workloadsNew = filter.endorsedWorkloads ? [...filter.endorsedWorkloads] : [];
      const itemIndex = workloadsNew.indexOf(filterKey);
      if (itemIndex === -1) {
        workloadsNew.push(filterKey);
      } else {
        workloadsNew.splice(itemIndex, 1);
      }
      updatedFilter.endorsedWorkloads = workloadsNew;
    } else if (filterType === FilterType.EndorsedProduct) {
      updatedFilter.endorsedProduct = undefined;
    } else if (filterType === FilterType.TrainingDesignation) {
      const trainingDesignationNew = filter.trainingDesignations ? [...filter.trainingDesignations] : [];
      const itemIndex = trainingDesignationNew.indexOf(filterKey);
      if (itemIndex === -1) {
        trainingDesignationNew.push(filterKey);
      } else {
        trainingDesignationNew.splice(itemIndex, 1);
      }
      updatedFilter.trainingDesignations = trainingDesignationNew;
    } else {
      filterChanged = false;
    }
    if (!updatedFilter.endorsedProduct && updatedFilter.endorsedWorkloads?.length > 0) {
      updatedFilter.endorsedWorkloads = [];
      filterChanged = true;
    }
    if (filterChanged) {
      // Filter updated we are no longer in search suggestion mode.
      updatedFilter.searchSuggestionMode = false;
      updatedFilter.resultPageOffset = 0;
      updateFilterUrl(updatedFilter);
    }
  };

  const onFilterClearAll = () => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_FILTER_CLEAR_ALL });
    filterClearAll();
  };

  const onGalleryFilterClearAll = () => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_EMPTY_CLEAR_ALL });
    filterClearAll();
  };

  const filterClearAll = () => {
    const updatedFilter = PartnersFilter.cloneFilter(filter);
    updatedFilter.orgSize = '';
    updatedFilter.freeText = '';
    updatedFilter.azureMSP = false;
    updatedFilter.advSpecialization = [];
    updatedFilter.products = [];
    updatedFilter.industries = [];
    updatedFilter.categories = [];
    updatedFilter.services = [];
    updatedFilter.diverseOwned = false;
    updatedFilter.diverseAccrediation = false;
    updatedFilter.resultPageOffset = 0;
    updatedFilter.designations = [];
    updatedFilter.trainingDesignations = [];
    // Filter updated we are no longer in search suggestion mode.
    updatedFilter.searchSuggestionMode = false;
    updateFilterUrl(updatedFilter);
  };

  const onFilterSetLocationClick = () => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_FILTER_EDIT_LOCATION_CLICK });
    setSelectLocationDialogOpen(true);
  };

  const onGallerySetLocationClick = () => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_EMPTY_EDIT_LOCATION_CLICK });
    setSelectLocationDialogOpen(true);
  };

  const showDiversityFilter = (filter: PartnersFilter): boolean => {
    return envConfig.DIVERSE_INCLUSION_ENABLED && filter && isDiversityAndInclusionSupported(filter.countryCode);
  };

  const renderGalleryFilter = (partnersFilter: PartnersFilter) => {
    return (
      <Filter
        key="gallery_filter"
        filter={partnersFilter}
        showDiverseFilters={showDiversityFilter(filter)}
        onFilterCheckChange={onFilterCheckChange}
        onClearAll={onFilterClearAll}
        onSelectLocationClick={onFilterSetLocationClick}
      />
    );
  };

  const renderGalleryHeader = () => {
    const searchResultFilter = filter && filter.searchSuggestionMode;
    const tags = getFilterTags();
    const displaySearchResultHeader = searchResultFilter && tags.length == 1;
    return (
      <div className="gallery-header-box">
        {displaySearchResultHeader ? (
          <div className="gallery-header-search-result-box">
            <p className="gallery-header-main-title">
              <Trans i18nKey={SHOWING_SEARCH_RESULT_FOR} tOptions={{ searchTerm: tags[0].text }}></Trans>
            </p>
            {hasAnyLocationFilter() ? (
              <p className="gallery-header-sub-title">{translate(FILTER_SORT_REFINE_RESULTS)}</p>
            ) : (
              <p className="gallery-header-no-location-alert-box">
                <FontIcon iconName="Info" className={iconClass} />
                <span className="gallery-header-no-location-alert-message">{translate(NO_LOCATION_SEARCH_ALERT_MESSAGE)} </span>
                <span className="gallery-header-no-location-alert-action">
                  <Link
                    className="filter-location-edit-link primary-link"
                    onClick={onGallerySetLocationClick}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                      if (e.code === SYSTEM_KEY_CODES.Enter) {
                        onGallerySetLocationClick();
                      }
                    }}
                    tabIndex={0}
                  >
                    {translate(NO_LOCATION_SEARCH_ALERT_ACTION)}
                  </Link>
                </span>
              </p>
            )}
          </div>
        ) : (
          <div>
            <p className="gallery-header-main-title">{translate(BROWSE_SERVICE_PARTNERS)}</p>
            <p className="gallery-header-sub-title">{translate(FILTER_SORT_REFINE_RESULTS)}</p>
          </div>
        )}
      </div>
    );
  };

  const renderGallerySearch = () => {
    if (runningInIframe()) {
      return null;
    } else {
      return (
        <div className="partners-gallery-search-box">
          <PartnerSearchBox />
        </div>
      );
    }
  };

  const renderGalleryContent = (partnersFilter: PartnersFilter) => {
    let totalResultsPages = Math.floor(estimatedTotalMatchingPartners / RESULTS_PAGE_SIZE);
    const currentResultsPage = Math.trunc(filter.resultPageOffset / RESULTS_PAGE_SIZE);
    if (estimatedTotalMatchingPartners % RESULTS_PAGE_SIZE !== 0) {
      totalResultsPages++;
    }
    // Since the number of results we are getting is not accurate - we can fix it.
    // If we get less than the requested results we know that the current page is the last one.
    if (partners.length < RESULTS_PAGE_SIZE) {
      totalResultsPages = currentResultsPage + 1;
    }
    const filterTags: IFilterTag[] = getFilterTags();
    const sortedFilterTags = filterTags.sort((tagA, tagB) => {
      return tagA.text > tagB.text ? 1 : -1;
    });
    const searchParams = new URLSearchParams(urlLocation.search);
    return (
      <Gallery
        loadingInProgress={fetchPartnersStatus === Status.InProgress}
        data={partners}
        totalResultsCount={estimatedTotalMatchingPartners}
        resultsPages={totalResultsPages}
        currentResultsPage={currentResultsPage}
        onClick={onPartnerClick}
        filterTags={sortedFilterTags}
        hasSelectedFilter={partnersFilter.hasNonLocationActiveFilter()}
        hasSelectedLocation={partnersFilter.hasLocationFilter()}
        onRemoveClick={onRemoveFilterTagClick}
        onPageClick={onPageClick}
        onClearFiltersClick={onGalleryFilterClearAll}
        onSelectLocationClick={onGallerySetLocationClick}
        contactPartner={contactPartner}
        filterQuryParam={searchParams.get('filter')}
        showBanner={filterTags.length === 0}
        resultSortBy={filter.resultSortBy}
        onSortByChange={onSortByChange}
      />
    );
  };

  const onSortByChange = (sortType: PartnersResultsSort) => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.GALLERY_SORT, properties: { option: sortOptionsNames[sortType] } });
    const updatedFilter = PartnersFilter.cloneFilter(filter);
    updatedFilter.resultSortBy = sortType;
    updateFilterUrl(updatedFilter);
  };

  const onApplySelectedLocation = (location: IUserLocation, radius: string, showPartnersFromLocation: boolean) => {
    trackUserAction({
      name: ANALYTICS_USER_ACTIONS.GALLERY_SET_LOCATION_CLICK,
      properties: { locationName: location?.name }
    });

    setSelectLocationDialogOpen(false);

    const countryLocation = getCountryByName(location.name);
    const isCountryLocation = countryLocation !== null;
    const filterLocation = new FilterLocation(location, getFilterDistanceOptionsMap().get(radius).distanceMiles, showPartnersFromLocation);
    const updatedFilter = PartnersFilter.cloneFilter(filter);
    updatedFilter.locationLatitude = isCountryLocation ? null : filterLocation.location.latitude;
    updatedFilter.locationLongtitude = isCountryLocation ? null : filterLocation.location.longitude;
    updatedFilter.locationName = filterLocation.location.name;
    updatedFilter.countryCode = isCountryLocation ? countryLocation.code : filterLocation.location.countryCode;
    updatedFilter.onlyThisCountryResults = countryLocation ? true : filterLocation.filterThisLocationOnly;
    updatedFilter.radiusMiles = isCountryLocation ? null : filterLocation.filterRadiusMiles;
    updatedFilter.userLocation = false;
    // Location was change, reset the page offset to first page.
    updatedFilter.resultPageOffset = 0;
    //Clear coordinates before storing location for whole country.
    if (isCountryLocation) {
      filterLocation.location.latitude = null;
      filterLocation.location.longitude = null;
    }
    dispatch(setFilterLocation({ filterLocation }));
    if (runningInIframe()) {
      const outboundMessagesHandler = new OutboundMessagesHandler();
      const storeFilterLocation: IStoreFilterLocationMessage = {
        messageType: PartnersHostMessageType.StoreFilterLocation,
        filterLocation: {
          countryCode: updatedFilter.countryCode,
          locationLatitude: updatedFilter.locationLatitude,
          locationLongtitude: updatedFilter.locationLongtitude,
          locationName: updatedFilter.locationName,
          onlyThisCountryResults: updatedFilter.onlyThisCountryResults,
          radiusMiles: updatedFilter.radiusMiles
        },
        diversityAndInclusionEnabled: showDiversityFilter(updatedFilter)
      };
      outboundMessagesHandler.postStoreFilterLocationMessage(storeFilterLocation);
    }
    updateFilterUrl(updatedFilter);
  };

  const renderSelectLocationDialog = () => {
    if (selectLocationDialogOpen) {
      const showPartnersFromLocation = filter.userLocation ? true : filter.onlyThisCountryResults;
      return (
        <SelectLocationDialog
          directionRtl={isRtl()}
          isDialogOpen={selectLocationDialogOpen}
          location={new UserLocation(filter.locationName, filter.locationLatitude, filter.locationLongtitude, filter.countryCode)}
          radius={getFilterDistanceOptionByMilesRadius(filter.radiusMiles).name}
          showPartnersFromLocation={showPartnersFromLocation}
          onCancel={() => {
            setSelectLocationDialogOpen(false);
          }}
          onApply={onApplySelectedLocation}
        />
      );
    } else return null;
  };

  const hasAnyLocationFilter = () => {
    const hasLocationFilter =
      filter.hasLocationFilter() ||
      (userLocation && userLocation.coordExist()) ||
      (lastFilterLocation && lastFilterLocation.location && lastFilterLocation.location.coordExist()) ||
      (lastFilterLocation && lastFilterLocation.location && lastFilterLocation.location.name && lastFilterLocation.location.countryCode) ||
      (viewContext &&
        viewContext.filterLocation &&
        viewContext.filterLocation.locationLatitude &&
        viewContext.filterLocation.locationLongtitude) ||
      (viewContext && viewContext.user && viewContext.user.country) ||
      (lastResultsFilter && lastResultsFilter.hasLocationFilter());
    return hasLocationFilter;
  };

  // Check if gallery view is empty.
  // View should be empty if one of the following conditions met.
  // 1. We didn't get init (with data) call from the parent of the iframe.
  // 2. Url location is not final - partnersUrl that we get as part of init isn't identical to the url we have in browser location.
  // 3. Runniung in iframe - we don't need this behaviour in standalone mode.
  const isEmptyGalleryView = () => {
    const urlLocation = useLocation();
    // "fakebase.com" used because URL constructor requier domain.
    const contextUrl = new URL(viewContext.partnersUrl, 'http://fakebase.com');
    const urlInLocationIsFinal = urlLocation.pathname === contextUrl.pathname && urlLocation.search === contextUrl.search;

    return runningInIframe() && (!viewContextInit || !urlInLocationIsFinal);
  };

  const galleryView = (
    <div className="partners-gallery">
      {renderGallerySearch()}
      {renderGalleryHeader()}
      <div className="partners-gallery-content-box">
        {renderGalleryFilter(filter)}
        {renderGalleryContent(filter)}
      </div>
      {renderSelectLocationDialog()}
    </div>
  );

  if (hasAnyLocationFilter() || filter.locationNotRequiredMode || isEmptyGalleryView()) {
    return galleryView;
  } else {
    if (isEmptyGalleryView()) {
      return <div className="partners-gallery-empty" />;
    } else {
      return galleryView;
    }
  }
}
