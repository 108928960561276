import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  Checkbox,
  DirectionalHint,
  FontIcon,
  INavButtonProps,
  INavLink,
  INavLinkGroup,
  INavStyles,
  ISearchBoxStyles,
  ITooltipHost,
  ITooltipHostStyles,
  Link,
  mergeStyles,
  Nav,
  SearchBox,
  Separator,
  Stack,
  TooltipHost
} from '@fluentui/react';
import { DominDataType, getDomainDataUniqueKey, getDomainDataUniqueKeyParent, IDomainData } from '../../shared/domainDataObj';
import {
  CATEGORIES,
  INDUSTRIES,
  MILES_ABBRV,
  ORGANIZATION_SIZE,
  PRODUCTS,
  RADIUS,
  SERVICES,
  FILTERS,
  CLEAR_ALL,
  CLEAR_ALL_FILTERS,
  MICROSOFT_ENDORSEMENTS,
  ADVANCED_SPECIALIZATION,
  AZURE_EXPERT_MSP_ONLY,
  SELECT_LOCATION,
  EDIT_LOCATION,
  SEARCH_FILTERS,
  NO_LOCATION_SELECTED,
  KILOMETERS_ABBRV,
  AZURE_EXPERT_MSPS_EXPLANATION,
  ADVANCED_SPECIALIZATION_EXPLANATION,
  SPECIALIZATION_EXPLANATION,
  EXPAND_MICROSOFT_ENDORSEMENTS_FILTER,
  COLLAPSE_MICROSOFT_ENDORSEMENTS_FILTER,
  EXPAND_ORGANIZATION_SIZE_FILTER,
  EXPAND_INDUSTRIES_FILTER,
  COLLAPSE_INDUSTRIES_FILTER,
  COLLAPSE_PRODUCTS_FILTER,
  EXPAND_PRODUCTS_FILTER,
  EXPAND_CATEGORIES_FILTER,
  COLLAPSE_CATEGORIES_FILTER,
  COLLAPSE_SERVICES_FILTER,
  EXPAND_SERVICES_FILTER,
  COLLAPSE_ORGANIZATION_SIZE_FILTER,
  PARTNERS_FROM_ENTIRE_COUNTRY,
  DIVERSE_BUSINESS,
  EXPAND_DIVERSE_BUSINESS_FILTER,
  COLLAPSE_DIVERSE_BUSINESS_FILTER_FILTER,
  DIVERSE_OWNED,
  DIVERSE_ACCREDIATION,
  DIVERSE_ACCREDIATION_DESCRIPTION,
  DIVERSE_OWNED_DESCRIPTION,
  SOCIAL_IMPACT,
  EXPAND_SOCIAL_IMPACT_FILTER,
  COLLAPSE_SOCIAL_IMPACT_FILTER,
  DESIGNATIONS,
  DESIGNATIONS_EXPLANATION,
  SPECIALIZATION,
  TRAINING_DESIGNATIONS,
  TRAINING_DESIGNATIONS_EXPLANATION
} from '../../constants/localization';
import { PartnersFilter } from '../../shared/models/Request/partnersFilter';
import { containsCaseInsesitive, splitStringBySearchPhrase, SplitStringResult, trimToLengthWithEllipsis } from '../../utils/stringUtils';
import { getCountryByName, localeUseImperialSystem } from '../../utils/countryStatesUtils';
import { useParams } from 'react-router-dom';
import { getFilterDistanceOptionByMilesRadius } from '../../utils/distanceUtils';
import CollapsiblePanel from '../common/collapsiblePanel';
import { SYSTEM_KEY_CODES } from '../../constants/keyboardNavigation';
import { isKeyboardNavigation } from '../../services/accessibilityService';
import { GetEnvConfiguration } from '../../services/configurationService';
import { getFeatureFlags, IFeatureFlags } from '../../redux/configSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export const ORG_SIZE_FILTER_KEY = 'organizationSizeFilter';
export const MSFT_ENDORSE_FILTER_KEY = 'microsoftEndorsementsFilter';
export const AZURE_MSP_FILTER_GROUP_KEY = 'azureMSPFilterGroup';
export const AZURE_MSP_FILTER_KEY = 'azureMSPFilter';
export const ADVANCES_SPECIALIZATION_FILTER_GROUP_KEY = 'advancedSPrcializationGroup';
export const SERVICES_FILTER_GROUP_KEY = 'servicesGroup';
export const DESIGNATIONS_FILTER_GROUP_KEY = 'designationsGroup';
export const TRAINING_DESIGNATIONS_FILTER_GROUP_KEY = 'trainingDesignationsGroup';
export const DIVERSE_BUSINESS_FILTER_KEY = 'diverseBusinessFilter';
export const SOCIAL_IMPACT_FILTER_KEY = 'socialImpactFilter';
export const DIVERSE_BUSINESS_OWNED_FILTER_KEY = 'diverseBusinessOwnedFilter';
export const ENDORSED_PRODUCT_FILTER_KEY = 'endorsedProductFilter';
export const ENDORSED_WORKLOAD_FILTER_KEY = 'endorsedWorkloadFilter';
export const DIVERSE_BUSINESS_ACCREDIATION_FILTER_KEY = 'diverseBusinessAccrediationFilter';
export const MAX_CHARS_IN_FILTER_L1 = 32;
export const MAX_CHARS_IN_FILTER_L2 = 28;

export enum FilterType {
  Group,
  Location,
  OrgSize,
  AzureMSP,
  AdvSpec,
  Product,
  Industry,
  Category,
  Service,
  DiverseOwn,
  DiverseAccred,
  Designation,
  TrainingDesignation,
  SubGroup,
  EndorsedProduct,
  EndorsedWorkload
}
interface IFilterNavLink extends INavLink {
  filterType: FilterType;
  toolTipText?: string;
}

export interface IFilterProps {
  filter: PartnersFilter;
  showDiverseFilters: boolean;
  onFilterCheckChange(filterType: FilterType, filterKey: string): void;
  onClearAll(): void;
  onSelectLocationClick(): void;
}

export default function Filter(props: IFilterProps) {
  const { t: translate } = useTranslation();
  const [expendStateMap, setExpendStateMap] = useState(new Map<string, boolean>());
  const [filtersSearch, setFiltersSearch] = useState('');
  const { locale } = useParams<{ locale: string }>();
  const featureFlags: IFeatureFlags = useSelector((store: RootState) => getFeatureFlags(store.config));

  const updateExpendStateMap = (key: string, expendState: boolean) => {
    setExpendStateMap(new Map(expendStateMap.set(key, expendState)));
  };
  const clearExpendStateMap = () => {
    setExpendStateMap(new Map());
  };

  const navStyles: Partial<INavStyles> = {
    navItem: {},
    link: {
      '&': {
        backgroundColor: 'transparent'
      },
      '.ms-Nav-compositeLink &:after': {
        display: 'none'
      }
    },
    chevronButton: {
      '&': {
        backgroundColor: 'transparent'
      },
      '&:after': {
        border: 'none'
      }
    },
    chevronIcon: {
      color: 'rgb(50, 49, 48)',
      display: 'inline-flex',
      alignItems: 'center'
    },
    root: { width: 400, overflow: 'hidden' },
    group: {
      marginBottom: -40
    }
  };

  const onEditLocationClickHandler = () => {
    if (props.onSelectLocationClick) {
      props.onSelectLocationClick();
    }
  };

  const renderLocationFilter = () => {
    const imperialSystem = localeUseImperialSystem(locale);
    const distanceAbbrv = imperialSystem ? MILES_ABBRV : KILOMETERS_ABBRV;
    const distanceOption = getFilterDistanceOptionByMilesRadius(props.filter.radiusMiles);
    const countryLocation = getCountryByName(props.filter.locationName) !== null;
    let locationText = null;
    if (countryLocation) {
      locationText = `${props.filter.locationName} (${translate(PARTNERS_FROM_ENTIRE_COUNTRY)})`;
    } else {
      const distanceValue = distanceOption
        ? imperialSystem
          ? distanceOption.distanceMiles
          : distanceOption.distanceKM
        : props.filter.radiusMiles;
      locationText = props.filter.hasLocationFilter()
        ? `${props.filter.locationName} (${distanceValue} ${translate(distanceAbbrv)} ${translate(RADIUS)})`
        : props.filter.hasLocationFilter()
        ? `${props.filter.locationName}`
        : translate(NO_LOCATION_SELECTED);
    }
    return (
      <div className="filter-location-box">
        <div className="filter-location-label-box">
          <div className={'filter-location-icon-box'}>
            <div className={'icon-location'} />
          </div>
          <p className="filter-location-label">{locationText}</p>
        </div>
        <div className="filter-location-edit-box">
          <Link
            className="filter-location-edit-link primary-link"
            onClick={onEditLocationClickHandler}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
              if (e.code === SYSTEM_KEY_CODES.Enter) {
                onEditLocationClickHandler();
              }
            }}
            tabIndex={0}
          >
            {props.filter.hasLocationFilter() ? translate(EDIT_LOCATION) : translate(SELECT_LOCATION)}
          </Link>
        </div>
      </div>
    );
  };

  const searcBoxStyles: Partial<ISearchBoxStyles> = {
    root: { backgroundColor: 'transparent' }
  };

  const updateExpendFoundFilters = (searchFilter: string) => {
    // Invalid input --> clear expend state and do nothing
    if (!searchFilter || searchFilter === '') {
      clearExpendStateMap();
      return;
    } else {
      // Start by clearing the expend state.
      clearExpendStateMap();
      const navLinkGroups = createNavLinkGroups();
      const filteredNavLinkGroups = filterNavLinkGroups(navLinkGroups);
      // Try to expend level 1.
      for (let groupL1Index = 0; groupL1Index < filteredNavLinkGroups.length; groupL1Index++) {
        const group = filteredNavLinkGroups[groupL1Index].links[0];
        if (containsCaseInsesitive(group.name, searchFilter)) {
          updateExpendStateMap(group.key, true);
        }
        // Try to expend level 1 and 2 base on level 2.
        if (group.links && group.links.length > 0) {
          for (let filterL2Index = 0; filterL2Index < group.links.length; filterL2Index++) {
            const l2Filter = group.links[filterL2Index];
            if (containsCaseInsesitive(l2Filter.name, searchFilter)) {
              updateExpendStateMap(group.key, true);
              if (l2Filter.links && l2Filter.links.length > 0) {
                updateExpendStateMap(l2Filter.key, true);
              }
            }
            // Try to expend level 2 base on level 3
            if (l2Filter.links && l2Filter.links.length > 0) {
              for (let filterL3Index = 0; filterL2Index < l2Filter.links.length; filterL2Index++) {
                const l3Filter = l2Filter.links[filterL3Index];
                if (containsCaseInsesitive(l3Filter.name, searchFilter)) {
                  updateExpendStateMap(l2Filter.key, true);
                }
              }
            }
          }
        }
      }
    }
  };

  const renderFiltersSearch = () => {
    return (
      <SearchBox
        placeholder={translate(SEARCH_FILTERS)}
        underlined={true}
        styles={searcBoxStyles}
        onChange={(event: any, newValue?: string) => {
          setFiltersSearch(newValue);
          updateExpendFoundFilters(newValue);
        }}
      />
    );
  };

  const getFilterGroupByType = (
    donainDataType: DominDataType,
    filtertype: FilterType,
    groupText: string,
    sortFilterItems: boolean,
    groupExpandText: string,
    groupCollapseText: string
  ) => {
    const domDataOptions: IDomainData[] = getDomainDataUniqueKey(donainDataType);
    const domDataFilterOptions = domDataOptions.filter(option => !option.isRemoved);
    const filterLinks: IFilterNavLink[] = domDataFilterOptions.map((option, index) => {
      return {
        filterType: filtertype,
        name: translate(option.localizationKey),
        key: option.newKey,
        url: '',
        target: '_blank',
        isExpanded: expendStateMap.get(option.newKey)
      };
    });

    let finalFilterLinks = filterLinks;
    // Sort filter items alphabetically.
    if (sortFilterItems) {
      finalFilterLinks = filterLinks.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }

    const groupKey = filtertype + 'filter_group';
    const group: INavLinkGroup = {
      links: [
        {
          name: groupText,
          url: '',
          target: '_blank',
          key: groupKey,
          expandAriaLabel: groupExpandText,
          collapseAriaLabel: groupCollapseText,
          links: finalFilterLinks,
          isExpanded: expendStateMap.get(groupKey)
        }
      ]
    };
    return group;
  };

  const getAzureMSPGroup = () => {
    const mspLink: IFilterNavLink = {
      filterType: FilterType.AzureMSP,
      name: translate(AZURE_EXPERT_MSP_ONLY),
      key: AZURE_MSP_FILTER_KEY,
      url: '',
      target: '_blank',
      toolTipText: translate(AZURE_EXPERT_MSPS_EXPLANATION),
      isExpanded: expendStateMap.get(AZURE_MSP_FILTER_KEY)
    };

    return mspLink;
  };

  const getAdvSpecializationGroupByDesignations = () => {
    const advSpecItems: IDomainData[] = getDomainDataUniqueKey(DominDataType.AdvSpecParent);
    const advSpecGroupLinks = advSpecItems.map(item => {
      return createAdvSpecFilterLinksGroup(item);
    });

    const advSpecializationGroup: IFilterNavLink = {
      filterType: FilterType.Group,
      name: translate(SPECIALIZATION),
      url: '',
      key: ADVANCES_SPECIALIZATION_FILTER_GROUP_KEY,
      isExpanded: expendStateMap.get(ADVANCES_SPECIALIZATION_FILTER_GROUP_KEY),
      toolTipText: translate(SPECIALIZATION_EXPLANATION),
      links: advSpecGroupLinks
    };
    return advSpecializationGroup;
  };

  const createAdvSpecFilterLinksGroup = (advSpecParent: IDomainData): IFilterNavLink => {
    const advSpecializationGroup: IFilterNavLink = {
      filterType: FilterType.SubGroup,
      name: translate(advSpecParent.localizationKey),
      url: '',
      target: '_blank',
      key: advSpecParent.newKey,
      isExpanded: expendStateMap.get(advSpecParent.newKey),
      links: createAdvSpecFilterLinks(advSpecParent.newKey)
    };
    return advSpecializationGroup;
  };

  const createAdvSpecFilterLinks = (advSpecParent: string): IFilterNavLink[] => {
    const advSpecItems: IDomainData[] = getDomainDataUniqueKeyParent(DominDataType.AdvSpec, advSpecParent);
    const advSpecLinks: IFilterNavLink[] = advSpecItems.map(advSpecial => {
      return createAdvSpecFilterLink(advSpecial);
    });
    const sortedAdvSpecLinks = advSpecLinks.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    return sortedAdvSpecLinks;
  };

  const createAdvSpecFilterLink = (advSpecial: IDomainData) => {
    return {
      filterType: FilterType.AdvSpec,
      name: translate(advSpecial.localizationKey),
      key: advSpecial.newKey,
      url: '',
      target: '_blank',
      isExpanded: expendStateMap.get(advSpecial.newKey)
    };
  };

  const getServicesGroupByParents = () => {
    const servicesParentItems: IDomainData[] = getDomainDataUniqueKey(DominDataType.ServiceParent);
    const servicesGroupLinks = servicesParentItems.map(item => {
      return createServicesLinksGroup(item);
    });

    const serviceGroup: INavLinkGroup = {
      links: [
        {
          name: translate(SERVICES),
          url: '',
          key: SERVICES_FILTER_GROUP_KEY,
          isExpanded: expendStateMap.get(SERVICES_FILTER_GROUP_KEY),
          links: servicesGroupLinks
        }
      ]
    };
    return serviceGroup;
  };

  const createServicesLinksGroup = (serviceParent: IDomainData): IFilterNavLink => {
    const servicesGroup: IFilterNavLink = {
      filterType: FilterType.SubGroup,
      name: translate(serviceParent.localizationKey),
      url: '',
      target: '_blank',
      key: serviceParent.newKey,
      isExpanded: expendStateMap.get(serviceParent.newKey),
      links: createServiceFilterLinks(serviceParent.newKey)
    };
    return servicesGroup;
  };

  const createServiceFilterLinks = (serviceParent: string): IFilterNavLink[] => {
    const serviceItems: IDomainData[] = getDomainDataUniqueKeyParent(DominDataType.Service, serviceParent);
    const serviceLinks: IFilterNavLink[] = serviceItems.map(service => {
      return createServiceFilterLink(service);
    });
    const sortedServiceLinks = serviceLinks.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    return sortedServiceLinks;
  };

  const createServiceFilterLink = (service: IDomainData) => {
    return {
      filterType: FilterType.Service,
      name: translate(service.localizationKey),
      key: service.newKey,
      url: '',
      target: '_blank',
      isExpanded: expendStateMap.get(service.newKey)
    };
  };

  const getDesignationsGroup = () => {
    const designationDataOptions: IDomainData[] = getDomainDataUniqueKey(DominDataType.Designations);
    const designationLinks: IFilterNavLink[] = designationDataOptions.map(designation => {
      {
        return {
          filterType: FilterType.Designation,
          name: translate(designation.localizationKey),
          key: designation.newKey,
          url: '',
          target: '_blank',
          isExpanded: expendStateMap.get(designation.newKey)
        };
      }
    });

    const sortedDesignationLinks = designationLinks.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

    const designationsGroup: IFilterNavLink = {
      filterType: FilterType.Group,
      name: translate(DESIGNATIONS),
      url: '',
      target: '_blank',
      key: DESIGNATIONS_FILTER_GROUP_KEY,
      isExpanded: expendStateMap.get(DESIGNATIONS_FILTER_GROUP_KEY),
      toolTipText: translate(DESIGNATIONS_EXPLANATION),
      links: sortedDesignationLinks
    };
    return designationsGroup;
  };

  const getTrainingDesignationsGroup = () => {
    const trainingDesignationDataOptions: IDomainData[] = getDomainDataUniqueKey(DominDataType.TrainingDesignations);
    const trainingDesignationLinks: IFilterNavLink[] = trainingDesignationDataOptions.map(trainingDesignation => {
      {
        return {
          filterType: FilterType.TrainingDesignation,
          name: translate(trainingDesignation.localizationKey),
          key: trainingDesignation.newKey,
          url: '',
          target: '_blank',
          isExpanded: expendStateMap.get(trainingDesignation.newKey)
        };
      }
    });

    const sortedTrainingDesignationLinks = trainingDesignationLinks.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

    const trainingDesignationsGroup: IFilterNavLink = {
      filterType: FilterType.Group,
      name: translate(TRAINING_DESIGNATIONS),
      url: '',
      target: '_blank',
      key: TRAINING_DESIGNATIONS_FILTER_GROUP_KEY,
      isExpanded: expendStateMap.get(TRAINING_DESIGNATIONS_FILTER_GROUP_KEY),
      toolTipText: translate(TRAINING_DESIGNATIONS_EXPLANATION),
      links: sortedTrainingDesignationLinks
    };
    return trainingDesignationsGroup;
  };

  const getMicrosoftEndorsementsGroup = () => {
    const trainingDesignationsEnabled = GetEnvConfiguration().TRAINING_DESIGNATIONS_ENABLED || featureFlags?.trainingDesignationsEnabled;
    const links = [getAzureMSPGroup(), getAdvSpecializationGroupByDesignations(), getDesignationsGroup()];
    if (trainingDesignationsEnabled) {
      links.push(getTrainingDesignationsGroup());
    }
    const navLinkGroup: INavLinkGroup = {
      links: [
        {
          name: translate(MICROSOFT_ENDORSEMENTS),
          url: '',
          target: '_blank',
          key: MSFT_ENDORSE_FILTER_KEY,
          expandAriaLabel: translate(EXPAND_MICROSOFT_ENDORSEMENTS_FILTER),
          collapseAriaLabel: translate(COLLAPSE_MICROSOFT_ENDORSEMENTS_FILTER),
          links,
          isExpanded: expendStateMap.get(MSFT_ENDORSE_FILTER_KEY)
        }
      ]
    };

    return navLinkGroup;
  };

  const getDiverseOwnLedGroup = () => {
    const diverseOwnNavLink: IFilterNavLink = {
      filterType: FilterType.DiverseOwn,
      name: translate(DIVERSE_OWNED),
      url: '',
      target: '_blank',
      key: DIVERSE_BUSINESS_OWNED_FILTER_KEY,
      toolTipText: translate(DIVERSE_OWNED_DESCRIPTION)
    };
    return diverseOwnNavLink;
  };

  const getDiverseAccredGroup = () => {
    const diverseAccredNavLink: IFilterNavLink = {
      filterType: FilterType.DiverseAccred,
      name: translate(DIVERSE_ACCREDIATION),
      url: '',
      target: '_blank',
      key: DIVERSE_BUSINESS_ACCREDIATION_FILTER_KEY,
      toolTipText: translate(DIVERSE_ACCREDIATION_DESCRIPTION)
    };

    const diverseMultiGeoEnabled = GetEnvConfiguration().DIVERSE_INCLUSION_MULTIGEO_ENABLED;
    if (diverseMultiGeoEnabled) {
      // Add child accridiation types according to the selected country
      // Should be implemented when backend support this
    }

    return diverseAccredNavLink;
  };

  const getSocialImpactGroup = () => {
    const navLinkGroup: INavLinkGroup = {
      links: [
        {
          name: translate(SOCIAL_IMPACT),
          url: '',
          target: '_blank',
          key: SOCIAL_IMPACT_FILTER_KEY,
          expandAriaLabel: translate(EXPAND_SOCIAL_IMPACT_FILTER),
          collapseAriaLabel: translate(COLLAPSE_SOCIAL_IMPACT_FILTER),
          links: [getDiverseBuisinessGroup()],
          isExpanded: expendStateMap.get(SOCIAL_IMPACT_FILTER_KEY)
        }
      ]
    };
    return navLinkGroup;
  };

  const getDiverseBuisinessGroup = () => {
    const navLinkGroup: IFilterNavLink = {
      filterType: FilterType.Group,
      name: translate(DIVERSE_BUSINESS),
      url: '',
      target: '_blank',
      expandAriaLabel: translate(EXPAND_DIVERSE_BUSINESS_FILTER),
      collapseAriaLabel: translate(COLLAPSE_DIVERSE_BUSINESS_FILTER_FILTER),
      key: DIVERSE_BUSINESS_FILTER_KEY,
      isExpanded: expendStateMap.get(DIVERSE_BUSINESS_FILTER_KEY),
      links: [getDiverseOwnLedGroup(), getDiverseAccredGroup()] // Disable accred buisness until operation support it.
    };

    return navLinkGroup;
  };

  const filterLinkPassSearch = (filterLink: INavLink): boolean => {
    // No filters search
    if (!filtersSearch || filtersSearch === '') {
      return true;
    }
    // Filters search found
    if (containsCaseInsesitive(filterLink.name, filtersSearch)) {
      return true;
    } else {
      if (filterLink.links) {
        for (let i = 0; i < filterLink.links.length; i++) {
          if (filterLinkPassSearch(filterLink.links[i])) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const filterNavLinkGroups = (filterLinkGroups: INavLinkGroup[]): INavLinkGroup[] => {
    if (!filtersSearch || filtersSearch === '') {
      return filterLinkGroups;
    }
    const filteredResults: INavLinkGroup[] = [];
    // Group in level 1
    for (let groupIndex = 0; groupIndex < filterLinkGroups.length; groupIndex++) {
      const filterLinkGroup = filterLinkGroups[groupIndex].links[0];
      // Search phrase found in group name found --> add all children
      if (containsCaseInsesitive(filterLinkGroup.name, filtersSearch)) {
        //const cloneGroup = { ...filterLinkGroups[groupIndex] };
        const cloneGroup = JSON.parse(JSON.stringify(filterLinkGroups[groupIndex]));
        filteredResults.push(cloneGroup);
      }
      // Group name not found --> search in children
      else {
        //const cloneGroup = { ...filterLinkGroups[groupIndex] };
        const cloneGroup = JSON.parse(JSON.stringify(filterLinkGroups[groupIndex]));
        cloneGroup.links[0].links = [];
        // Link in level 2
        for (let filterL1Index = 0; filterL1Index < filterLinkGroup.links.length; filterL1Index++) {
          const filterL1Link = filterLinkGroup.links[filterL1Index];
          // Link in level 2 that has level 3
          if (filterL1Link.links && filterL1Link.links.length > 0) {
            // Search phrase found in link name --> show it and all its children
            if (containsCaseInsesitive(filterL1Link.name, filtersSearch)) {
              const cloneL1Link = JSON.parse(JSON.stringify(filterL1Link));
              cloneGroup.links[0].links.push(cloneL1Link);
            } else {
              if (filterLinkPassSearch(filterL1Link)) {
                const cloneL1Link = JSON.parse(JSON.stringify(filterL1Link));
                cloneL1Link.links = [];
                // Link in level 3
                for (let filterL2Index = 0; filterL2Index < filterL1Link.links.length; filterL2Index++) {
                  const filterL2Link = filterL1Link.links[filterL2Index];
                  if (filterLinkPassSearch(filterL2Link)) {
                    const cloneL2Link = JSON.parse(JSON.stringify(filterL2Link));
                    cloneL1Link.links.push(cloneL2Link);
                  }
                }
                cloneGroup.links[0].links.push(cloneL1Link);
              }
            }
            // Link in level 2 w/o level 3 children
          } else {
            if (filterLinkPassSearch(filterL1Link)) {
              const cloneL1Link = JSON.parse(JSON.stringify(filterL1Link));
              cloneGroup.links[0].links.push(cloneL1Link);
            }
          }
        }
        if (cloneGroup.links[0].links.length > 0) {
          filteredResults.push(cloneGroup);
        }
      }
    }
    return filteredResults;
  };

  const renderFilterLinkToolTip = (navLink: INavLink, tooltipHostRef: MutableRefObject<ITooltipHost>): JSX.Element => {
    const filterLink = navLink as IFilterNavLink;
    const hasTooltip = filterLink && filterLink.toolTipText;
    if (hasTooltip) {
      const iconClass = mergeStyles({
        fontSize: 16,
        height: 16,
        width: 16,
        margin: '0px 6px',
        verticalAlign: 'middle'
      });
      const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
      const calloutProps = { gapSpace: 0 };
      return (
        <TooltipHost
          content={filterLink.toolTipText}
          id={filterLink.key + '_tooltiphost_id'}
          calloutProps={calloutProps}
          styles={hostStyles}
          componentRef={tooltipHostRef}
        >
          <FontIcon aria-label={filterLink.toolTipText} iconName="Info" className={iconClass} />
        </TooltipHost>
      );
    } else {
      return null;
    }
  };

  const renderLink = (navButtonProps: INavButtonProps): JSX.Element => {
    if (!navButtonProps.link) {
      return null;
    }
    const hasChildren = navButtonProps.link.links && navButtonProps.link.links.length > 0;
    let selectedChildrenCount = 0;
    if (hasChildren) {
      navButtonProps.link.links.map(link => {
        {
          if (getFilterItemSelected(link as IFilterNavLink)) {
            selectedChildrenCount++;
          }
        }
      });
    }

    const labelText = navButtonProps.link.name;

    const filterLink = navButtonProps.link as IFilterNavLink;
    const isLevel2Checkbox =
      (filterLink && filterLink.filterType === FilterType.AdvSpec) ||
      (filterLink && filterLink.filterType === FilterType.Designation) ||
      (filterLink && filterLink.filterType === FilterType.TrainingDesignation) ||
      filterLink.filterType == FilterType.AzureMSP ||
      filterLink.filterType == FilterType.DiverseOwn ||
      filterLink.filterType == FilterType.DiverseAccred;

    const isSingleSelection = filterLink && (filterLink.filterType === FilterType.OrgSize || filterLink.filterType === FilterType.Industry);

    const labelTxtTrimToLength = trimToLengthWithEllipsis(labelText, isLevel2Checkbox ? MAX_CHARS_IN_FILTER_L2 : MAX_CHARS_IN_FILTER_L1);
    const splitResult: SplitStringResult = splitStringBySearchPhrase(labelTxtTrimToLength, filtersSearch);
    const infoTooltipComponentRef = useRef<ITooltipHost>();

    // Filter group
    if (hasChildren) {
      const groupStyle = { marginLeft: 24, fontWeight: 400, 'white-space': 'nowrap' };
      groupStyle.marginLeft =
        navButtonProps.link.filterType == FilterType.Group ? 40 : navButtonProps.link.filterType == FilterType.SubGroup ? 56 : 24;
      groupStyle.fontWeight = selectedChildrenCount > 0 ? 600 : 400;
      const groupSelectionCounterSuffix = selectedChildrenCount > 0 ? ` (${selectedChildrenCount})` : '';
      const itemContent = splitResult.foudAndSplitted ? (
        <span style={groupStyle}>
          {splitResult.prefix}
          <span className="filter-search-result-bold">{splitResult.match}</span>
          {splitResult.suffix}
          {groupSelectionCounterSuffix}
          {renderFilterLinkToolTip(navButtonProps.link, infoTooltipComponentRef)}
        </span>
      ) : (
        <span style={groupStyle}>
          {`${labelTxtTrimToLength} ${groupSelectionCounterSuffix}`}
          {renderFilterLinkToolTip(navButtonProps.link, infoTooltipComponentRef)}
        </span>
      );
      const onButtonFocus = () => {
        // Enable keyboard navigation tooltip display for menu items with an additional information tooltip
        if (infoTooltipComponentRef && infoTooltipComponentRef.current && isKeyboardNavigation()) {
          infoTooltipComponentRef.current.show();
        }
      };

      return (
        <ActionButton onClick={navButtonProps.onClick} onFocus={onButtonFocus}>
          <TooltipHost directionalHint={DirectionalHint.topLeftEdge} content={labelText}>
            {itemContent}
          </TooltipHost>
        </ActionButton>
      );

      // Filter checkbox
    } else {
      const itemContent = (
        <Checkbox
          checked={getFilterItemSelected(navButtonProps.link as IFilterNavLink)}
          // Make this show as radio button for screen reader and not failed in accessibility insights tests.
          inputProps={isSingleSelection ? { role: 'radio', type: 'button', value: filterLink.name } : null}
          onRenderLabel={() =>
            splitResult.foudAndSplitted ? (
              <span className="filter-check-text-left-margin">
                {splitResult.prefix}
                <span className="filter-search-result-bold">{splitResult.match}</span>
                {splitResult.suffix}
                {renderFilterLinkToolTip(navButtonProps.link, infoTooltipComponentRef)}
              </span>
            ) : (
              <span className="filter-check-text-left-margin">
                {labelTxtTrimToLength}
                {renderFilterLinkToolTip(navButtonProps.link, infoTooltipComponentRef)}
              </span>
            )
          }
        ></Checkbox>
      );
      return (
        <div className="filter-item-action-button">
          <div
            onClick={navButtonProps.onClick}
            className={isLevel2Checkbox ? 'filter-check-l2-left-margin' : 'filter-check-l1-left-margin'}
          >
            <TooltipHost directionalHint={DirectionalHint.topLeftEdge} content={labelText}>
              {itemContent}
            </TooltipHost>
          </div>
        </div>
      );
    }
  };

  const getFilterItemSelected = (filterLink: IFilterNavLink): boolean => {
    const filter = props.filter;
    if (filterLink.filterType == FilterType.AdvSpec) {
      return filter.advSpecialization && filter.advSpecialization.indexOf(filterLink.key) !== -1;
    } else if (filterLink.filterType == FilterType.OrgSize) {
      return filterLink.key === filter.orgSize;
    } else if (filterLink.filterType == FilterType.AzureMSP) {
      return filter.azureMSP;
    } else if (filterLink.filterType == FilterType.Industry) {
      return filter.industries && filter.industries.indexOf(filterLink.key) !== -1;
    } else if (filterLink.filterType == FilterType.Product) {
      return filter.products && filter.products.indexOf(filterLink.key) !== -1;
    } else if (filterLink.filterType == FilterType.Category) {
      return filter.categories && filter.categories.indexOf(filterLink.key) !== -1;
    } else if (filterLink.filterType == FilterType.Service) {
      return filter.services && filter.services.indexOf(filterLink.key) !== -1;
    } else if (filterLink.filterType == FilterType.DiverseOwn) {
      return filter.diverseOwned;
    } else if (filterLink.filterType == FilterType.DiverseAccred) {
      return filter.diverseAccrediation;
    } else if (filterLink.filterType === FilterType.Designation) {
      return filter.designations && filter.designations.indexOf(filterLink.key) !== -1;
    } else if (filterLink.filterType === FilterType.TrainingDesignation) {
      return filter.trainingDesignations && filter.trainingDesignations.indexOf(filterLink.key) !== -1;
    }
    // Group call get value on its children
    else if (filterLink.filterType == FilterType.Group) {
      if (!filterLink.links) return false;
      else {
        let selectedChildrenCount = 0;
        filterLink.links.map(childrenFilter => {
          if (getFilterItemSelected(childrenFilter as IFilterNavLink)) {
            selectedChildrenCount++;
            return;
          }
        });
        return selectedChildrenCount > 0;
      }
    } else {
      return false;
    }
  };

  const onNavLinkClick = (event: React.MouseEvent<HTMLElement>, navLink: INavLink) => {
    const filterNavLink: IFilterNavLink = navLink as IFilterNavLink;
    if (filterNavLink && props.onFilterCheckChange) {
      props.onFilterCheckChange(filterNavLink.filterType, filterNavLink.key);
    }
  };

  const onNavLinkExpandClick = (event: React.MouseEvent<HTMLElement>, item: INavLink) => {
    let isExpended = true;
    if (expendStateMap.has(item.key)) {
      isExpended = !expendStateMap.get(item.key);
    }
    updateExpendStateMap(item.key, isExpended);
  };

  const createNavLinkGroups = (): INavLinkGroup[] => {
    const orgSizeGroup: INavLinkGroup = getFilterGroupByType(
      DominDataType.CompanySize,
      FilterType.OrgSize,
      translate(ORGANIZATION_SIZE),
      false,
      translate(EXPAND_ORGANIZATION_SIZE_FILTER),
      translate(COLLAPSE_ORGANIZATION_SIZE_FILTER)
    );
    const msftEndorseGroup: INavLinkGroup = getMicrosoftEndorsementsGroup();
    const industriesGroup: INavLinkGroup = getFilterGroupByType(
      DominDataType.Industry,
      FilterType.Industry,
      translate(INDUSTRIES),
      true,
      translate(EXPAND_INDUSTRIES_FILTER),
      translate(COLLAPSE_INDUSTRIES_FILTER)
    );
    const productsGroup: INavLinkGroup = getFilterGroupByType(
      DominDataType.Product,
      FilterType.Product,
      translate(PRODUCTS),
      true,
      translate(EXPAND_PRODUCTS_FILTER),
      translate(COLLAPSE_PRODUCTS_FILTER)
    );
    const solutionsGroup: INavLinkGroup = getFilterGroupByType(
      DominDataType.Category,
      FilterType.Category,
      translate(CATEGORIES),
      true,
      translate(EXPAND_CATEGORIES_FILTER),
      translate(COLLAPSE_CATEGORIES_FILTER)
    );

    const servicesGroup: INavLinkGroup = getServicesGroupByParents();
    const allGroups = [orgSizeGroup, msftEndorseGroup, industriesGroup, productsGroup, solutionsGroup, servicesGroup];
    if (props.showDiverseFilters) {
      const socialImpactGroup: INavLinkGroup = getSocialImpactGroup();
      allGroups.push(socialImpactGroup);
    }
    return allGroups;
  };

  const renderNavFilters = () => {
    const navLinkGroups = createNavLinkGroups();
    const filteredNavLinkGroups = filterNavLinkGroups(navLinkGroups);
    return (
      <Nav
        styles={navStyles}
        groups={filteredNavLinkGroups}
        onLinkClick={onNavLinkClick}
        onLinkExpandClick={onNavLinkExpandClick}
        ariaLabel={translate(FILTERS)}
        linkAs={props => renderLink(props)}
        isOnTop={false}
      />
    );
  };

  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth });

  const updateWindowDimensions = () => {
    setWindowDimensions({ width: window.innerWidth });
  };

  const FILTER_COLLAPSE_BREAKPOINT = 1024;

  const toggleCollapseFilters = () => setIsFiltersCollapsed(!isFiltersCollapsed);

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  });

  const shouldFiltersCollapse = () => {
    return windowDimensions.width > FILTER_COLLAPSE_BREAKPOINT ? false : isFiltersCollapsed;
  };

  const handleClearAllFilters = () => {
    if (props.onClearAll) {
      props.onClearAll();
    }
  };

  return (
    <div className="filter-container-box">
      <div className="filters-title-box">
        <Stack horizontal grow disableShrink horizontalAlign="space-between" verticalAlign="center">
          <div>
            <div className="filters-title-text">{translate(FILTERS)}</div>
            {props.filter.hasNonLocationActiveFilter() ? (
              <Link
                as="a"
                className="filters-title-clear-link primary-link"
                tabIndex={0}
                onClick={() => {
                  handleClearAllFilters();
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                  if (e.code === SYSTEM_KEY_CODES.Enter) {
                    handleClearAllFilters();
                  }
                }}
                role="button"
                aria-label={translate(CLEAR_ALL_FILTERS)}
              >
                {translate(CLEAR_ALL)}
              </Link>
            ) : null}
          </div>
          <ActionButton
            className="filters-expand-button"
            iconProps={{ iconName: 'ChevronDown' }}
            disabled={false}
            checked={false}
            onClick={() => {
              toggleCollapseFilters();
            }}
            styles={{ icon: { transform: isFiltersCollapsed ? 'rotate(0)' : 'rotate(180deg)' } }}
          />
        </Stack>
      </div>
      <CollapsiblePanel collapsed={shouldFiltersCollapse()}>
        <div className="filters-search-container">{renderFiltersSearch()}</div>
        <div className="filters-location-container">{renderLocationFilter()}</div>
        <Separator
          styles={{
            root: [
              {
                selectors: {
                  '::before': {
                    background: '#d5d5d5'
                  }
                }
              }
            ]
          }}
        />
        <div className="filters-nav-container">{renderNavFilters()}</div>
      </CollapsiblePanel>
    </div>
  );
}
