import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchPartnerDetails, fetchPartners } from './apis';
import { fetchPartnersAsync, setPartners, onError as onPartnersError, onSuccess as onPartnersSuccess } from '../redux/partnersSlice';
import {
  fetchPartnerDetailsAsync,
  setPartner,
  onError as onPartnerError,
  onSuccess as onPartnerSuccess
} from '../redux/partnersDetailsSlice';
import * as Logger from '../services/loggerService';
import { LOGGER } from '../shared/constants';

const LOGGER_PREFIX = 'Parnters Saga:';

function* getPartners(action) {
  try {
    const { filter } = action.payload;
    const { data } = yield call(fetchPartners, filter);
    Logger.Info('fetchPartners', '/api/partners', LOGGER.END);
    yield put(setPartners(data));
    yield put(onPartnersSuccess());
  } catch (e) {
    Logger.Exception(LOGGER_PREFIX, e.toString());
    yield put(onPartnersError(e.toString()));
  }
}

function* getPartnerDetails(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(fetchPartnerDetails, id);
    Logger.Info('fetchPartnerDetails', '/api/partners/' + id, LOGGER.END);
    yield put(setPartner({ data: data }));
    yield put(onPartnerSuccess());
  } catch (e) {
    Logger.Exception(LOGGER_PREFIX, e.toString());
    yield put(onPartnerError(e.toString()));
  }
}

export function* partnersWatcher() {
  yield takeLatest(fetchPartnersAsync, getPartners);
  yield takeLatest(fetchPartnerDetailsAsync, getPartnerDetails);
}
