import { ProductKey } from '../shared/domainDataObj';

export function getIconForProduct(productKey: string): string {
  if (productKey === ProductKey.Microsoft365) {
    return 'icon-product-microsoft365-16';
  } else if (productKey === ProductKey.Dynamics365Business) {
    return 'icon-product-dynamics-365-16';
  } else if (productKey === ProductKey.Dynamics365Enterprise) {
    return 'icon-product-dynamics-365-16';
  } else if (productKey === ProductKey.DynamicsAX_GP_NAV_SL) {
    return 'icon-product-dynamics-365-16';
  } else if (productKey === ProductKey.Azure) {
    return 'icon-product-azure-16';
  } else if (productKey === ProductKey.SQL) {
    return 'icon-product-sql-16';
  } else if (productKey === ProductKey.PowerBI) {
    return 'icon-product-power-bi-16';
  } else if (productKey === ProductKey.Office) {
    return 'icon-product-office-16';
  } else if (productKey === ProductKey.Exchange) {
    return 'icon-product-outlook-16';
  } else if (productKey === ProductKey.Project) {
    return 'icon-product-project-16';
  } else if (productKey === ProductKey.SharePoint) {
    return 'icon-product-share_point-16';
  } else if (productKey === ProductKey.Visio) {
    return 'icon-product-visio-16';
  } else if (productKey === ProductKey.Windows) {
    return 'icon-product-windows-16';
  } else if (productKey === ProductKey.Teams) {
    return 'icon-product-teams-16';
  } else if (productKey === ProductKey.SkypeForBusiness) {
    return 'icon-product-skype_for_business-16';
  } else if (productKey === ProductKey.DeveloperTools) {
    return 'icon-product-microsoft365-16';
  } else if (productKey === ProductKey.Yammer) {
    return 'icon-product-yammer-16';
  } else if (productKey === ProductKey.EnterpriseMobilityAndSecurity) {
    return 'icon-product-microsoft365-16';
  } else return null;
}
