import React, { useEffect, useState } from 'react';
import { mergeStyleSets, TextField } from '@fluentui/react';
import { IUserLocation, UserLocation } from '../../redux/locationSlice';
import { loadAutoCompleteModule, loadBingMapApi } from '../../utils/bingUtils';
import * as Logger from './../../services/loggerService';

const LOGGER_PREFIX = 'BingLocationSearchBox';

export interface IBingLocationSearchBox {
  label: string;
  selectedLocation: IUserLocation;
  placeholder?: string;
  required?: boolean;
  maxResults: number;
  onSelectedLocationChange(location: IUserLocation): void;
}

const contentStyles = mergeStyleSets({
  textField: {
    label: {
      marginBottom: 3
    }
  }
});

export default function BingLocationSearchBox(props: IBingLocationSearchBox) {
  const [locationEditText, setLocationEditText] = useState(
    props.selectedLocation && props.selectedLocation.name ? props.selectedLocation.name : ''
  );
  const [locationEditted, setLocationEditted] = useState(false);

  useEffect(() => {
    loadBingMapApi(onLoadMapApi);
  }, []);

  const onLoadMapApi = () => {
    loadAutoCompleteModule(onLoadAutoSuggestSuccess, onLoadAutoSuggestError);
  };

  const onLoadAutoSuggestSuccess = () => {
    const options = { maxResults: props.maxResults };
    const manager = new (window as any).Microsoft.Maps.AutosuggestManager(options);
    manager.attachAutosuggest('#searchBox', '#searchBoxContainer', onSelectedSuggestion);
  };

  const onLoadAutoSuggestError = (message: string) => {
    Logger.Err(LOGGER_PREFIX, 'Fail to load auto suggest module - ' + message);
  };

  const onSelectedSuggestion = suggestionResult => {
    setLocationEditText(suggestionResult.formattedSuggestion);
    setLocationEditted(false);
    const bingSuggestionResult = new UserLocation(
      suggestionResult.formattedSuggestion,
      suggestionResult.location.latitude,
      suggestionResult.location.longitude,
      suggestionResult.address.countryRegionISO2
    );
    if (props.onSelectedLocationChange) {
      props.onSelectedLocationChange(bingSuggestionResult);
    }
  };

  const getLocationDisplayText = (): string => {
    if (locationEditted) return locationEditText;
    if (props.selectedLocation && props.selectedLocation.name !== '') return props.selectedLocation.name;
    return '';
  };

  return (
    <div>
      <div id="searchBoxContainer">
        <TextField
          key={'gallery_filter_dropdown_location'}
          placeholder={props.placeholder}
          required={props.required}
          value={getLocationDisplayText()}
          label={props.label}
          type="text"
          id="searchBox"
          autoComplete="off"
          role="combobox"
          onFocus={e => {
            if (e && e.target) {
              e.target.select();
            }
          }}
          onChange={(event: any, newValue?: string) => {
            setLocationEditted(true);
            setLocationEditText(newValue);
            // User is typing - clear selected location.
            if (props.onSelectedLocationChange) {
              props.onSelectedLocationChange(null);
            }
          }}
          className={contentStyles.textField}
        />
      </div>
    </div>
  );
}
