import {
  IStoreFilterLocationMessage,
  IChangeUrlDetailsMessage,
  IChangeUrlGalleryMessage,
  IContentHeightChangeMessage,
  IPartnersHostMessage,
  PartnersHostMessageType,
  IStoreContactDetailsMessage
} from './partnersHostInboundMessages';
import * as Logger from '../services/loggerService';

const LOGGER_PREFIX = 'OutboundMessagesHandler';

/*
  Send messages to partners host(AppSource).
 */
export class OutboundMessagesHandler {
  public postLoginRequest = () => {
    const message: IPartnersHostMessage = { messageType: PartnersHostMessageType.Login };
    this.postMessage(message);
  };

  public postChangeUrlGalleryMessage = (message: IChangeUrlGalleryMessage) => {
    this.postMessage(message);
  };

  public postChangeUrlDetailsMessage = (message: IChangeUrlDetailsMessage) => {
    this.postMessage(message);
  };

  public postStoreFilterLocationMessage = (message: IStoreFilterLocationMessage) => {
    this.postMessage(message);
  };

  public postStoreContactDetailsMessage = (message: IStoreContactDetailsMessage) => {
    this.postMessage(message);
  };

  public postReadyMessage = () => {
    const message: IPartnersHostMessage = { messageType: PartnersHostMessageType.Ready };
    this.postMessage(message);
  };

  public postResizeMessage = () => {
    const message: IContentHeightChangeMessage = {
      messageType: PartnersHostMessageType.ContentHeightChange,
      height: document.body.scrollHeight
    };
    this.postMessage(message);
  };

  private postMessage = (message: IPartnersHostMessage) => {
    if (window.parent) {
      Logger.Info(LOGGER_PREFIX, 'Before post request to parent - ' + String(message));
      window.parent.postMessage(JSON.stringify(message), '*');
      Logger.Info(LOGGER_PREFIX, 'After post request to parent');
    } else {
      Logger.Info(LOGGER_PREFIX, "Can't send message. no parent window");
    }
  };
}
