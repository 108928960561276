import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CANCEL,
  APPLY,
  MILES_ABBRV,
  RADIUS,
  ADDRESS,
  ADDRESS_CITY_OR_COUNTRY,
  EDIT_LOCATION,
  PARTNERS_FROM_THIS_LOCATION,
  KILOMETERS_ABBRV,
  MY_LOCATION,
  USER_LOCATION_DISABLED_IN_BROWSER,
  ALLOW_ACCESS_TO_MY_CURRENT_LOCATION,
  USE_MY_LOCATION,
  SELECT_LOCATION,
  CLOSE_LOCATION_SELECTION_DIALOG,
  PARTNERS_FROM_ENTIRE_COUNTRY,
  RADIUS_TITLE
} from '../../constants/localization';
import {
  Modal,
  Checkbox,
  mergeStyleSets,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  DefaultButton,
  IconButton,
  TooltipHost
} from '@fluentui/react';
import { runningInIframe } from '../../utils/iframeUtils';
import BingLocationSearchBox from './bingLocationSearchBox';
import { IUserLocation, UserLocation } from '../../redux/locationSlice';
import { getFilterDistanceOptions, IFilterDistanceOption } from '../../utils/distanceUtils';
import { getCountryByName, localeUseImperialSystem } from '../../utils/countryStatesUtils';
import { useParams } from 'react-router-dom';
import { ANALYTICS_USER_ACTIONS, trackUserAction } from '../../services/analyticsService';
import UserLocationDiscovery from './userLocationDiscovery';

const MAX_SEARCH_LOCATION_RESULTS = 3;

export interface ISelectLocationDialog {
  isDialogOpen: boolean;
  directionRtl: boolean;
  location: IUserLocation;
  radius: string;
  showPartnersFromLocation: boolean;
  onApply(location: IUserLocation, radiusKey: string, showPartnersFromLocation: boolean): void;
  onCancel(): void;
}

export default function SelectLocationDialog(props: ISelectLocationDialog) {
  const isIframe: boolean = runningInIframe();
  const [selectedLocation, setSelectedLocation] = useState(props.location);
  const [selectedRadiusKey, setSelectedRadiusKey] = useState(props.radius);
  const [showPartnersFromLocation, setShowPartnersFromLocation] = useState(props.showPartnersFromLocation);
  const [getUserLocation, setGetUserLocation] = useState(false);
  const [userLocationFailed, setUserLocationFailed] = useState(false);
  const { t: translate } = useTranslation();
  const { locale } = useParams<{ locale: string }>();

  const modalAbsPosStyles = mergeStyleSets({
    root: {
      position: 'absolute'
    },
    main: {
      position: 'absolute',
      top: 300
    },
    layer: {
      position: 'absolute'
    }
  });

  const renderLocationSearchBox = () => {
    return (
      <BingLocationSearchBox
        label={translate(ADDRESS)}
        placeholder={translate(ADDRESS_CITY_OR_COUNTRY)}
        maxResults={MAX_SEARCH_LOCATION_RESULTS}
        selectedLocation={selectedLocation ? selectedLocation : new UserLocation(undefined, undefined, undefined, undefined)}
        onSelectedLocationChange={(location: IUserLocation) => {
          setSelectedLocation(location);
        }}
      />
    );
  };

  const useLocationDisabled = () => {
    return !('geolocation' in navigator) || userLocationFailed;
  };

  const onUserLocationFailed = (error: any) => {
    setUserLocationFailed(true);
  };

  const onUserLocationSuccess = (location: IUserLocation) => {
    props.onApply && props.onApply(location, selectedRadiusKey, showPartnersFromLocation);
  };

  const countryLocation = getCountryByName(selectedLocation && selectedLocation.name);
  const isCountryLocation = countryLocation !== null;

  const filterDistanceOptions: IFilterDistanceOption[] = getFilterDistanceOptions();
  const imperialSystem = localeUseImperialSystem(locale);
  const distanceAbbrv = imperialSystem ? MILES_ABBRV : KILOMETERS_ABBRV;
  const distanceRadiusOptions = filterDistanceOptions.map(distance => {
    {
      const distanceValue = imperialSystem ? distance.distanceMiles : distance.distanceKM;
      const text = `${translate(distance.localizationKey)} (${distanceValue} ${translate(distanceAbbrv)} ${translate(RADIUS)})`;
      return { key: distance.name, text: text };
    }
  });
  const noLocation = props.location && (props.location.coordExist() || props.location.countryCode);
  return (
    <Modal
      styles={isIframe ? modalAbsPosStyles : null}
      containerClassName="select-location-dialog-container"
      titleAriaId={'select_location_dialog_title_area_id'}
      isOpen={props.isDialogOpen}
      onDismiss={props.onCancel}
      isBlocking={false}
    >
      {getUserLocation ? (
        <UserLocationDiscovery onUserLocationError={onUserLocationFailed} onUserLocationSuccess={onUserLocationSuccess} />
      ) : null}
      <div className={props.directionRtl ? 'select-location-dialog-rtl' : 'select-location-dialog'}>
        <div className="select-location-dialog-title-box">
          <h2 className="select-location-dialog-title" id="select_location_dialog_title_area_id">
            <Trans i18nKey={noLocation ? EDIT_LOCATION : SELECT_LOCATION} />
          </h2>
          <IconButton
            className={props.directionRtl ? 'select-location-dialog-cancel-icon-rtl' : 'select-location-dialog-cancel-icon'}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel={translate(CLOSE_LOCATION_SELECTION_DIALOG)}
            onClick={props.onCancel}
          />
        </div>
        <p className="select-location-dialog-my-location-label">
          <Trans i18nKey={MY_LOCATION} />
        </p>
        <TooltipHost
          content={userLocationFailed ? translate(USER_LOCATION_DISABLED_IN_BROWSER) : translate(ALLOW_ACCESS_TO_MY_CURRENT_LOCATION)}
          // This id is used on the tooltip itself, not the host
          // (so an element with this id only exists when the tooltip is shown)
          id="get_my_location_button_tooltip"
        >
          <PrimaryButton
            ariaDescription={
              userLocationFailed ? translate(USER_LOCATION_DISABLED_IN_BROWSER) : translate(ALLOW_ACCESS_TO_MY_CURRENT_LOCATION)
            }
            className="select-location-dialog-location-button-margins"
            name="get_my_location_button"
            type="submit"
            disabled={useLocationDisabled()}
            onClick={() => {
              trackUserAction({
                name: ANALYTICS_USER_ACTIONS.GALLERY_USE_MY_LOCATION_CLICK
              });
              setGetUserLocation(true);
            }}
          >
            <Trans i18nKey={USE_MY_LOCATION}></Trans>
          </PrimaryButton>
        </TooltipHost>

        <p className="select-location-dialog-select-location-label">
          <Trans i18nKey={SELECT_LOCATION} />
        </p>

        <div className="select-location-dialog-input-box">
          <div className="select-location-dialog-input-row">{renderLocationSearchBox()}</div>
          {!isCountryLocation ? (
            <Dropdown
              disabled={isCountryLocation}
              className="select-location-dialog-input-row"
              options={distanceRadiusOptions}
              required={false}
              label={translate(RADIUS_TITLE)}
              ariaLabel={translate(RADIUS)}
              selectedKey={selectedRadiusKey}
              onChange={(event, option?: IDropdownOption) => {
                if (option) {
                  setSelectedRadiusKey(option.key as string);
                }
              }}
            />
          ) : null}
          <div className="select-location-dialog-input-row">
            <Checkbox
              disabled={isCountryLocation}
              checked={isCountryLocation || showPartnersFromLocation}
              onChange={() => {
                setShowPartnersFromLocation(!showPartnersFromLocation);
              }}
              label={isCountryLocation ? translate(PARTNERS_FROM_ENTIRE_COUNTRY) : translate(PARTNERS_FROM_THIS_LOCATION)}
            />
          </div>
        </div>
        <div className="select-location-dialog-buttons-box">
          <PrimaryButton
            className="select-location-dialog-apply-button-margins"
            name="apply_button"
            type="submit"
            disabled={!(selectedLocation != undefined)}
            onClick={() => props.onApply && props.onApply(selectedLocation, selectedRadiusKey, showPartnersFromLocation)}
          >
            <Trans i18nKey={APPLY}>Apply</Trans>
          </PrimaryButton>
          <DefaultButton name="cancel_button" type="submit" onClick={props.onCancel}>
            <Trans i18nKey={CANCEL}>Cancel</Trans>
          </DefaultButton>
        </div>
      </div>
    </Modal>
  );
}
