import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CLOSE,
  CONTACT_SUCCESS_DIALOG_TITLE,
  CONTACT_SUCCESS_DIALOG_MESSAGE_PREFIX,
  CONTACT_SUCCESS_DIALOG_MESSAGE_SUFFIX,
  CLOSE_CONTACT_PARTNER_DIALOG
} from '../../constants/localization';
import { Modal, IconButton, mergeStyleSets, PrimaryButton } from '@fluentui/react';
import { runningInIframe } from '../../utils/iframeUtils';
import { getHostSize, HostSizeInfo } from '../../redux/hostContextSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export interface IContactMessageSentDialog {
  partnerName: string;
  directionRtl: boolean;
  isDialogOpen: boolean;
  onDismissDialog(): void;
}

const DIALOG_HEIGHT = 380;

export default function ContactMessageSentDialog(props: IContactMessageSentDialog) {
  const { t: translate } = useTranslation();
  const isIframe: boolean = runningInIframe();
  const hostSize: HostSizeInfo = useSelector((store: RootState) => getHostSize(store.hostContext));

  const getModalAbsPosStyles = () => {
    return mergeStyleSets({
      root: {
        position: 'absolute'
      },
      main: {
        position: 'absolute',
        top: (hostSize.height - hostSize.frameTop) / 2 - DIALOG_HEIGHT / 2
      },
      layer: {
        position: 'absolute'
      }
    });
  };

  const style = isIframe ? getModalAbsPosStyles() : null;
  return (
    <Modal
      styles={style}
      titleAriaId={'message_sent_title_id'}
      isOpen={props.isDialogOpen}
      onDismiss={props.onDismissDialog}
      isBlocking={false}
    >
      <div className={props.directionRtl ? 'contact-message-sent-dialog-rtl' : 'contact-message-sent-dialog'}>
        <div className="contact-message-sent-titlebox">
          <span className="contact-message-sent-title" id={'message_sent_title_id'}>
            <Trans i18nKey={CONTACT_SUCCESS_DIALOG_TITLE}></Trans>
          </span>
          <IconButton
            className={props.directionRtl ? 'contact-message-sent-cancel-icon-rtl' : 'contact-message-sent-cancel-icon'}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel={translate(CLOSE_CONTACT_PARTNER_DIALOG)}
            onClick={props.onDismissDialog}
          />
        </div>
        <div className="contact-message-sent-main-box">
          <div className="icon-sent title-icon contact-message-sent-icon"></div>
          <div>
            <p className="contact-message-sent-message">
              <span>
                <Trans i18nKey={CONTACT_SUCCESS_DIALOG_MESSAGE_PREFIX}>Close</Trans>
              </span>
              <span className="contact-message-sent-message-bold">{' ' + props.partnerName + ' '}</span>
              <span>
                <Trans i18nKey={CONTACT_SUCCESS_DIALOG_MESSAGE_SUFFIX}>Close</Trans>
              </span>
            </p>
          </div>
        </div>
        <div className="contact-message-sent-buttons-box">
          <PrimaryButton name="button" className="c-button" type="submit" onClick={props.onDismissDialog}>
            <Trans i18nKey={CLOSE}>Close</Trans>
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
}
