import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Logger from '../../services/loggerService';
import { Translation } from 'react-i18next';
import { SOMETHING_WENT_WRONG } from '../../constants/localization';
import { FontIcon, mergeStyles } from '@fluentui/react';
import { EXCEPTIONS, monitorException } from '../../services/monitorService';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const stack = error && error.stack ? error.stack : errorInfo && errorInfo.componentStack ? errorInfo.componentStack : '';
    const message = error && error.message ? error.message : '';
    const exceptionMessage = `message = ${message},   stack = ${stack}`;
    Logger.Exception('ErrorBoundary:', exceptionMessage);
    monitorException({ name: EXCEPTIONS.UNHANDLED, message: message, stack: stack });
  }

  public renderError() {
    const iconClass = mergeStyles({
      fontSize: 200,
      height: 200,
      width: 200,
      margin: '0px 0px',
      color: '#323130',
      verticalAlign: 'middle'
    });
    return (
      <div className="error-boundary-page">
        <div className="error-boundary-container">
          <FontIcon aria-label="Something went wrong" iconName="Error" className={iconClass} />
          <Translation>{t => <div className="error-boundary-title">{t(SOMETHING_WENT_WRONG)}</div>}</Translation>
        </div>
      </div>
    );
  }

  public render() {
    if (this.state.hasError) {
      return this.renderError();
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
