import React, { useEffect, useState } from 'react';
import { getUserLocation, IUserLocation, setUserLocation, UserLocation } from '../../redux/locationSlice';
import { loadBingMapApi, loadSearchModule } from '../../utils/bingUtils';
import * as Logger from './../../services/loggerService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import { IStoreFilterLocationMessage, PartnersHostMessageType } from '../../protocol/partnersHostInboundMessages';
import { getDefaultDistanceOption } from '../../utils/distanceUtils';
import { runningInIframe } from '../../utils/iframeUtils';
import { MONITORS_DEPENDENCIES, monitorDependency } from '../../services/monitorService';
import { ANALYTICS_USER_ACTIONS, trackUserAction } from '../../services/analyticsService';
import { GetEnvConfiguration } from '../../services/configurationService';
import { isDiversityAndInclusionSupported } from '../../utils/countryStatesUtils';

const LOGGER_PREFIX = 'UserLocationDiscovery';
export const INVISIBLE_BING_MAP_ELEMENT_ID = 'invisibleMapUsedToMakeBingWork';

export interface IUserLocationDiscoveryProps {
  onUserLocationError(error: any): void;
  // If this value is set - action will be activated. If not,
  // user location will be set to react store automatically.
  onUserLocationSuccess?(location: IUserLocation): void;
}

// This component support two modes.
// 1. Get user location (for the fisrt time) when location not set yet.
//    At this mode location will be requested one time and set by this component to the store.
// 2. Get user location request from external component.
//    At this mode location will be send to the external requestor who is responsible for handling it.
export default function UserLocationDiscovery(props: IUserLocationDiscoveryProps) {
  const dispatch = useDispatch();
  const userLocation: IUserLocation = useSelector((store: RootState) => getUserLocation(store.location));
  const [searchManager, setSearchManager] = useState(null);
  const [foundUserLocation, setFoundUserLocation] = useState(null);
  const envConfig = GetEnvConfiguration();

  useEffect(() => {
    if ('geolocation' in navigator) {
      // If we have external location handler we will allow getting user location multiple times.
      // If we don't have external location handler we will allow getting user location once.
      if (!props.onUserLocationSuccess && hasUserCoordinates()) {
        Logger.Info(LOGGER_PREFIX, 'User location already set. No need to take it again.');
      }
      // Try to get user location from browser.
      else {
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(onCurrentPositionSuccess, onCurrentPoisionError, options);
      }
    } else {
      Logger.Info(LOGGER_PREFIX, 'User location not available');
    }
  }, []);

  const onCurrentPositionSuccess = (position: any) => {
    const userLocation = new UserLocation('', position.coords.latitude, position.coords.longitude, '');
    setFoundUserLocation(userLocation);
    Logger.Info(LOGGER_PREFIX, 'User location(coordinates) found and set.');
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.LOCATION_USER_ALLOW_ACCESS });
    // Load bing map api in order to load search manager.
    // We are going to use search manager to reverse geocode user
    // coordinates to find the name/address of the user location.
    loadBingMapApi(onLoadMapApi);
  };

  const onCurrentPoisionError = (error: any) => {
    props.onUserLocationError && props.onUserLocationError(error);
    Logger.Info(LOGGER_PREFIX, 'User location(coordinates) not allowed.');
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.LOCATION_USER_DENY_ACCESS });
  };

  // Effect will run after search module loaded and search manager initilized.
  useEffect(() => {
    if (searchManager && foundUserLocation) {
      const searchRequest = {
        location: { latitude: foundUserLocation.latitude, longitude: foundUserLocation.longitude },
        callback: onReverseGeocodeSuccess,
        errorCallback: onReverseGeocodeError,
        includeCountryIso2: true,
        includeNeighborhood: true
      };
      searchManager.reverseGeocode(searchRequest);
    }
  }, [searchManager]);

  const hasUserCoordinates = () => {
    return userLocation && userLocation.latitude && userLocation.longitude;
  };

  const onLoadMapApi = () => {
    loadSearchModule(onLoadSearchModuleSuccess, onLoadSearchModuleError);
  };

  const onLoadSearchModuleSuccess = () => {
    // Dummyy map - created to make bing search manager work.
    // We use Bing search manager to reverse geocode the user location.
    const map = new (window as any).Microsoft.Maps.Map('#' + INVISIBLE_BING_MAP_ELEMENT_ID, {
      center: new (window as any).Microsoft.Maps.Location(47.678, -122.133),
      zoom: 11
    });
    const searchManagerObj = new (window as any).Microsoft.Maps.Search.SearchManager(map);
    setSearchManager(searchManagerObj);
  };

  const onLoadSearchModuleError = (message: string) => {
    Logger.Err(LOGGER_PREFIX, 'Fail to load search module - ' + message);
  };

  const onReverseGeocodeSuccess = (reverseGeocodeResult: any) => {
    Logger.Info(LOGGER_PREFIX, 'User location reverse geocode success.');
    monitorDependency({ name: MONITORS_DEPENDENCIES.BING_REVERSE_GEO_CODE, success: true });
    const updatedUserLocation = new UserLocation(
      reverseGeocodeResult.address.formattedAddress,
      reverseGeocodeResult.location.latitude,
      reverseGeocodeResult.location.longitude,
      reverseGeocodeResult.address.countryRegionISO2
    );
    // Success location handler available - call it with the location.
    if (props.onUserLocationSuccess) {
      props.onUserLocationSuccess(updatedUserLocation);
    }
    // Success location handler not available - update store with the new location.
    else {
      dispatch(setUserLocation({ userLocation: updatedUserLocation }));
      if (runningInIframe()) {
        const outboundMessagesHandler = new OutboundMessagesHandler();
        const storeFilterLocation: IStoreFilterLocationMessage = {
          messageType: PartnersHostMessageType.StoreFilterLocation,
          filterLocation: {
            countryCode: updatedUserLocation.countryCode,
            locationLatitude: updatedUserLocation.latitude,
            locationLongtitude: updatedUserLocation.longitude,
            locationName: updatedUserLocation.name,
            onlyThisCountryResults: true,
            radiusMiles: getDefaultDistanceOption().distanceMiles
          },
          diversityAndInclusionEnabled: isDiversityAndInclusionEnabled(updatedUserLocation.countryCode)
        };
        outboundMessagesHandler.postStoreFilterLocationMessage(storeFilterLocation);
      }
    }
  };

  const isDiversityAndInclusionEnabled = (countryCode: string): boolean => {
    return envConfig.DIVERSE_INCLUSION_ENABLED && isDiversityAndInclusionSupported(countryCode);
  };

  const onReverseGeocodeError = (error: any) => {
    const errorMessage = 'User location reverse geocode failed.';
    Logger.Err(LOGGER_PREFIX, errorMessage);
    monitorDependency({
      name: MONITORS_DEPENDENCIES.BING_REVERSE_GEO_CODE,
      success: false,
      error: error ? error.toString() : errorMessage
    });
  };

  return (
    <div id="invisibleMapContainer">
      <div id={INVISIBLE_BING_MAP_ELEMENT_ID} className="invisible-map" />
    </div>
  );
}
