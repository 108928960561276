import React from 'react';

export interface IIconLink {
  className: string;
  iconClassName?: string;
  href: string;
  key: string;
  title: string;
  children: any;
  boxClassName?: string;
  role?: string;
}

export default function IconLink(props: IIconLink) {
  return (
    <div className={props.boxClassName ? props.boxClassName : 'icon-link'}>
      <div className={props.iconClassName + ' icon'}></div>
      <a href={props.href} role={props.role || 'link'} className={props.className} title={props.title}>
        {props.children}
      </a>
    </div>
  );
}
