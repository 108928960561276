import { mergeStyleSets } from '@uifabric/styling';
import { DefaultPalette } from 'office-ui-fabric-react/lib/Styling';

export const DarkPalette = {
  themePrimary: '#dda7ea',
  themeLighterAlt: '#090709',
  themeLighter: '#231b26',
  themeLight: '#423246',
  themeTertiary: '#85648d',
  themeSecondary: '#c293ce',
  themeDarkAlt: '#e0afed',
  themeDark: '#e5bbef',
  themeDarker: '#ecccf4',
  neutralLighterAlt: '#44114a',
  neutralLighter: '#4b1552',
  neutralLight: '#561d5e',
  neutralQuaternaryAlt: '#5d2265',
  neutralQuaternary: '#63276b',
  neutralTertiaryAlt: '#7c3e84',
  neutralTertiary: '#c8c8c8',
  neutralSecondary: '#d0d0d0',
  neutralPrimaryAlt: '#dadada',
  neutralPrimary: '#ffffff',
  neutralDark: '#f4f4f4',
  black: '#f8f8f8',
  white: '#3d0d43',
  redDark: '#e5bbef'
};

export const DefaultColors = {
  Application: {
    Primary: '#0078d7',
    Secondary: '#e5e5e5',
    LightBackground: '#ffffff',
    NeutralBackground: '#e5e5e5'
  },
  VisualIndicators: {
    GrayIndicator: '#7a7574',
    GreenIndicator: '#498205',
    YellowIndicator: '#fce100',
    RedIndicator: '#d1343b',
    BlueIndicator: '#0078d7'
  },
  Text: {
    Header: DefaultPalette.neutralPrimary,
    Primary: DefaultPalette.neutralPrimary,
    Secondary: DefaultPalette.neutralSecondary,
    HeroAccent: '#0078D7',
    Error: DefaultPalette.redDark
  },
  Link: {
    PrimaryLink: DefaultPalette.themePrimary,
    HeroLink: DefaultPalette.themeDark
  },
  List: {
    HeaderText: DefaultPalette.neutralSecondary,
    RowText: DefaultPalette.neutralPrimary
  },
  Item: {
    ListItemHoverBackgroundColor: DefaultPalette.neutralLighterAlt,
    ItemIndicator: '#0078d7'
  },
  Icon: {
    SecondaryIcon: DefaultPalette.neutralSecondary,
    PrimaryIcon: DefaultPalette.neutralPrimary
  }
};

export const DarkColors = {
  Application: {
    Primary: '#0078d7',
    Secondary: '#e5e5e5',
    LightBackground: '#ffffff',
    NeutralBackground: '#e5e5e5'
  },
  VisualIndicators: {
    GrayIndicator: '#7a7574',
    GreenIndicator: '#498205',
    YellowIndicator: '#fce100',
    RedIndicator: '#d1343b',
    BlueIndicator: '#0078d7'
  },
  Text: {
    Header: DarkPalette.neutralPrimary,
    Primary: DarkPalette.neutralPrimary,
    Secondary: DarkPalette.neutralSecondary,
    HeroAccent: '#0078D7',
    Error: DarkPalette.redDark
  },
  Link: {
    PrimaryLink: DarkPalette.themePrimary,
    HeroLink: DarkPalette.themeDark
  },
  List: {
    HeaderText: DarkPalette.neutralSecondary,
    RowText: DarkPalette.neutralPrimary
  },
  Item: {
    ListItemHoverBackgroundColor: DarkPalette.neutralLighterAlt,
    ItemIndicator: '#0078d7'
  },
  Icon: {
    SecondaryIcon: DarkPalette.neutralSecondary,
    PrimaryIcon: DarkPalette.neutralPrimary
  }
};

export const CalloutStyle = () => {
  return { width: '296px' };
};
export const AutocompleteStyles = () => {
  return {
    marginTop: '10px',
    marginBottom: '20px',
    width: '300px',
    display: 'inline-block'
  };
};
export const SuggestionListStyle = () => {
  return { padding: '4px 16px', fontSize: '14px', cursor: 'default' };
};
export const SuggestionListItemStyle = mergeStyleSets({
  root: {
    selectors: {
      '&:hover': {
        backgroundColor: DefaultColors.Item.ListItemHoverBackgroundColor
      }
    }
  }
});
